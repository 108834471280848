export default {
  path: "company",
  component: require("@/views/company/Index").default,
  name: "company",
  redirect: { name: "company-profile" },
  props: true,
  children: [
    {
      path: "",
      name: "company-profile",
      component: require("@/views/company/Profile").default,
      props: true,
      meta: {
        label: "Profile",
        breadcrumbs: ["dashboard", "company-profile"],
      },
    },
    {
      path: "logo",
      name: "company-logo",
      component: require("@/views/company/Logo").default,
      props: true,
      meta: {
        label: "Logo",
        breadcrumbs: ["dashboard", "company-logo"],
      },
    },
    {
      path: "welcome-screen",
      name: "company-welcome-screen",
      component: require("@/views/company/WelcomeScreen").default,
      props: true,
      meta: {
        label: "Welcome Screen",
        breadcrumbs: ["dashboard", "company-welcome-screen"],
      },
    },
    {
      path: "service-comparison",
      name: "service-comparison",
      component: require("@/views/company/ServiceComparison").default,
      props: true,
      meta: {
        label: "Service Comparison",
        breadcrumbs: ["dashboard", "service-comparison"],
      },
    },
    {
      path: "template-content",
      name: "template-content",
      component: require("@/views/company/template-content/Index").default,
      props: true,
      children: [
        {
          path: "",
          name: "template-content-list",
          component: require("@/views/company/template-content/List").default,
          props: true,
          meta: {
            label: "Templates",
            breadcrumbs: ["dashboard", "template-content-list"],
          },
        },
        {
          path: ":slug",
          name: "template-content-upsert",
          component: require("@/views/company/template-content/Upsert").default,
          props: true,
          meta: {
            label: "Templates",
            breadcrumbs: ["dashboard", "template-content-list", "template-content-upsert"],
          },
        }
      ]
    }
  ]
}