export default {
    path: "services",
    component: require("@/views/services/Index").default,
    name: "services",
    redirect: { name: "services-list" },
    props: true,
    children: [
        {
            path: "",
            name: "services-list",
            component: require("@/views/services/List").default,
            props: true,
            meta: {
                label: "Services",
                breadcrumbs: ["dashboard", "services-list"],
            },
        },
        {
            path: ":serviceId",
            name: "service-upsert",
            component: require("@/views/services/Upsert").default,
            props: true,
            meta: {
                label: "Service",
                breadcrumbs: ["dashboard", "services-list", "service-upsert"],
            },
        }
    ]
}