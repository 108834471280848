import axios from "@/plugins/axios";

export const get = async (id, bId) => {
  return axios.get(`/v1/template/${id}?business_id=${bId}`).then((res) => {
    return res.data;
  })
};

export const getTemplateAdmin = async (id) => {
  return axios.get(`/v1/admin/template/${id}`).then((res) => {
    return res.data;
  })
};

export const add = async (data) => {
  return axios.post(`/v1/template`, data).then((res) => {
    return res.data;
  })
};

export const addTemplateAdmin = async (data) => {
  return axios.post(`/v1/admin/template`, data).then((res) => {
    return res.data;
  })
};


export const update = async (data) => {
  return axios.put(`/v1/template/${data.id}`, data).then((res) => {
    return res.data;
  })
};

export const updateTemplateAdmin = async (data) => {
  return axios.put(`/v1/admin/template/${data.id}`, data).then((res) => {
    return res.data;
  })
};


export const archive = async (data) => {
  return axios.patch(`/v1/template/${data.id}/archive`, data).then((res) => {
    return res.data;
  })
};

export const archiveTemplateAdmin = async (data) => {
  return axios.patch(`/v1/admin/template/${data.id}/archive`, data).then((res) => {
    return res.data;
  })
};

export const unarchive = async (data) => {
  return axios.patch(`/v1/template/${data.id}/unarchive`, data).then((res) => {
    return res.data;
  })
};

export const unarchiveTemplateAdmin = async (data) => {
  return axios.patch(`/v1/admin/template/${data.id}/unarchive`, data).then((res) => {
    return res.data;
  })
};

export const activate = async (data) => {
  return axios.put(`/v1/template/${data.id}/activate`, data).then((res) => {
    return res.data;
  })
};


export const tags = async () => {
  // return axios.get(`/v1/template/tags?business_id=${id}`).then((res) => {
  //   return res.data;
  // })
  return axios.get(`/v1/template-tags/funeral-notice-templates`).then((res) => {
    return res.data;
  })
};
