<template>
  <div>
    <item-form
      :item="productId"
      :fields="fields"
      :save="save"
      :get="get"
      :archive="archived"
      :form-state.sync="formState"
      class="p-4"
      :footer="isDisabled"
      :breadcrumb="false"
      :redirect="false"
      width="400px"
      ref="item"
      :notify="true"
      :gap="3"
    >
      <template #default="{form}">
        <div
          v-if="isItemArchived !== null"
          :key="isItemArchived"
          class="bg-warning-lightest p-4 mb-2 radius-3 mb-4"
        >
          <p class="text-warning-darkest">
            This item is archived. Unarchive it to make any changes.
          </p>
          <div class="mt-2 flex flex--nowrap flex--between flex--fit">
            <s-button
              color="warning"
              label="Unarchive"
              icon="ArchiveArrowUp"
              @click.native="unarchiveItem"
              :loader="archiving"
            />
          </div>
        </div>
        <s-field-validate label="Products Category" rules="required">
          <s-select
            placeholder="Select"
            v-model="form.category_id"
            :request="categoryOptions"
            :disabled="isItemArchived ? true : false"
          />
        </s-field-validate>
        <s-field-validate label="Third-party Product" rules="required">
          <s-radio
            :options="thirdPartyOptions"
            v-model="form.is_third_party"
            :class="isItemArchived ? 'media-disabled' : ''"
          />
        </s-field-validate>
        <s-field-validate label="Name" rules="required">
          <s-textbox
            v-model="form.name"
            type="text"
            :disabled="isItemArchived ? true : false"
            spellcheck="true"
          />
        </s-field-validate>

        <s-field-validate label="Product Type" rules="required">
          <!-- <s-select
            placeholder="Select"
            v-model="form.product_type"
            :options="productTypeOptions"
            :disabled="isItemArchived ? true : false"
          /> -->
          <s-radio
            :options="productTypeOptions"
            v-model="form.product_type"
            :class="isItemArchived ? 'media-disabled' : ''"
          />
        </s-field-validate>
        <s-field-validate
          v-if="form.product_type === 'simple'"
          label="Price"
          rules="required"
        >
          <s-textbox
            v-model="form.price"
            type="number"
            :disabled="isItemArchived ? true : false"
          />
        </s-field-validate>
        <s-field-validate
          label="Variants"
          rules="required"
          class="mt-2"
          v-if="form.product_type === 'variation'"
        >
          <div>
            <s-row class="mb-3 mt-2 g-2">
              <s-column :size="5">Name</s-column>
              <s-column :size="5">Price</s-column>
              <s-column :size="2">Action</s-column>
            </s-row>
            <div
              v-for="(variant, index) in form.variants"
              :key="index"
              class="mb-3"
            >
              <s-row class="g-2 mb-3" v-if="variant.action !== 'delete'">
                <s-column :size="5">
                  <s-field-validate
                    name="name"
                    rules="required"
                    :vid="form.variants[index]['name']"
                  >
                    <s-textbox
                      type="text"
                      v-model="form.variants[index]['name']"
                      :disabled="isItemArchived ? true : false"
                    />
                  </s-field-validate>
                </s-column>
                <s-column :size="5">
                  <s-field-validate
                    name="price"
                    rules="required"
                    :vid="`${form.variants[index]['price']}`"
                  >
                    <s-textbox
                      type="number"
                      v-model="form.variants[index]['price']"
                      :disabled="isItemArchived ? true : false"
                    />
                  </s-field-validate>
                </s-column>
                <s-column :size="2">
                  <s-button
                    shape="circle"
                    icon="Delete"
                    theme="muted"
                    color="danger"
                    @click.native="form.variants[index]['action'] = 'delete'"
                    :disabled="isItemArchived ? true : false"
                  />
                </s-column>
              </s-row>
            </div>
            <s-row class="mb-3">
              <s-column :size="1">
                <s-button
                  fluid
                  icon="vmdi-plus"
                  align="right"
                  color="primary"
                  theme="muted"
                  label="Add New"
                  size="sm"
                  @click.native="addField"
                  :disabled="isItemArchived ? true : false"
                />
              </s-column>
            </s-row>
          </div>
        </s-field-validate>
        <s-field-validate label="Add Photo" rules="required">
          <div>
            <s-media
              :readonly="false"
              ratio="16x9"
              width="160px"
              :value="form.productImageId ? form.productImageId : productImage"
              @input="openCropper('productImage', $event, 16 / 9)"
              @remove="
                form.productImageId = null;
                productImage = null;
              "
              accept=".jpg,.jpeg,.png"
              value-type="base64"
              :max-file-size="5 * 1024 * 1024"
              :changeable="false"
              :class="isItemArchived ? 'radius-3 media-disabled' : 'radius-3'"
            />
          </div>
        </s-field-validate>
      </template>
    </item-form>
    <!-- Cropper -->
    <cropper ref="cropper" @input="onCrop($event)" />
  </div>
</template>
<script>
import {
  add,
  get,
  update,
  archive,
  unarchive,
  categories,
} from "@/api/product";
import { mapGetters } from "vuex";
export default {
  props: {
    productId: [String, Number],
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    Cropper: require("@/components/Cropper").default,
  },
  computed: {
    ...mapGetters(["business"]),
    productType() {
      return [
        { value: "urn", label: "urn" },
        { value: "product", label: "product" },
      ];
    },
    thirdPartyOptions() {
      return [
        { value: true, label: "Yes" },
        { value: false, label: "No" },
      ];
    },
    productTypeOptions() {
      return [
        { value: "simple", label: "Simple" },
        { value: "variation", label: "Variation" },
      ];
    },
  },
  data() {
    return {
      categories,
      fields: [
        "name",
        "category_id",
        "productImageId",
        "is_third_party",
        "product_type",
        "price",
        {
          name: "variants",
          value: [{ name: "", price: "", action: "insert" }],
        },
      ],
      productImage: null,
      isItemArchived: null,
      archiving: false,
      isDisabled: true,
      formState: null,
    };
  },
  methods: {
    get() {
      if (!this.business?.id) {
        return;
      }
      return get(this.productId, this.business?.id).then((res) => {
        let variants = res.variants.map((v) => {
          return {
            ...v,
            action: "update",
          };
        });
        if (!variants.length) {
          variants = [{ name: "", price: "", action: "insert" }];
        }

        if (res.deleted_at) {
          this.isItemArchived = res.deleted_at;
          this.isDisabled = false;
        } else {
          this.isItemArchived = null;
        }

        return { ...res, productImageId: res.image, variants };
      });
    },
    onCrop({ id, value }) {
      this[id] = value;
    },
    openCropper(id, img, ratio) {
      if (!img) return;
      this.$refs.cropper.open(id, img, {
        force: true,
        aspectRatio: ratio || 1,
      });
    },
    async save(id, data) {
      data.business_id = this.business.id;
      data.image = this.productImage;
      data.type = "product";
      data.variants = data.variants.filter((v) => v.name && v.price);
      if (data.product_type === "variation") {
        data.price = 0;
      }
      if (Number(this.productId)) {
        data.id = this.productId;
        return update(data).then((res) => {
          this.$router.push({ name: "products" });
          return res;
        });
      } else {
        return add(data).then((res) => {
          this.$router.push({ name: "products" });
          return res;
        });
      }
    },
    archived() {
      archive(this.productId, this.business?.id).then((res) => {
        this.$router.push({ name: "products" });
      });
    },
    unarchiveItem() {
      this.archiving = true;
      unarchive(this.productId, this.business?.id).then((res) => {
        this.$notify({
          title: res.message,
          type: "success",
          text: this.error,
        });
        this.archiving = false;
        this.isDisabled = true;
        this.get();
      });
    },
    categoryOptions() {
      return this.categories(this.business?.id);
    },
    addField() {
      this.formState.variants = [
        ...this.formState.variants,
        { name: "", price: "", action: "insert" },
      ];
    },
  },
};
</script>
<style lang="scss">
.media-disabled {
  pointer-events: none;
  opacity: 0.5;
}
</style>
