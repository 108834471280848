import axios from "./axios";
import qs from "qs";


export default {
  requestHandler(data) {
    const { endpoint, filters, params, search, pagination, sort } = data;

    return axios.get(endpoint, {
      params: {
        ...params,
        search,
        filter: filters,
        page: pagination.page,
        limit: pagination.perPage,
        sortBy: sort.by,
        sortOrder: sort.order,
      },
      paramsSerializer: (params) => qs.stringify(params),
    })
      .then((res) => {
        return res.data;
      })
      .then((res) => {
        return {
          items: res.data,
          count: res.meta?.total,
        };
      });

  },
};
