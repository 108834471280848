import axios from "@/plugins/axios";

export const add = (data) => {
  return axios.put(`/v1/services-comparison?business_id=${data.busienss_id}`, data).then(res => {
    return res.data;
  })
}

export const get = (id) => {
  return axios.get(`/v1/services-comparison?business_id=${id}`).then(res => {
    return res.data;
  })
}
