<template>
  <div>
    <div class="text-primary text-bold text-md  mb-3 mt-4 ">
      Press Notice details
    </div>
    <s-media
      v-if="image"
      class="radius-3 mb-4 bg-grey-lightest"
      :readonly="true"
      width="100px"
      height="100px"
      :value="image"
    />
  </div>
</template>
<script>
export default {
  props: {
    funeralDetails: Object,
  },
  computed: {
    image() {
      return this.funeralDetails?.notice?.image;
    },
  },
};
</script>
