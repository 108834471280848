<template>
  <s-layout
    full-height
    class="product-box"
    right-size="400px"
    :right-classlist="['p-3', 'bl-lightest']"
  >
    <template #default>
      <div class="category-container">
        <item-form
          :item="funeralId"
          :fields="fields"
          class="p-4"
          :save="save"
          :get="get"
          :footer="true"
          :form-state.sync="formValues"
          :breadcrumb="false"
          :redirect="false"
          width="450px"
          ref="item"
          :notify="true"
          :gap="0"
        >
          <template #default="{ form }">
            <sp-list
              :actions="['']"
              :data="productListing"
              :footer="false"
              :per-page="-1"
            >
              <!-- @click="hrefMaker(step)" -->
              <!-- v-smooth-scroll -->
              <template #default="{ items }">
                <div
                  v-for="(item, index) in items"
                  :ref="'ps-' + index"
                  :id="'ps-' + index"
                  :key="index"
                >
                  <product-selection
                    v-if="item.products"
                    :title="item.name"
                    :desc="item.desc"
                    :is-open="step === index"
                    :stepId="index"
                    @stepOpen="stepOpen"
                  >
                    <template>
                      <multi-select-product
                        :options="item.products"
                        v-model="form.products"
                        :multiple="isMultiple(item)"
                      />
                      <s-row class="g-2">
                        <s-column :size="3" v-if="index !== 0">
                          <s-button
                            fluid
                            size="sm"
                            class="bg-grey-lighter mr-3 text-grey"
                            theme="muted"
                            label="Back"
                            @click.native="step--"
                          />
                        </s-column>
                        <s-column :size="3">
                          <s-button
                            fluid
                            color="primary"
                            size="sm"
                            label="Next"
                            @click.native="step++"
                          />
                        </s-column>
                        <s-column
                          :size="index !== 0 ? 6 : 9"
                          class="flex flex--fit flex--right"
                        >
                          <s-button
                            class="bg-grey-lighter text-grey"
                            theme="muted"
                            size="sm"
                            label="Skip"
                            @click.native="step++"
                          />
                        </s-column>
                      </s-row>
                    </template>
                  </product-selection>
                </div>
              </template>
              <template #empty>
                <div></div>
              </template>
            </sp-list>
            <div
              :ref="'ps-' + productListing.length"
              :id="'ps-' + productListing.length"
            >
              <product-selection
                v-if="servicesListing.length"
                title="Third Party Services"
                :is-open="step === productListing.length"
                :stepId="productListing.length"
                @stepOpen="stepOpen"
              >
                <template>
                  <multi-select-product
                    :options="servicesListing"
                    v-model="form.services"
                    :multiple="true"
                  />
                  <s-row class="g-2">
                    <s-column :size="3" v-if="productListing.length">
                      <s-button
                        fluid
                        size="sm"
                        class="bg-grey-lighter mr-3 text-grey"
                        theme="muted"
                        label="Back"
                        @click.native="step--"
                      />
                    </s-column>
                    <s-column :size="3">
                      <s-button
                        fluid
                        color="primary"
                        size="sm"
                        label="Next"
                        @click.native="step++"
                      />
                    </s-column>
                    <s-column
                      :size="productListing.length ? 6 : 9"
                      class="flex flex--fit flex--right"
                    >
                      <s-button
                        class="bg-grey-lighter text-grey"
                        theme="muted"
                        size="sm"
                        label="Skip"
                        @click.native="step++"
                      />
                    </s-column>
                  </s-row>
                </template>
              </product-selection>
            </div>
            <!-- funeral Notice -->
            <div
              :ref="'ps-' + funeralNoticeStepId"
              :id="'ps-' + funeralNoticeStepId"
            >
              <product-selection
                title="Funeral Notice"
                :is-open="
                  servicesListing.length
                    ? step === productListing.length + 1
                    : step === productListing.length
                "
                :stepId="funeralNoticeStepId"
                @stepOpen="stepOpen"
              >
                <template>
                  <s-field-validate
                    label="Would you like to create funeral notice?"
                    rules="required"
                  >
                    <s-radio
                      :options="noticeOptions"
                      v-model="form.is_notice_required"
                      @change.native="noticeConfirm"
                    />
                  </s-field-validate>
                  <div class="mt-3" v-if="form.is_notice_required === 1">
                    <multi-select-product
                      v-if="noticeDetails"
                      :options="noticeDetails"
                      v-model="noticeDetails"
                      :disabled="true"
                    />
                  </div>
                  <s-row :class="form.is_notice_required === 0 ? 'mt-3' : ''">
                    <s-column :size="3">
                      <s-button
                        fluid
                        size="sm"
                        class="bg-grey-lighter text-grey"
                        theme="muted"
                        label="Back"
                        @click.native="step--"
                      />
                    </s-column>
                  </s-row>
                </template>
              </product-selection>
            </div>

            <s-field-validate label="Special Requests" optional>
              <s-textbox v-model="form.special_requests" />
            </s-field-validate>
          </template>
          <template #footer="{ saveItem, saving }" :class="['']">
            <s-row class="row-block g-2">
              <s-column :size="3">
                <s-button
                  fluid
                  class="bg-grey-lighter text-grey"
                  theme="muted"
                  label="Back"
                  @click.native="back"
                />
              </s-column>
              <s-column :size="9">
                <s-button
                  fluid
                  icon="vmdi-arrow-right"
                  align="right"
                  color="primary"
                  :label="label"
                  @click.native="saveItem"
                  :loader="saving"
                />
              </s-column>
            </s-row>
          </template>
        </item-form>
      </div>
    </template>
    <template #right>
      <div class="text-primary text-bold text-md mb-3">
        Your Summary & Guide Price
      </div>
      <funeral-director :business="funeralDetails.business" />
      <service-card
        :type="funeralDetails.type"
        :business-id="funeralDetails.business_id"
        @typeTotal="totalOperation"
      />
      <div
        class="radius-3 bg-grey-lightest-ultra p-3 text-grey mb-3"
        v-if="selectedProductsLength"
      >
        <div class="pb-2 mb-2 bb-lighter text-md text-grey-darkest">
          Product Selection
        </div>
        <sp-list
          :actions="['']"
          :data="selectedProducts"
          :footer="false"
          :per-page="-1"
        >
          <template #default="{ items }">
            <div v-for="(item, index) in items" :key="index">
              <!-- flex--middle -->
              <div
                class="flex flex--between flex--fit mb-3 text-grey"
                v-if="item"
              >
                <div class="side-product-name">
                  <div class="">
                    {{ item.name }}
                    <span class="text-capitalize" v-if="item.variant_name"
                      >- {{ item.variant_name }}</span
                    >
                  </div>
                  <div v-if="item.category.is_quantity_enable">
                    <div class="flex flex--fit quantity-handler mt-1">
                      <s-button
                        size="xs"
                        theme="muted"
                        color="primary"
                        icon="vmdi-minus"
                        class="px-2 btn-minus"
                        @click.native="quantityHandler(item)"
                        :disabled="item.quantity === 1"
                      />
                      <s-field size="xs">
                        <input
                          type="number"
                          v-model="item.quantity"
                          class="radius-0 field-block"
                          @blur="quantityInput($event, item)"
                        />
                      </s-field>
                      <s-button
                        size="xs"
                        theme="muted"
                        color="primary"
                        icon="vmdi-plus"
                        class="px-2 btn-plus"
                        @click.native="quantityHandler(item, false)"
                      />
                    </div>
                  </div>
                </div>
                <!-- flex--middle -->
                <div class="flex flex--fit">
                  <div
                    class="text-bold text-grey-darkest mr-1"
                    v-if="item.quantity"
                  >
                    {{ "£" + (item.quantity * item.product_price).toFixed(2) }}
                  </div>
                  <div class="text-bold text-grey-darkest mr-1" v-else>
                    {{ "£" + item.product_price.toFixed(2) }}
                  </div>
                  <s-button
                    size="xs"
                    shape="circle"
                    icon="Delete"
                    theme="outline"
                    color="danger"
                    @click.native="removeSelectedProduct(item)"
                  />
                </div>
              </div>
            </div>
            <div
              class="
                flex flex--between flex--fit flex--middle
                text-grey-darkest
                bt-lighter
                pt-3
              "
            >
              <div class="">Subtotal</div>
              <div class="text-bold">
                <span class="text-xs text-normal text-grey">From</span>
                {{ "£" + productsBaseTotal.toFixed(2) }}
              </div>
            </div>
          </template>
        </sp-list>
      </div>

      <third-party
        :data="selectedServices"
        @servicesTotal="totalOperation"
        @removeService="removeServiceProduct"
        @quantityHandler="serviceQuantity"
        @quantityInput="serviceQunatityInput"
      />

      <div
        class="
          bg-grey-lightest
          flex flex--between flex--fit flex--middle
          mb-3
          p-3
          radius-3
          text-bold text-md
        "
      >
        <div>Guide Price Total</div>
        <div>
          <span class="text-xs text-normal text-grey">From</span>
          {{
            "£" +
            (productsBaseTotal + typeBaseTotal + serviceBaseTotal).toFixed(2)
          }}
        </div>
      </div>

      <div class="text-xs text-grey">
        This is an estimate that you can change at any time. Your Funeral
        Director will finalise exact cost with you.
      </div>
    </template>
  </s-layout>
</template>
<script>
import {
  productSave,
  getProducts,
  getProductList,
  getServices,
  getFuneralNotice
} from "@/api/new-request/tailored";
import checkFuneral from "@/views/new-request/common/checkFuneral";

export default {
  mixins: [checkFuneral],
  props: {
    funeralDetails: [Object],
    funeralId: [Number, String]
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    ProductSelection: require("./ProductSelection").default,
    MultiSelectProduct: require("./MultiSelectProduct").default,
    ServiceCard: require("../common/ServiceSelection").default,
    FuneralDirector: require("../common/FuneralDirector").default,
    ThirdParty: require("./ThirdPartyRevamp").default
  },
  mounted() {
    this.routeAllowed();
    if (this.funeralDetails?.funeral_type_id === 2) {
      this.$router.push({ name: "funeral-options" });
    }
  },
  data() {
    return {
      fields: [
        { name: "products", value: [] },
        { name: "services", value: [] },
        { name: "is_notice_required", value: 0 },
        { name: "special_requests", value: null }
      ],
      step: 0,
      productListing: [],
      servicesListing: [],
      manageProducts: [],
      formValues: null,
      serviceBaseTotal: 0,
      typeBaseTotal: 0,
      productsBaseTotal: 0,
      loading: false,
      noticeDetails: null,
      tester: [],
      quantity: 1
    };
  },
  async created() {
    this.loading = true;
    this.productListing = await this.listingSetup();
    this.servicesListing = await this.servicesSetup();
  },
  watch: {
    step: (newValue, oldValue) => {
      // document.querySelector(
      //   ".product-box .layout__content"
      // ).scrollTop = document
      //   .querySelector(`#p-${newValue}`)
      //   ?.getBoundingClientRect().top;
      var element = document?.getElementById(`ps-${newValue}`);
      setTimeout(() => {
        element?.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
      }, 100);
    }
  },
  computed: {
    funeralNoticeStepId() {
      if (this.servicesListing?.length) {
        return this.productListing?.length + 1;
      }
      return this.productListing.length;
    },
    noticeOptions() {
      return [
        { label: "Yes", value: 1 },
        { label: "No", value: 0 }
      ];
    },
    selectedProducts() {
      if (this.formValues?.products)
        this.selectProductTotal(this.formValues?.products);

      return this.formValues?.products.filter(
        p => p !== null && !p.is_third_party
      );
    },
    selectedProductsLength() {
      return this.selectedProducts?.length;
    },
    selectedServices() {
      let thirdPartyProducts = this.formValues?.products.filter(
        p => p !== null && p.is_third_party
      );

      if (this.formValues?.is_notice_required && this.noticeDetails?.length) {
        thirdPartyProducts = [...thirdPartyProducts, ...this.noticeDetails];
      }

      return [
        ...(this.formValues?.services || []),
        ...(thirdPartyProducts || [])
      ];
    },
    selectedServicesLength() {
      return this.selectedServices?.length;
    },
    label() {
      if (this.formValues?.is_notice_required) {
        return "Funeral Notice Details";
      }
      return "Confirm Funeral Details";
    }
  },
  methods: {
    isMultiple(item) {
      if (item.is_multiple) {
        return true;
      }
      return false;
    },
    get(id) {
      let products;
      let code = localStorage.getItem("vfa-code");
      return getProducts(this.funeralId, code).then(res => {
        products = res.data.filter(p => p.type === "product");
        this.servicesListing = res.data.filter(
          p =>
            p.type === "third-party" && !p.is_compulsory && p.category_id !== 7
        );
        if (this.funeralDetails?.is_notice_required) {
          this.noticeConfirm(this.funeralDetails?.is_notice_required);
        }
        return {
          products,
          special_requests: this.funeralDetails?.special_requests,
          services: this.servicesListing,
          is_notice_required: this.funeralDetails?.is_notice_required
        };
      });
    },
    async save(id, data) {
      let finalData = {
        id: this.funeralId,
        special_requests: data.special_requests,
        is_notice_required: data.is_notice_required,
        products: [
          ...data.products
            .filter(p => p !== null)
            .map(p => {
              if (p.product_type === "variation") {
                return {
                  id: p.id,
                  variant_id: p.variant_id,
                  quantity: p.quantity || 1
                };
              }
              return {
                id: p.id,
                quantity: p.quantity || 1
              };
            }),
          ...data.services.map(s => {
            return { id: s.id, quantity: s.quantity || 1 };
          })
        ]
      };
      let isNoticeSaved = this.funeralDetails?.products.some(
        p => p.category?.id === this.noticeDetails?.[0]?.id
      );
      if (data.is_notice_required && !isNoticeSaved) {
        finalData.products = [
          ...finalData.products,
          { id: this.noticeDetails?.[0]?.id, quantity: 1 }
        ];
      } else {
        finalData.products = finalData.products.filter(
          p => p.id !== this.noticeDetails?.[0]?.id
        );
      }

      let code = localStorage.getItem("vfa-code");
      return productSave(finalData, code).then(res => {
        this.$emit("refresh");
        if (data.is_notice_required) {
          this.$router.push({ name: "deceased-details" });
        } else {
          this.$router.push({ name: "confirm-details" });
        }
      });
    },
    async listingSetup() {
      let includeUrn = this.funeralDetails.type === "cremation" ? "" : "urn,";
      let products = await getProductList(
        this.funeralDetails.business_id,
        includeUrn
      )
        .then(async res => {
          return await res.data;
        })
        .then(async res => {
          return await res.filter(p => p.products.length);
        });
      return products;
    },
    async servicesSetup() {
      const services = await getServices({
        id: this.funeralDetails.business_id,
        type: this.funeralDetails.type
      })
        .then(async res => {
          return await res.data;
        })
        .then(async res => {
          // this.formValues?.services
          let thirdParty = res.filter(s => s.is_compulsory);
          this.formValues.services = [
            ...(thirdParty || []),
            ...(this.formValues?.services || [])
          ];
          return await res.filter(s => !s.is_compulsory);
        });
      this.loading = false;
      return services;
    },
    totalOperation(item) {
      if (item.name === "services") {
        this.serviceBaseTotal = item.total;
      } else {
        this.typeBaseTotal = item.total;
      }
    },
    selectProductTotal(items) {
      this.productsBaseTotal = items
        .filter(p => p !== null && !p.is_third_party)
        .reduce((initial, current) => {
          if (current?.category?.is_quantity_enable) {
            return initial + current?.product_price * current?.quantity;
          }
          return initial + current.product_price;
        }, 0);
      return this.productsBaseTotal;
    },
    back() {
      this.$router.push({ name: "funeral-type" });
    },
    removeSelectedProduct(item) {
      let products = this.formValues?.products.filter(p => p.id !== item.id);
      this.formValues["products"] = products;
    },
    removeServiceProduct(item) {
      if (item?.name !== "Funeral Notice") {
        let services = this.formValues?.services.filter(s => s.id !== item.id);
        this.formValues["services"] = services;
        if (item?.is_third_party) {
          this.removeSelectedProduct(item);
        }
      } else {
        this.formValues["is_notice_required"] = 0;
      }
    },
    stepOpen(stepId) {
      this.step = stepId;
    },
    noticeConfirm(isNotice) {
      let data = {
        business_id: this.funeralDetails.business_id
      };
      if (this.formValues?.is_notice_required === 1 || isNotice) {
        getFuneralNotice(data)
          .then(res => {
            this.noticeDetails = res.data;
            // this.tester = [res.data?.[0]];
          })
          .catch(err => {
            console.log("error", err);
          });
      }
    },
    quantityHandler(item, op = true) {
      let productIndex = this.selectedProducts.findIndex(p => p.id === item.id);

      if (!isNaN(productIndex) && productIndex !== -1) {
        if (op) {
          this.selectedProducts[productIndex]["quantity"] -= 1;
        } else {
          this.selectedProducts[productIndex]["quantity"] += 1;
        }
      }
    },
    quantityInput(e, item) {
      if (
        !Number(e?.target?.value) ||
        isNaN(e?.target?.value) ||
        Number(e?.target?.value) <= 0
      ) {
        alert("Product quantity must be 1 or greater then 1");
        let productIndex = this.formValues?.products
          .filter(p => p !== null && !p.is_third_party)
          .findIndex(p => p.id === item.id);
        this.formValues.products[productIndex]["quantity"] = 1;
      }
    },
    serviceQuantity(item, op) {
      let serviceIndex = this.selectedServices?.findIndex(
        s => s.id === item.id
      );

      if (!isNaN(serviceIndex) && serviceIndex !== -1) {
        if (op) {
          this.selectedServices[serviceIndex]["quantity"] -= 1;
        } else {
          this.selectedServices[serviceIndex]["quantity"] += 1;
        }
      }
    },
    serviceQunatityInput(e, item) {
      if (
        !Number(e?.target?.value) ||
        Number(e?.target?.value) <= 0 ||
        isNaN(e?.target?.value)
      ) {
        alert("Product quantity must be 1 or greater then 1");
        let serviceIndex = this.selectedServices?.findIndex(
          s => s.id === item.id
        );
        this.selectedServices[serviceIndex]["quantity"] = 1;
      }
    }
  }
};
</script>
<style lang="scss">
.category-container {
  .v-list__content {
    overflow: inherit;
  }
}
.quantity-handler {
  input {
    width: 37px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
  .btn-minus {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .btn-plus {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}
</style>
