<template>
  <div class="h-100">
    <portal to="header">
      <s-button
        class=""
        size="sm"
        color="primary"
        theme="primary"
        label="Add New"
        :to="{ name: 'template', params: { templateId: '+' } }"
        icon="vmdi-plus"
      />
    </portal>
    <sp-list
      class="h-100"
      :endpoint="userRoles == 'admin' ? '/v1/admin/template' : '/v1/template'"
      :attrs="columns"
      :actions="['settings', 'refresh', 'filters']"
      ref="list"
      :filters.sync="filters"
      :params="params"
    >
      <template #filters>
        <s-field label="Archive" inline>
          <s-switch
            v-model="filters.archive"
            onValue="1"
            offValue="0"
            size="xs"
            id="archive-1"
            color="primary"
            shape="pill"
          />
        </s-field>
      </template>
      <sp-list-table @rowClick="details($event.id)">
        <template #status="{item}">
          <s-button
            v-if="userRoles == 'admin'"
            :color="statusText[item.status]"
            class="text-xs text-normal  h-4 py-3"
            :class="statusColor[item.status]"
            shape="pill"
            :label="item.status == 'inactive' ? 'In Active' : 'Active'"
          />
          <!-- @click.native="status(item)" -->
          <s-switch
            v-else
            v-model="item.status"
            :class="item.status == 'active' ? 'disabled-switch' : ''"
            onValue="active"
            color="primary"
            size="xs"
            shape="pill"
            :id="'status-' + item.id"
            @click.native="change($event, item)"
            :title="
              item.status == 'active'
                ? 'Current status active'
                : 'Current status inactive'
            "
          />
        </template>
        <template #action="{item}">
          <!-- shape="circle" -->
          <s-button
            size="sm"
            @click.native="details(item.id)"
            class="bg-grey-lighter px-2 text-grey"
            theme="muted"
            icon="vmdi-pencil"
          />
        </template>
      </sp-list-table>
    </sp-list>
    <!-- <status-modal :item="item" v-if="item" @refresh="$refs.list.refresh()" /> -->
  </div>
</template>
<script>
import { activate } from "@/api/template";
import { mapGetters } from "vuex";
export default {
  // components: {
  //   StatusModal: require("./StatusModal").default,
  // },
  data() {
    return {
      statusColor: {
        active: "bg-success-lightest",
        inactive: "bg-warning-lightest",
      },
      statusText: {
        active: "text-success",
        inactive: "text-warning",
      },
      item: null,
      filters: {
        archive: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["business", "userRoles"]),
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "name" },
        { name: "status", fix: true, rowClick: false },
        { name: "action", fix: true, rowClick: false },
        // { name: "_drag", label: "", fix: true },
      ];
    },
    params() {
      if (this.userRoles == "admin") {
        return;
      } else {
        return { business_id: this.business?.id };
      }
    },
  },
  methods: {
    details(id) {
      this.$router.push({ name: "template", params: { templateId: id } });
    },
    change(event, item) {
      if (item.status == "active") {
        this.$notify({
          title: "Template is already active",
          type: "success",
          text: this.error,
        });
      } else {
        let data = {
          // status:this.changeStatus,
          business_id: this.business?.id,
          id: item.id,
        };
        activate(data).then((res) => {
          this.$refs.list.refresh();
        });
      }
    },
  },
};
</script>
<style lang="scss">
.disabled-switch {
  label {
    cursor: not-allowed;
    pointer-events: none;
  }
}
</style>
