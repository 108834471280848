<template>
  <div class="h-100">
    <request
      class="h-100"
      :get="runFuneral"
      #default="{ res }"
      ref="funeralDetails"
    >
      <s-layout
        class="sub-box"
        full-height
        :left-classlist="['br-lightest']"
        left-size="260px"
      >
        <template #left>
          <sub-nav class="bt-lightest p-3">
            <s-nav-item
              class="is-root"
              :to="{ name: 'contact-details' }"
              icon="vmdi-numeric-1-box"
              label="Your/Deceased Details"
            />
            <s-nav-item
              :to="{ name: 'funeral-options' }"
              icon="vmdi-numeric-2-box"
              label="Funeral Options"
              :disabled="!res.contact_email"
            />
            <span v-if="funeralType">
              <div v-for="(menu, index) in sideMenu" :key="index">
                <s-nav-item
                  :to="menu.to"
                  :icon="menu.icon"
                  :label="menu.label"
                  :disabled="menu.isDisabled"
                  v-if="menu.isVisible"
                />
              </div>
            </span>
          </sub-nav>
        </template>
        <template #default>
          <router-view
            :funeral-details="res"
            @refresh="$refs.funeralDetails.refresh()"
          />
        </template>
      </s-layout>
    </request>
    <otp-modal
      :funeralId="funeralId"
      @refresh="$refs.funeralDetails.refresh()"
    />
  </div>
</template>
<script>
import { getFuneral } from "@/api/new-request/funeral";
export default {
  props: {
    funeralId: [Number, String]
  },
  components: {
    SubNav: require("@/components/SubNav").default,
    Request: require("@/components/Request").default,
    OtpModal: require("./OtpModal").default
  },
  data() {
    return {
      funeralType: null,
      funeralResponse: null
    };
  },
  computed: {
    simpleFuneral() {
      return [
        {
          to: { name: "funeral-type" },
          icon: "vmdi-numeric-3-box",
          label: "Funeral Type",
          isVisible: true
        },
        {
          to: { name: "urns" },
          icon: "vmdi-numeric-4-box",
          label: "Funeral Products",
          isDisabled: this.funeralResponse?.ceremony_location ? false : true,
          isVisible: true
        },
        {
          to: { name: "deceased-details" },
          icon: "vmdi-numeric-5-box",
          label: "Funeral Notice Details",
          isDisabled: this.funeralResponse?.is_notice_required ? false : true,
          isVisible: true
        },
        {
          to: { name: "confirm-details" },
          icon: "vmdi-numeric-6-box",
          label: "Confirm Funeral Details",
          isDisabled: this.funeralResponse?.ceremony_location ? false : true,
          isVisible: true
        }
      ];
    },
    tailoredFuneral() {
      return [
        {
          to: { name: "funeral-type" },
          icon: "vmdi-numeric-3-box",
          label: "Funeral Type",
          isVisible: true
        },
        {
          to: { name: "funeral-product" },
          icon: "vmdi-numeric-4-box",
          label: "Funeral Products",
          isVisible: true,
          isDisabled: this.funeralResponse?.ceremony_location ? false : true
        },
        {
          to: { name: "deceased-details" },
          icon: "vmdi-numeric-5-box",
          label: "Funeral Notice Details",
          isVisible: true,
          isDisabled: this.funeralResponse?.is_notice_required ? false : true
        },
        {
          to: { name: "urns" },
          icon: "vmdi-numeric-6-box",
          label: "URNs",
          // isVisible: this.funeralResponse?.type === "cremation" ? true : false,
          isVisible: false,
          isDisabled: this.funeralResponse?.ceremony_location ? false : true
        },
        {
          to: { name: "confirm-details" },
          // icon:
          //   this.funeralResponse?.type === "cremation"
          //     ? "vmdi-numeric-7-box"
          //     : "vmdi-numeric-6-box",
          icon: "vmdi-numeric-6-box",
          label: "Confirm Funeral Details",
          isVisible: true,
          isDisabled: this.funeralResponse?.ceremony_location ? false : true
        }
      ];
    },
    directFuneral() {
      return [
        {
          to: { name: "confirm-details" },
          icon: "vmdi-numeric-3-box",
          label: "Confirm Funeral Details",
          isVisible: true
        }
      ];
    },
    sideMenu() {
      if (this.funeralType === 3) {
        return this.tailoredFuneral;
      } else if (this.funeralType === 2) {
        return this.simpleFuneral;
      } else if (this.funeralType === 1) {
        return this.directFuneral;
      }
      return [];
    }
  },
  methods: {
    get() {
      let code = localStorage.getItem("vfa-code");
      return getFuneral(this.funeralId, code).then(res => {
        this.funeralType = res?.funeral_type_id;
        this.funeralResponse = res;
        return res;
      });
    },
    verifyCode() {
      setTimeout(() => {
        this.$root.$emit("shilp-modal-open", "otp-verification-modal");
      }, 500);
      return {};
    },
    async runFuneral() {
      let funeralStatus;
      let error = false;
      await getFuneral(this.funeralId)
        .then(res => {
          funeralStatus = res.status;
        })
        .catch(err => {
          error = true;
        });
      if (error || funeralStatus !== "Pending") {
        console.log("error");
        const token = await localStorage.getItem("vfa-token");
        let code = await localStorage.getItem("vfa-code");
        if (code || token) {
          return this.get();
        }
        return this.verifyCode();
      } else {
        console.log("first");
        return this.get();
      }
    }
  }
};
</script>
<style lang="scss">
@media (max-width: 780px) {
  .sub-box {
    position: relative;
    display: flex;
    flex-direction: column;
    .layout__left {
      flex: 0 0 60px;
      .nav {
        display: flex;
        flex-direction: row;
        padding: 5px !important;
        > span {
          display: flex;
        }
        > li {
          & + li {
            margin-top: 0px;
          }
        }
      }
    }
    .layout__content {
      flex: 1;
      overflow: auto;
      .item-form {
        width: 100% !important;
      }
    }
  }
}
</style>
