<template>
  <div>
    <item-form
      :item="true"
      :fields="fields"
      :save="save"
      :get="get"
      class="p-4"
      :footer="true"
      :breadcrumb="false"
      :redirect="false"
      width="400px"
      ref="item"
      :notify="true"
      :gap="3"
      :form-state.sync="formState"
    >
      <template #default="{form}">
        <s-field label="Add Photo">
          <div>
            <s-media
              class="radius-3"
              :readonly="false"
              ratio="1x1"
              width="220px"
              :value="form.image ? form.image : companyLogo"
              @input="openCropper('companyLogo', $event, NaN)"
              @remove="removeProfile()"
              accept=".jpg,.jpeg,.png"
              value-type="base64"
              :max-file-size="5 * 1024 * 1024"
              :changeable="false"
            />
          </div>
        </s-field>
      </template>
    </item-form>
    <!-- Cropper -->
    <cropper ref="cropper" @input="onCrop($event)" />
  </div>
</template>
<script>
import company from "@/api/company";
import { mapGetters } from "vuex";
import user from "@/api/user";

export default {
  components: {
    ItemForm: require("@/components/ItemForm").default,
    Cropper: require("@/components/Cropper").default,
  },
  data() {
    return {
      fields: ["image"],
      companyLogo: null,
      loader: false,
      mediaId: null,
      formState: null,
    };
  },
  computed: {
    ...mapGetters(["business"]),
  },
  methods: {
    get() {
      this.mediaId = this.business?.media_id;
      return {
        image: this.business?.logo,
      };
    },
    onCrop({ id, value }) {
      this[id] = value;
    },
    openCropper(id, img, ratio) {
      if (!img) return;
      this.$refs.cropper.open(id, img, {
        force: true,
        aspectRatio: ratio,
      });
    },
    removeProfile() {
      let data = this.mediaId;
      this.formState.image = null;
      this.companyLogo = null;
      return user
        .mediaRemove(data)
        .then((res) => {
          this.$notify({
            title: res.message,
            type: "success",
            text: this.error,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    async save(id, data) {
      data.image = this.companyLogo;
      return company.upsertLogo(this.business.id, data).then((res) => {
        this.$root.$emit("rehydrate");
        return res;
      });
    },
  },
};
</script>
