import axios from "@/plugins/axios";

export const funeralNotice = async (data, code) => {
  return axios.post(`/v1/funeral/${data.id}/notice?verify=${code}`, data).then((res) => {
    return res.data;
  })
};

export const deceasedDetail = async (data, code) => {
  return axios.put(`/v1/funeral/${data.id}/deceased-detail?verify=${code}`, data).then((res) => {
    return res.data;
  })
};

export const familyReference = async (data, code) => {
  return axios.put(`/v1/funeral/${data.id}/notice?verify=${code}`, data).then((res) => {
    return res.data;
  })
};


export const media = async (data, code) => {
  return axios.put(`/v1/funeral/${data.id}/notice-media?verify=${code}`, data).then((res) => {
    return res.data;
  })
};

export const publishOption = async (data, code) => {
  return axios.put(`/v1/funeral/${data.id}/notice-publisher?verify=${code}`, data).then((res) => {
    return res.data;
  })
};