<template>
  <div>
    <item-form
      :item="true"
      :fields="fields"
      :save="save"
      :get="get"
      class="p-4"
      :footer="true"
      :breadcrumb="false"
      :redirect="false"
      width="400px"
      ref="item"
      :notify="true"
      :gap="3"
      :form-state.sync="formState"
    >
      <template #default="{form}">
        <s-field label="Add Photo">
          <div>
            <!-- @remove="
                form.profileImageId = null;
                profileImage = null;
              " -->
            <s-media
              class="radius-3"
              :readonly="false"
              ratio="1x1"
              width="120px"
              :value="form.profileImageId ? form.profileImageId : profileImage"
              @input="openCropper('profileImage', $event, 1)"
              @remove="removeProfile()"
              accept=".jpg,.jpeg,.png"
              value-type="base64"
              :max-file-size="5 * 1024 * 1024"
              :changeable="false"
            />
          </div>
        </s-field>
        <s-field-validate label="Email or Username" rules="required">
          <s-textbox v-model="form.email" type="text" disabled />
        </s-field-validate>
        <s-field-validate label="User Full Name" rules="required">
          <s-textbox v-model="form.name" type="text" />
        </s-field-validate>
        <s-field-validate label="mobile" rules="required">
          <s-textbox v-model="form.mobile_no" type="number" />
        </s-field-validate>
        <!-- <s-field-validate label="Color" rules="required">
          <s-textbox v-model="form.color" type="color" class="w-7" />
          <s-textbox type="text" />
        </s-field-validate> -->
      </template>
    </item-form>
    <!-- Cropper -->
    <cropper ref="cropper" @input="onCrop($event)" />
  </div>
</template>
<script>
import me from "@/api/user";
import user from "@/api/user";
export default {
  components: {
    ItemForm: require("@/components/ItemForm").default,
    Cropper: require("@/components/Cropper").default,
  },
  data() {
    return {
      fields: ["email", "mobile_no", "profileImageId", "name"],
      profileImage: null,
      mediaId: null,
      loader: false,
      formState: null,
    };
  },
  methods: {
    get() {
      return me.get().then((res) => {
        this.mediaId = res.media_id;
        return { ...res, profileImageId: res.avatar };
      });
    },
    removeProfile() {
      let data = this.mediaId;
      this.formState.profileImageId = null;
      this.profileImage = null;
      return user
        .mediaRemove(data)
        .then((res) => {
          this.$notify({
            title: res.message,
            type: "success",
            text: this.error,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    onCrop({ id, value }) {
      this[id] = value;
    },
    openCropper(id, img, ratio) {
      if (!img) return;
      this.$refs.cropper.open(id, img, {
        force: true,
        aspectRatio: ratio || 1,
      });
    },
    async save(id, data) {
      this.loader = true;
      delete data.profileImageId;
      // delete data.email;
      if (this.profileImage) {
        data.avatar = this.profileImage;
      }
      return me.upsert(data).then((res) => {
        this.loader = false;
        this.$root.$emit("rehydrate");
      });
    },
  },
};
</script>
