<template>
  <div class="h-100">
    <portal to="header">
      <s-button
        class=""
        size="sm"
        color="primary"
        theme="primary"
        label="Add New"
        :to="{ name: 'products-upsert', params: { productId: '+' } }"
        icon="vmdi-plus"
      />
    </portal>
    <sp-list
      class="h-100"
      endpoint="/v1/product"
      :attrs="columns"
      :params="params"
      :actions="['settings', 'refresh', 'filters']"
      :perPage="10"
      :filters.sync="filters"
    >
      <template #filters>
        <s-field label="Archive" inline>
          <s-switch
            v-model="filters.archive"
            onValue="1"
            offValue="0"
            size="xs"
            id="archive-1"
            color="primary"
            shape="pill"
          />
        </s-field>
      </template>
      <!-- @rowClick="details($event.id)" -->
      <sp-list-table @rowClick="details($event.id)">
        <template #name="{item}">
          <div>
            {{ item.name }}
            <span
              v-if="item.product_type === 'variation'"
              class="ml-1 text-italic text-grey text-xs"
            >
              ({{ item.variants.length }} Variations)
            </span>
          </div>
        </template>
        <template #product_type="{item}">
          <div class="text-capitalize">
            {{ item.product_type || "simple" }}
          </div>
        </template>
        <template #media="{item}">
          <s-media
            class="radius-3"
            ratio="16x9"
            width="75px"
            :value="item.image"
          />
        </template>
        <template #price="{item}">
          <span v-if="item.product_type === 'variation'">
            (Pricing start with)
          </span>
          {{ "£" + item.price.toFixed(2) }}
        </template>
        <template #is_third_party="{item}">
          <s-button
            v-if="!item.is_third_party"
            color="text-warning text-normal"
            class="bg-warning-lightest text-capitalize text-xs h-4 py-3"
            shape="pill"
            label="No"
          />
          <s-button
            v-else
            color="text-success text-normal"
            class="bg-success-lightest text-capitalize text-xs h-4 py-3"
            shape="pill"
            label="Yes"
          />
        </template>
      </sp-list-table>
    </sp-list>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      filters: {
        archive: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["business"]),
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "media", fix: true },
        { name: "name",fix:100 },
        {
          name: "product_type",
          label: "Type",
        },
        { name: "price",fix:100 },
        { name: "category_name", value: (item) => item?.category?.name },
        { name: "is_third_party", label: "Third Party", fix: true },
      ];
    },
    params() {
      return { business_id: this.business?.id };
    },
  },
  methods: {
    details(id) {
      this.$router.push({
        name: "products-upsert",
        params: {
          productId: id,
        },
      });
    },
  },
};
</script>
