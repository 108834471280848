<template>
  <div>
    <div class="text-primary text-bold text-md mb-3">Your information</div>
    <s-row class="common-row pb-4 bb-lightest mb-3">
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">Your Name</div>
        <div class="text-grey-darkest text-capitalize">
          {{ funeralDetails.contact_name }}
        </div>
      </s-column>
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">Your Surname</div>
        <div class="text-grey-darkest text-capitalize">
          {{ funeralDetails.contact_surname }}
        </div>
      </s-column>
      <s-column :size="3">
        <div class="text-sm text-grey mb-1">Your Email</div>
        <div>
          {{ funeralDetails.contact_email }}
        </div>
      </s-column>
      <s-column :size="4" class="mt-3">
        <div class="text-sm text-grey mb-1">Your Contact Number</div>
        <div v-if="funeralDetails.contact_mobile_no">
          {{ funeralDetails.contact_mobile_no }}
        </div>
        <div v-else class="text-italic text-xs text-grey">
          (Details not provided)
        </div>
      </s-column>
    </s-row>
  </div>
</template>
<script>
export default {
  props: {
    funeralDetails: [Object]
  }
};
</script>
