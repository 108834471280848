<template>
  <div>
    <company-profile v-if="!companySlug" :business="business" />
    <div class="p-5 information-spacing">
      <div class="information-width">
        <div class="text-grey-dark text-bold mb-4" v-html="bannerTitle" />
        <div class="text-grey mb-3" v-html="bannerDesc" />
        <s-field-validate
          rules="required"
          name="Privacy, Terms and Conditions"
          class="mt-4"
        >
          <!-- <s-checkbox
            v-model="isTerms"
            id="checkbox-2"
            label="
            I have read and understood the Privacy Policy and Terms & Conditions."
            class="choice text-bold mt-1"
            @change.native="checkDetails($event)"
            name="terms"
          /> -->
          <div class="checkbox choice text-bold mb-2 custom-checkbox--label">
            <input
              name="terms"
              id="checkbox-2"
              type="checkbox"
              @change="checkDetails($event)"
              v-model="isTerms"
            />
            <label for="checkbox-2" class="choice__label"
              ><span
                >I have read and understood the
                <a href="/app/privacy-policy.pdf" target="_blank">
                  Privacy Policy and Terms &amp; Conditions.</a
                ></span
              ></label
            >
          </div>
        </s-field-validate>
        <s-button
          @click.native="create"
          align="right"
          class="mt-3"
          color="primary"
          theme="primary"
          label="Organise a Funeral"
          :loader="loading"
          icon="vmdi-arrow-right"
          :disabled="isCheck"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { createId } from "@/api/new-request/funeral";

export default {
  props: {
    companySlug: String,
    business: Object
  },
  components: {
    CompanyProfile: require("@/components/CompanyProfile").default
  },
  data() {
    return {
      loading: false,
      isTerms: false,
      isCheck: true
    };
  },
  computed: {
    bannerTitle() {
      // return this.business?.banner_title;
      return this.business?.welcome_screen_title_text;
    },
    bannerDesc() {
      return this.business?.welcome_screen_text;
    }
  },
  methods: {
    async create() {
      this.loading = true;
      let data = {};
      if (this.business?.id) {
        data.business_id = this.business?.id;
      }
      await createId(data)
        .then(res => {
          this.$router.push({
            name: "funeral-starting",
            params: { funeralId: res.uuid }
          });
        })
        .catch(err => {
          console.log("ERRRRR ", err);
        });
      this.loading = false;
    },
    checkDetails($event) {
      if (this.isTerms) {
        this.isCheck = false;
      } else {
        this.isCheck = true;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.information-width {
  width: 730px;
}
</style>
