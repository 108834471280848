export default {
    path: "funeral-director-view/:id",
    name: "funeral-director-view",
    component: require("@/views/funeral-directors/View").default,
    props:true,
    meta: {
      label: "Funeral Directors View",
      breadcrumbs: ["dashboard","funeral-directors","funeral-director-view"],
      icon: "vmdi-view-dashboard",
    },
}