<template>
  <s-layout
    class="template-box"
    full-height
    right-size="400px"
    :right-classlist="['p-3', 'bl-lightest']"
  >
    <template #default>
      <item-form
        :item="templateId"
        :fields="fields"
        class="p-4"
        :save="save"
        :get="get"
        :footer="isDisabled"
        :breadcrumb="false"
        :redirect="false"
        width="710px"
        ref="item"
        :archive="archived"
        :notify="true"
      >
        <template #default="{form}">
          <div
            v-if="isItemArchived !== null"
            :key="isItemArchived"
            class="bg-warning-lightest p-4 mb-2 radius-3 mb-4"
          >
            <p class="text-warning-darkest">
              This item is archived. Unarchive it to make any changes.
            </p>
            <div class="mt-2 flex flex--nowrap flex--between flex--fit">
              <s-button
                color="warning"
                label="Unarchive"
                icon="ArchiveArrowUp"
                @click.native="unarchiveItem"
                :loader="archiving"
              />
            </div>
          </div>
          <s-field-validate label="Name" rules="required">
            <s-textbox
              type="text"
              spellcheck="true"
              v-model="form.name"
              :class="isItemArchived ? 'media-disabled' : ''"
            />
          </s-field-validate>
          <s-field-validate label="Full Description" rules="required">
            <editor
              v-model="form.content"
              :editable="isItemArchived === null"
            />
          </s-field-validate>
        </template>
      </item-form>
    </template>
    <template #right>
      <div class="text-grey mb-3 text-md">
        Form Fields
      </div>
      <div v-if="tagsData">
        <card
          :title="tag.category"
          v-for="(tag, index) in tagsData"
          :key="index"
          :values="tag.fields"
        />
      </div>
    </template>
  </s-layout>
</template>
<script>
import {
  add,
  addTemplateAdmin,
  update,
  updateTemplateAdmin,
  get,
  getTemplateAdmin,
  archive,
  archiveTemplateAdmin,
  unarchive,
  unarchiveTemplateAdmin,
  tags,
} from "@/api/template";
import { mapGetters } from "vuex";
export default {
  props: {
    templateId: [Number, String],
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    Editor: require("@/components/Editor").default,
    Card: require("./Card").default,
  },
  data() {
    return {
      fields: ["name", "content"],
      tagsData: null,
      isItemArchived: null,
      archiving: false,
      isDisabled: true,
    };
  },
  computed: {
    ...mapGetters(["business", "userRoles"]),
    // isNameEdit() {
    //   if (Number(this.templateId)) {
    //     return true;
    //   }
    //   return false;
    // },
  },
  created() {
    tags().then((res) => {
      this.tagsData = res.tags;
    });
  },
  methods: {
    get() {
      if (!this.business?.id) {
        return;
      }
      if (this.userRoles == "admin") {
        return getTemplateAdmin(this.templateId, this.business.id).then(
          (res) => {
            if (res.deleted_at) {
              this.isItemArchived = res.deleted_at;
              this.isDisabled = false;
            } else {
              this.isItemArchived = null;
            }
            return res;
          }
        );
      } else {
        return get(this.templateId, this.business.id).then((res) => {
          if (res.deleted_at) {
            this.isItemArchived = res.deleted_at;
            this.isDisabled = false;
          } else {
            this.isItemArchived = null;
          }
          return res;
        });
      }
    },
    async save(id, data) {
      if (this.userRoles == "admin") {
        if (id) {
          data.id = this.templateId;
          return updateTemplateAdmin(data).then((res) => {
            this.$router.push({ name: "funeral-notice" });
            return res;
          });
        } else {
          return addTemplateAdmin(data).then((res) => {
            this.$router.push({ name: "funeral-notice" });
            return res;
          });
        }
      } else {
        data.business_id = this.business.id;
        if (id) {
          data.id = this.templateId;
          return update(data).then((res) => {
            this.$router.push({ name: "funeral-notice" });
            return res;
          });
        } else {
          return add(data).then((res) => {
            this.$router.push({ name: "funeral-notice" });
            return res;
          });
        }
      }
    },
    archived() {
      let data = {
        id: this.templateId,
        business_id: this.business?.id,
      };
      if (this.userRoles == "admin") {
        return archiveTemplateAdmin(data).then((res) => {
          this.$router.push({ name: "funeral-notice" });
          return res;
        });
      } else {
        return archive(data).then((res) => {
          this.$router.push({ name: "funeral-notice" });
          return res;
        });
      }
    },
    unarchiveItem() {
      this.archiving = true;
      let data = {
        id: this.templateId,
        business_id: this.business?.id,
      };
      if (this.userRoles == "admin") {
        return unarchiveTemplateAdmin(data).then((res) => {
          this.$notify({
            title: res.message,
            type: "success",
            text: this.error,
          });
          this.archiving = false;
          this.isDisabled = true;
          this.get();
          return res;
        });
      } else {
        return unarchive(data).then((res) => {
          this.$notify({
            title: res.message,
            type: "success",
            text: this.error,
          });
          this.archiving = false;
          this.isDisabled = true;
          this.get();
          return res;
        });
      }
    },
  },
};
</script>
