<template>
  <modal
    size="sm"
    id="stripe-payment-modal"
    title="Payment for Subscription"
    :closeOnEsc="false"
    :closeOnOverlay="false"
    :close="false"
    @open="stripeInit"
  >
    <div>
      <sp-list
        class="p-3"
        :actions="['']"
        endpoint="/v1/subscription-plan"
        :footer="false"
      >
        <template #default="{items}">
          <div class="flex flex--fit flex--around">
            <plans
              v-for="(item, index) in items"
              :key="index"
              :item="item"
              v-model="plan"
            />
          </div>
        </template>
      </sp-list>
      <div class="field-group g-4 p-3">
        <s-field label="Your Card Details">
          <div id="card-element"></div>
          <template #end>
            <p v-if="error" class="text-danger mt-2">
              {{ errorMessage }}
            </p>
          </template>
        </s-field>
        <s-button
          :disabled="!plan || error || isEmpty"
          color="primary"
          size="lg"
          label="Pay via Stripe"
          type="submit"
          fluid
          :loader="paying"
          @click.native="pay()"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import { publicKey } from "@/api/subscription";

export default {
  props: {
    businessData: Object,
  },
  components: {
    Modal: require("@/components/Modal").default,
    Plans: require("./Plans").default,
  },
  data() {
    return {
      stripe: null,
      error: false,
      errorMessage: null,
      isEmpty: true,
      card: null,
      paying: false,
      plan: null,
      pubKey: null,
    };
  },
  methods: {
    async stripeInit() {
      this.pubKey = await publicKey().then((res) => res);
      this.stripe = window.Stripe(this.pubKey?.publishable_key);
      const elements = this.stripe.elements();
      this.card = elements.create("card", {
        style: {
          base: {
            backgroundColor: "#e7e5e4",
          },
        },
      });
      this.card.mount("#card-element");
      this.card.on("change", (event) => {
        this.isEmpty = event.empty;
        if (event.error) {
          this.error = true;
          this.error = event.error.message;
        } else {
          this.error = false;
          this.error = null;
        }
      });
    },
    async pay() {
      this.paying = true;
      try {
        const res = await this.stripe.confirmCardPayment(this.plan?.secretKey, {
          payment_method: {
            card: this.card,
          },
        });
        if (res.error) {
          this.$emit("error", res.error.message);
          console.error(res);
        } else {
          this.$shilp.modal.close("stripe-payment-modal");
          if (!this.businessData?.email) {
            this.$shilp.modal.open("company-profile-modal");
          }
        }
      } catch (err) {
        console.error(err);
      }
      this.paying = false;
    },
  },
};
</script>
<style lang="scss">
#card-element {
  background-color: --color(grey, lightest);
  padding: --space(3);
}
.card-list {
  img {
    height: 40px;
  }
}
</style>
