<template>
  <div class="h-100">
    <portal to="header">
      <s-button
        class=""
        size="sm"
        color="primary"
        theme="primary"
        label="Add New"
        :to="{ name: 'service-upsert', params: { serviceId: '+' } }"
        icon="vmdi-plus"
      />
    </portal>
    <sp-list
      class="h-100"
      endpoint="/v1/service"
      :attrs="columns"
      :actions="['settings', 'refresh', 'filters']"
      :perPage="10"
      :params="params"
      :filters.sync="filters"
    >
      <template #filters>
        <s-field label="Archive" inline class="mb-3">
          <s-switch
            v-model="filters.archive"
            onValue="1"
            offValue="0"
            size="xs"
            id="archive-1"
            color="primary"
            shape="pill"
          />
        </s-field>
        <!-- <s-field label="Compulsory Service" inline>
          <s-switch
            v-model="filters.compulsory_service"
            onValue="1"
            offValue="0"
            size="xs"
            id="archive-2"
            color="primary"
            shape="pill"
          />
        </s-field> -->
      </template>
      <sp-list-table @rowClick="details($event.id)">
        <template #media="{item}">
          <s-media
            class="radius-3 bg-grey-lightest"
            ratio="16x9"
            width="75px"
            :value="item.image"
          />
        </template>
        <template #price="{item}">
          {{ "£" + item.price.toFixed(2) }}
        </template>
        <template #is_compulsory="{item}">
          <s-button
            v-if="!item.is_compulsory"
            color="text-warning text-normal"
            class="bg-warning-lightest text-capitalize text-xs h-4 py-3"
            shape="pill"
            label="No"
          />
          <s-button
            v-else
            color="text-success text-normal"
            class="bg-success-lightest text-capitalize text-xs h-4 py-3"
            shape="pill"
            label="Yes"
          />
        </template>
      </sp-list-table>
    </sp-list>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      filters: {
        archive: 0,
        // compulsory_service: 0,
      },
    };
  },
  computed: {
    ...mapGetters(["business"]),
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "media", fix: true },
        {
          name: "type",
          label: "Service Type",
          value: (item) =>
            item.type == "funeral-options"
              ? "Funeral Options"
              : item.type == "product"
              ? "Product"
              : "Third Party",
        },

        {
          name: "funeral_type",
          value: (item) => {
            if (item.funeral_type) {
              return "Tailored " + item.funeral_type;
            }
            return "-";
          },
        },
        {
          name: "name",
          label: "Title/Description",
          value: (item) =>
            item.type == "funeral-options" ? item.desc : item.name,
        },
        { name: "is_compulsory", label: "Compulsory" },
        { name: "price", fix: true },
      ];
    },
    params() {
      return { business_id: this.business?.id };
    },
  },
  methods: {
    // changeFilter(event){
    //   if(event.target.value == 'on'){
    //     this.filters.archive = 1;
    //   }else{
    //     this.filters.archive = 0;
    //   }
    // },
    details(id) {
      this.$router.push({
        name: "service-upsert",
        params: {
          serviceId: id,
        },
      });
    },
  },
};
</script>
