<template>
  <div class="radius-3 bg-grey-lightest-ultra p-3 text-grey mb-3">
    <div class="pb-2 mb-2 bb-lighter text-md text-grey-darkest">
      Funeral Director
    </div>
    <div class="mb-3 text-bold">{{ business.name }}</div>
    <div class="flex flex--nowrap mb-2">
      <div class="mr-3 flex__fit">
        <s-icon class="" :size="15" name="vmdi-domain" />
      </div>
      <div>{{ business.address }}</div>
    </div>
    <div class="flex flex--nowrap">
      <div class="mr-3 flex__fit">
        <s-icon class="" :size="15" name="vmdi-phone" />
      </div>
      <div>{{ business.mobile_no }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    business: Object,
  },
};
</script>
