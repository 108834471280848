<template>
  <div
    class="card p-3 mb-3 flex radius-4 flex--nowrap flex--middle"
    :class="item.id === value ? 'card-active' : ''"
    v-if="item"
    @click="select(item.id)"
  >
    <div class="flex__fit mr-3">
      <s-media
        v-if="item.image"
        :value="item.image"
        class="radius-3"
        width="100px"
        height="100px"
      />
      <s-media
        v-else
        :value="item.image"
        class="radius-3 media--select media__wrap"
        width="100px"
        height="100px"
      />
    </div>
    <div class="">
      <div class="flex flex--fit flex--middle flex--between mb-1">
        <div class="text-primary-darkest text-md text-bold">
          {{ item.name }}
        </div>
      </div>
      <div class="text-primary-light">
        {{ item.description }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: Object,
    value: {
      type: [String, Number],
      default: "",
    },
  },
  methods: {
    select(id) {
      this.$emit("input", id);
    },
  },
};
</script>
