var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'disable-card': _vm.disabled }},_vm._l((_vm.options),function(option,index){return _c('div',{key:index,staticClass:"card p-2 flex flex--nowrap flex--middle mb-3",class:{
      'card-active': _vm.multiple
        ? _vm.isActive(option)
        : _vm.value.some(function (p) { return p.id === option.id; }),
      'cursor-pointer': option.product_type !== 'variation',
    },attrs:{"title":option.product_type === 'variation' ? '' : 'Click to add '},on:{"click":function($event){option.product_type === 'variation' ? '' : _vm.select(option, $event)}}},[_c('div',{staticClass:"flex__fit mr-3 lightbox-container",attrs:{"title":option.image ? 'Click to open ' : ''}},[(option.image)?_c('silent-box',{ref:"silentbox",refInFor:true,attrs:{"gallery":[
          {
            src: option.image,
            srcSet: option.image,
            thumbnailWidth: '90px',
            thumbnailHeight: '65px',
          } ],"lazy-loading":false}}):_c('s-media',{staticClass:"radius-3 bg-grey-lightest",attrs:{"value":option.image,"ratio":"16x9","width":"90px","height":"65px"}}),(option.image)?_c('s-button',{staticClass:"p-1 btn-expand",attrs:{"size":"xs","name":"expand-image","theme":"muted","color":"primary","icon":"vmdi-arrow-expand-all"},nativeOn:{"click":function($event){return _vm.imageExpand(
            {
              src: option.image,
              srcSet: option.image,
              thumbnailWidth: '90px',
              thumbnailHeight: '65px',
            },
            index
          )}}}):_vm._e()],1),_c('div',{},[_c('div',{staticClass:"text-grey text-sm"},[_vm._v(" "+_vm._s(option.name)+" "),(option.is_third_party)?_c('span',{staticClass:"text-xs"},[_vm._v("(Third-party Product)")]):_vm._e()]),(option.product_type === 'variation')?_c('div',_vm._l((option.variants),function(v,index){return _c('s-button',{key:index,staticClass:"mr-1 mt-1 bt-border",class:{
            'button-border': _vm.value.some(
              function (p) { return p.id === option.id && v.id === p.variant_id; }
            ),
          },attrs:{"color":"primary","size":"sm","theme":"muted","title":"Click to add"},nativeOn:{"click":function($event){return _vm.select(Object.assign({}, option,
              {price: v.price,
              variant_id: v.id,
              variant_name: v.name,
              product_price: v.price}))}}},[_c('div',{staticClass:"text-normal"},[_vm._v(" "+_vm._s(v.name)+" ")]),_c('div',[_vm._v(" "+_vm._s("£" + v.price.toFixed(2))+" ")])])}),1):_c('div',{staticClass:"text-grey-darkest text-bold text-lg"},[_vm._v(" "+_vm._s("£" + option.price.toFixed(2))+" ")])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }