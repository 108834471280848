<template>
  <div class="cropper">
    <modal
      size="lg"
      id="crop-modal"
      title="Crop Image"
      full-height
      @save="save"
      @reset="reset"
      @close="cancel()"
      :reset="cropperConfig && cropperConfig.force != true"
      :save-label="saveLabel"
      reset-label="Don't Crop"
      :saving="uploading"
    >
      <div class="h-100 p-3" v-if="img">
        <vue-cropper
          class="h-100"
          :background="false"
          ref="cropper"
          v-bind="cropperConfig"
          :src="img"
          alt="Source Image"
        />
      </div>

      <template #footer-note>
        <s-button-group
          color="secondary"
          shape="square"
          theme="muted"
          size="md"
        >
          <s-button
            icon="vmdi-crop-rotate"
            class="mr-1"
            @click.native="$refs.cropper.rotate(45)"
          />
          <s-button
            icon="vmdi-magnify-plus"
            class="mr-1"
            @click.native="$refs.cropper.relativeZoom(0.2)"
          />
          <s-button
            icon="vmdi-magnify-minus"
            class="mr-1"
            @click.native="$refs.cropper.relativeZoom(-0.2)"
          />
          <s-button icon="vmdi-restart" @click.native="$refs.cropper.reset()" />
        </s-button-group>
        <p class="mt-2 text-grey text-sm">
          Use scroll to zoom-in & zoom-out image.
        </p>
      </template>
    </modal>
  </div>
</template>
<script>
import { toBase64 } from "@/utils/helpers";
export default {
  components: { Modal: require("@/components/Modal").default },
  props: {
    upload: {
      type: Function,
    },
    saveLabel: {
      type: String,
      default: "Crop",
    },
  },
  data() {
    return {
      img: null,
      imgId: null,
      imgType: null,
      cropperConfig: null,
      uploading: false,
    };
  },

  methods: {
    async open(id, img, cropperConfig = {}) {
      if (!img) {
        return;
      }
      if (typeof img != "string") {
        this.imgType = img[0].type;
        img = await toBase64(img[0]);
      }
      this.img = img;
      this.imgId = id;
      this.cropperConfig = cropperConfig;
      this.$shilp.modal.open("crop-modal");
      if (!cropperConfig.force) {
        this.update(img);
      }
    },
    update(img) {
      let payload;
      payload = { value: img, id: this.imgId, config: this.cropperConfig };
      this.$emit("input", payload);
    },
    cancel() {
      this.close();
      //This is required when user cancels the crop operation.
      //When user cancels the crop, we need to remove the selected image.
      this.$emit("cancel", this.imgId);
    },
    close() {
      this.img = this.imgId = null;
      this.$refs.cropper.reset();
    },
    async save() {
      const cropped = this.$refs.cropper
        .getCroppedCanvas()
        .toDataURL(this.imgType);

      if (this.upload) {
        this.uploading = true;
        try {
          const res = await this.upload(cropped);
          this.$emit("success", res);
          this.uploading = false;
        } catch (err) {
          this.$shilp.notify({
            title: "Failed to Upload Image",
            message:
              "Please try with diffrent image or try smaller size of image file.",
            type: "danger",
            duration: 4000,
          });
        }
        this.uploading = false;
      } else {
        this.update(cropped);
      }

      this.$shilp.modal.close("crop-modal");
      this.close();
    },
    reset() {
      this.update(this.img);
      this.$shilp.modal.close("crop-modal");
      this.close();
    },
  },
};
</script>
