<template>
  <request class="p-4" :get="get" #default="{res}" ref="formDetails">
    <div class="text-primary text-bold text-md mb-3">
      Funeral Notice Preview
    </div>
    <s-media
      class="radius-4 image-border mb-4"
      :value="res.notice && res.notice.image"
      width="400px"
      height="400px"
      v-if="res.notice && res.notice.image"
    />
    <div class="paragraph-width ">
      <div
        v-if="res.business.notice_disclaimer"
        v-html="res.business.notice_disclaimer"
        class="mb-4 text-sm text-italic"
      />
      <div v-if="res" v-html="res.notice_preview" />
    </div>
    <s-row class="action-box g-2 mt-5">
      <s-column :size="3">
        <s-button
          fluid
          class="bg-grey-lighter text-grey"
          theme="muted"
          label="Back"
          @click.native="back"
        />
      </s-column>
      <s-column :size="5">
        <s-button
          fluid
          icon="vmdi-arrow-right"
          align="right"
          color="primary"
          :label="nextBtnTitle"
          @click.native="save"
        />
      </s-column>
    </s-row>
  </request>
</template>
<script>
export default {
  props: {
    funeralDetails: [Object],
    funeralId: [Number, String],
  },
  components: {
    Request: require("@/components/Request").default,
  },
  computed: {
    nextBtnTitle() {
      if (this.funeralDetails?.type === "cremation") {
        return "Confirm Funeral Details";
        // Select URNs
      }
      return "Confirm Funeral Details";
    },
  },
  methods: {
    get() {
      return this.funeralDetails;
    },
    async save(id, data) {
      this.$emit("refresh");
      this.$router.push({ name: "confirm-details" });
    },
    back() {
      this.$router.push({ name: "publish-options" });
    },
  },
};
</script>
<style lang="scss" scoped>
.image-border {
  border: 2px dashed #d6d3d1;
}
.text-size-16 {
  font-size: 16px;
}
.paragraph-width {
  max-width: 800px;
}
</style>
