<template>
  <div class="h-100">
    <sp-list
      class="h-100"
      endpoint="/v1/template-content"
      :attrs="columns"
      :params="params"
      :actions="['settings', 'refresh']"
      :perPage="-1"
      :footer="false"
    >
      <sp-list-table @rowClick="details($event.slug)" />
    </sp-list>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["business"]),
    columns() {
      return [{ name: "_index", label: "#", fix: true }, { name: "name" }];
    },
    params() {
      return { business_id: this.business?.id };
    },
  },
  methods: {
    details(id) {
      this.$router.push({
        name: "template-content-upsert",
        params: {
          slug: id,
        },
      });
    },
  },
};
</script>
