<template>
  <sign>
    <template #default>
      <item-form
        :item="true"
        :fields="fields"
        :save="formSave"
        :get="get"
        :footer="true"
        :breadcrumb="false"
        :redirect="false"
        width="400px"
        ref="item"
        :notify="true"
      >
        <template #default="{form}">
          <s-field-validate
            label="Account Email"
            rules="required|email"
            before-icon="Email"
            :desc="emailDesc"
          >
            <s-textbox
              v-model="form.email"
              type="text"
              :disabled="isResetVisible"
            />
          </s-field-validate>

          <s-field-validate
            label="otp"
            rules="required"
            before-icon="vmdi-lastpass"
            v-if="isResetVisible"
          >
            <s-textbox v-model="form.otp" type="text" />
          </s-field-validate>
          <s-field-validate
            label="Password"
            rules="required"
            before-icon="Lock"
            v-if="isResetVisible"
          >
            <s-textbox v-model="form.password" type="password" />
          </s-field-validate>
          <s-field-validate
            label="Password Confirmation"
            rules="required"
            before-icon="Lock"
            v-if="isResetVisible"
          >
            <s-textbox v-model="form.password_confirmation" type="password" />
          </s-field-validate>
        </template>
        <template #footer="{saveItem,saving}" :class="['mt-2']">
          <div>
            <div class="bb pb-4 mb-3">
              <s-button
                icon="vmdi-login-variant"
                align="right"
                color="primary"
                fluid
                label="Reset Password"
                @click.native="saveItem"
                :loader="saving"
              />
            </div>
            <div class="flex flex--fit flex--between">
              <s-button
                align="right"
                type="link"
                label="Have an account?"
                :to="{ name: 'login' }"
                class="p-0"
              />
              <s-button
                align="right"
                type="link"
                label="Help?"
                :to="{ name: 'signin' }"
                class="p-0"
              />
            </div>
          </div>
        </template>
      </item-form>
    </template>
  </sign>
</template>
<script>
import auth from "@/api/auth";
export default {
  components: {
    Sign: require("@/components/Sign").default,
    ItemForm: require("@/components/ItemForm").default,
  },
  data() {
    return {
      fields: ["email", "otp", "password", "password_confirmation"],
      loader: false,
      isResetVisible: false,
      resetEmail: null,
    };
  },
  computed: {
    formSave() {
      if (this.isResetVisible) return this.resetPassword;
      return this.forgetPassword;
    },
    emailDesc() {
      if (this.isResetVisible)
        return "We have sended you an email with password reset instructions.";
      return "We'll send you an email with password reset instructions.";
    },
  },
  methods: {
    get() {
      return {
        email: this.resetEmail,
      };
    },
    async forgetPassword(id, data) {
      this.loader = true;
      this.resetEmail = data.email;
      return auth.forgetPassword(data).then((res) => {
        this.loader = false;
        this.isResetVisible = true;
        return res;
      });
    },
    resetPassword(id, data) {
      return auth.resetPassword(data).then((res) => {
        this.loader = false;
        this.$router.push({ name: "login" });
        return res;
      });
    },
  },
};
</script>
