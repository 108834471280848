<template>
  <modal size="sm" id="status-change-modal" title="Status">
    <div v-if="loading" class="p-5" v-shilp-loader="true" />
    <div class="p-4" v-else>
      <s-field label="Payment Status" class="mb-3">
        <div class="">
          <s-button
            color="text-warning text-normal"
            class="bg-warning-lightest text-xs m-2 h-4 py-3"
            shape="pill"
            label="Unpaid"
            :class="unpaid"

            @click.native="changePaymentStatus('Unpaid')"
          />
          <s-button
            color="text-success text-normal"
            class="bg-success-lightest text-xs m-2 h-4 py-3"
            shape="pill"
            label="Paid"
            :class="paid"

            @click.native="changePaymentStatus('Paid')"
          />
          <s-button
            color="text-danger text-normal"
            class="bg-danger-lightest text-xs m-2  h-4 py-3"
            shape="pill"
            label="Refund"
            :class="refund"

            @click.native="changePaymentStatus('Refund')"
          />
        </div>
      </s-field>
      <s-field label="Funeral Status">
        <div class="">
          <s-button
            color="text-warning text-normal"
            :class="open"
            class="bg-warning-lightest text-xs m-2 h-4 py-3"
            shape="pill"
            label="Open"
            @click.native="changeFuneralStatus('Open')"
          />
          <s-button
            color="text-success text-normal"
            :class="completed"
            class="bg-success-lightest text-xs  m-2 h-4 py-3"
            shape="pill"
            label="Completed"
            @click.native="changeFuneralStatus('Completed')"
          />
          <s-button
            color="text-danger text-normal"
            :class="cancelled"
            class="bg-danger-lightest text-xs m-2  h-4 py-3"
            shape="pill"
            label="Cancelled"
            @click.native="changeFuneralStatus('Cancelled')"
          />
        </div>
      </s-field>
    </div>
  </modal>
</template>
<script>
import { confirmDetails,confirmStatus } from "@/api/new-request/funeral";
// import { confirmStatus } from "@/api/new-request/funeral";

export default {
  props: {
    item: Object,
  },
  components: {
    Modal: require("@/components/Modal").default,
  },
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    open() {
      if (this.item?.status === "Open") {
        return "border-warning";
      }
      return "";
    },
    cancelled() {
      if (this.item?.status === "Cancelled") {
        return "border-warning";
      }
      return "";
    },
    completed() {
      if (this.item?.status === "Completed") {
        return "border-success";
      }
      return "";
    },
    unpaid() {
      if (this.item?.payment_status === "Unpaid") {
        return "border-warning";
      }
      return "";
    },
    paid() {
      if (this.item?.payment_status === "Paid") {
        return "border-success";
      }
      return "";
    },
    refund() {
      if (this.item?.payment_status === "Refund") {
        return "border-warning";
      }
      return "";
    },
  },
  methods: {
    changeFuneralStatus(status) {
      this.loading = true;
      if (!this.item) return;

      let data = {
        status,
        id: this.item?.uuid,
      };
      confirmDetails(data)
        .then((res) => {
          // console.log("res ", res);
          this.loading = false;
          this.$root.$emit("shilp-modal-close", "status-change-modal");
          this.$emit("refresh");
        })
        .catch((err) => {
          console.log("SOMETHING WENT WRONG");
        });
      this.loading = false;
    },
    changePaymentStatus(status) {
      this.loading = true;
      if (!this.item) return;

      let data = {
        payment_status:status,
        id: this.item?.uuid,
      };
      confirmStatus(data)
        .then((res) => {
          // console.log("res ", res);
          this.loading = false;
          this.$root.$emit("shilp-modal-close", "status-change-modal");
          this.$emit("refresh");
        })
        .catch((err) => {
          console.log("SOMETHING WENT WRONG");
        });
      this.loading = false;
    },
  },
};
</script>
<style>
.border-success {
  border: 2px solid #22c55e !important;
}
.border-warning {
  border: 2px solid #eab308 !important;
}
.border-danger {
  border: 2px solid #ef4444 !important;
}
</style>
