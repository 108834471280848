import { SET_FUNERAL_ID, SET_FUNERAL_TYPE } from "./mutation-types";

const state = {
  funeralId: null,
  funeralType: null
};

const getters = {
  funeralId(state) {
    return state.funeralId;
  },
  funeralType(state) {
    return state.funeralType;
  },
};

const mutations = {
  [SET_FUNERAL_ID](state, data) {
    state.funeralId = data;
  },
  [SET_FUNERAL_TYPE](state, data) {
    state.funeralType = data;
  },
};

const actions = {
  funeralId({ commit }, data) {
    commit(SET_FUNERAL_ID, data);
  },
  setFuneralType({ commit }, data) {
    commit(SET_FUNERAL_TYPE, data);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
