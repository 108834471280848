import axios from "@/plugins/axios";

export const plans = () => {
  return axios.get(`/v1/subscription-plan`).then(res => {
    return res.data;
  })
}

export const secretKey = (provider_product_id) => {
  return axios.get(`/v1/stripe/secret-key/${provider_product_id} `).then(res => {
    return res.data;
  })
}

export const publicKey = () => {
  return axios.get(`/v1/stripe/public-key`).then(res => {
    return res.data;
  })
}