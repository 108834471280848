<template>
  <div class="radius-3 bg-grey-lightest-ultra p-3  mb-3" v-if="data.length">
    <div class="pb-2 mb-2 bb-lighter text-md text-grey-darkest">
      Third Party Fees
    </div>
    <sp-list :actions="['']" :data="data" :footer="false">
      <template #default="{items}">
        <!-- flex--middle -->
        <div
          class="flex flex--between flex--fit  mb-3 text-grey"
          v-for="(item, index) in items"
          :key="index"
        >
          <div class="side-product-name">
            <div>
              {{ item.name }}
              <span class="text-capitalize" v-if="item.variant_name"
                >- {{ item.variant_name }}</span
              >
            </div>
            <div v-if="isQuantity(item)">
              <div class="flex flex--fit quantity-handler mt-1">
                <s-button
                  size="xs"
                  theme="muted"
                  color="primary"
                  icon="vmdi-minus"
                  class="px-2 btn-minus"
                  @click.native="quantityHandler(item)"
                  :disabled="item.quantity === 1"
                />
                <s-field size="xs">
                  <input
                    type="number"
                    v-model="item.quantity"
                    class="radius-0 field-block"
                    @blur="quantityCheck($event, item)"
                  />
                </s-field>
                <s-button
                  size="xs"
                  theme="muted"
                  color="primary"
                  icon="vmdi-plus"
                  class="px-2 btn-plus"
                  @click.native="quantityHandler(item, false)"
                />
              </div>
            </div>
          </div>
          <!-- flex--middle  -->
          <div class="flex flex--fit ">
            <div
              class="text-bold text-grey-darkest mr-1"
              v-if="isQuantity(item)"
            >
              {{ "£" + (item.quantity * item.product_price).toFixed(2) }}
            </div>
            <div class="text-bold text-grey-darkest mr-1" v-else>
              {{ "£" + item.product_price.toFixed(2) }}
            </div>
            <s-button
              size="xs"
              shape="circle"
              icon="Delete"
              color="danger"
              theme="outline"
              @click.native="removeItem(item)"
              :disabled="item.is_compulsory"
            />
          </div>
        </div>
        <div
          class="flex flex--between flex--fit flex--middle text-grey-darkest bt-lighter pt-3"
        >
          <div class="">
            Subtotal
          </div>
          <div class="text-bold">
            <span class="text-xs text-normal text-grey">From</span>
            {{ "£" + total.toFixed(2) }}
          </div>
        </div>
      </template>
    </sp-list>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      total: 0,
    };
  },
  watch: {
    data: {
      deep: true,
      handler(value) {
        this.baseTotal(value);
      },
    },
  },
  methods: {
    baseTotal(items) {
      this.total = items.reduce((initial, current) => {
        if (this.isQuantity(current)) {
          return initial + current.product_price * current.quantity;
        }
        return initial + current.product_price;
      }, 0);
      this.$emit("servicesTotal", { total: this.total, name: "services" });
    },
    removeItem(item) {
      this.$emit("removeService", item);
    },
    isQuantity(item) {
      if (item?.category?.is_quantity_enable) {
        return true;
      }
      return false;
    },
    quantityHandler(item, op = true) {
      this.$emit("quantityHandler", item, op);
    },
    quantityCheck(e, item) {
      this.$emit("quantityInput", e, item);
    },
  },
};
</script>
<style lang="scss">
.side-product-name {
  width: 160px;
}
</style>
