import { SET_USER, SET_PREF, SET_BUSINESS } from "./mutation-types";

const state = {
  user: null,
  business: null,
  isAdminNavCollapsed: false,
};

const getters = {
  user(state) {
    return state.user;
  },
  userRoles: state => {
    return (state.user && state.user.user_roles[0]) || null;
  },
  userFullName(state) {
    return state.user?.name
  },
  avatar(state) {
    return state.user?.avatar
  },
  business(state) {
    return state.business;
  }
};

const mutations = {
  [SET_USER](state, data) {
    state.user = data;
  },
  [SET_PREF](state, data) {
    state.isAdminNavCollapsed = data;
  },
  [SET_BUSINESS](state, data) {
    state.business = data;
  },
};

const actions = {
  setUser({ commit }, data) {
    commit(SET_USER, data);
  },
  setIsAdminNavCollapsed({ commit }, data) {
    commit(SET_PREF, data);
  },
  setBusiness({ commit }, data) {
    commit(SET_BUSINESS, data);
  }
};

export default {
  state,
  getters,
  mutations,
  actions,
};
