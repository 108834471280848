<template>
  <item-form
    :item="true"
    :fields="fields"
    :save="save"
    :get="get"
    :footer="true"
    :breadcrumb="false"
    :redirect="false"
    width="400px"
    class="p-4"
    ref="item"
    :notify="true"
    :gap="3"
  >
    <template #default="{form}">
      <!-- <s-field-validate label="Email or Username" rules="required">
        <s-textbox v-model="form.username" type="text" />
      </s-field-validate> -->
      <s-field-validate label="Current Password" rules="required">
        <s-textbox v-model="form.current_password" type="password" />
      </s-field-validate>
      <s-field-validate vid="newPassword" name="newPassword" label="New Password" rules="required">
        <s-textbox v-model="form.password" type="password" />
      </s-field-validate>
      <s-field-validate label="Re-enter Password" rules="required|password:@newPassword">
        <s-textbox v-model="form.password_confirmation" type="password" />
      </s-field-validate>
    </template>
  </item-form>
</template>
<script>
import me from "@/api/user";
export default {
  components: {
    ItemForm: require("@/components/ItemForm").default,
  },
  data() {
    return {
      fields: ["current_password", "password", "password_confirmation"],
    };
  },
  methods: {
    get() {
      return {};
    },
    async save(id, data) {
      return me.changePassword(data).then((res) => {
        return res;
      });
    },
  },
};
</script>
