<template>
  <item-form
    :item="funeralId"
    :fields="fields"
    class="p-4"
    :save="save"
    :get="get"
    :form-state.sync="formValues"
    :footer="true"
    :breadcrumb="false"
    :redirect="false"
    width="400px"
    ref="item"
    :notify="true"
    :gap="3"
  >
    <template #default="{form}">
      <div class="text-primary text-bold text-md ">
        Details of the Deceased
      </div>
      <s-field-validate label="Deceased First Name" rules="required">
        <s-textbox v-model="form.first_name" type="text" spellcheck="true" />
      </s-field-validate>
      <s-field-validate label="Surname" rules="required">
        <s-textbox v-model="form.last_name" type="text" spellcheck="true" />
      </s-field-validate>
      <s-field-validate label="Nee (Maiden Name)">
        <s-textbox v-model="form.maiden_name" type="text" spellcheck="true" />
      </s-field-validate>
      <s-field-validate label="Date of Birth">
        <!-- <v-date-picker v-model="form.dob" :clickable="true" /> -->
        <dropdown-date-time v-model="form.dob" />
      </s-field-validate>
      <s-field-validate label="Date of Death">
        <!-- <v-date-picker :clickable="true" v-model="form.dod" /> -->
        <dropdown-date-time v-model="form.dod" />
      </s-field-validate>
      <s-field-validate label="Place of Death">
        <s-textbox
          v-model="form.place_of_death"
          type="text"
          spellcheck="true"
        />
      </s-field-validate>
      <s-field-validate label="Age">
        <s-textbox v-model="form.age" type="number" disabled />
      </s-field-validate>
      <s-field
        inline
        label="Would you like a funeral notice to be placed in the press?"
      >
        <s-switch
          v-model="form.publish_notice"
          size="xs"
          color="primary"
          shape="pill"
        />
      </s-field>
      <s-field-validate
        label="Circumstances (e.g. Peacefully, Suddenly, Surrounded by, ...)"
      >
        <s-textbox v-model="form.circumstances" spellcheck="true" />
      </s-field-validate>
      <s-field-validate label="Other Relevant Addresses">
        <s-textbox v-model="form.relevant_address" spellcheck="true" />
      </s-field-validate>
    </template>
    <template #footer="{saveItem,saving}" :class="['']">
      <s-row class="g-2 row-block">
        <s-column :size="3">
          <s-button
            fluid
            class="bg-grey-lighter text-grey"
            theme="muted"
            label="Back"
            @click.native="back"
          />
        </s-column>
        <s-column :size="9">
          <s-button
            fluid
            icon="vmdi-arrow-right"
            align="right"
            color="primary"
            label="Funeral Family Reference"
            :loader="saving"
            @click.native="saveItem"
          />
        </s-column>
      </s-row>
    </template>
  </item-form>
</template>
<script>
import { deceasedDetail } from "@/api/new-request/notice";
// import { contactDetails } from "@/api/new-request/funeral";

export default {
  props: {
    funeralDetails: [Object],
    funeralId: [Number, String],
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    // VDatePicker: require("@/components/VDatePicker").default,
    DropdownDateTime: require("@/components/DropdownDateTime").default,
  },
  data() {
    return {
      fields: [
        "first_name",
        "last_name",
        "maiden_name",
        "dob",
        "dod",
        "place_of_death",
        "age",
        "circumstances",
        "relevant_address",
        "publish_notice",
      ],
      formValues: null,
    };
  },
  methods: {
    get() {
      return {
        ...this.funeralDetails,
        publish_notice: this.funeralDetails?.publish_notice ? true : "",
      };
    },
    async save(id, data) {
      data.id = this.funeralId;
      data.publish_notice = data.publish_notice ? 1 : 0;

      let code = localStorage.getItem("vfa-code");
      return deceasedDetail(data, code).then(async (res) => {
        this.$emit("refresh");
        this.$router.push({ name: "family-references" });
        return res;
      });
    },
    back() {
      if (this.funeralDetails?.funeral_type_id === 3) {
        this.$router.push({ name: "funeral-product" });
      } else {
        this.$router.push({
          name: "urns",
        });
      }
    },
  },
};
</script>
<style lang="scss">
.family-checkbox {
  .options {
    margin-top: 5px;
    .choice {
      margin-bottom: 10px;
    }
  }
}
</style>
