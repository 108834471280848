<template>
  <request :get="get" #default="{res}" ref="companyDetails">
    <div
      class="px-5 py-4 profile-height main-profile"
      :style="{
        'background-color': res.brand_color,
        color: res.text_color,
      }"
    >
      <div class="flex--middle company-profile">
        <div v-if="res.logo" class="flex flex--column company-profile__logo">
          <s-media
            v-if="res.logo"
            :value="res.logo"
            class="company-logo"
            :readonly="true"
          />
        </div>
        <div class="" :class="res.logo ? 'company-profile__content' : 'company-profile__content pl-0'">
          <div class="text-lg mb-3 text-bold text-capitalize">
            {{ res.name }}
          </div>
          <div class="grid grid--2 g-3 mb-3 business-detail">
            <div class="flex flex--fit flex--nowrap">
              <s-icon
                :size="15"
                class="rounded mr-2 flex__fit"
                name="vmdi-web"
              />
              <!-- flex__fit -->
              <div class="flex__fluid">
                <a :href="res.website" target="_blank">{{ res.website }}</a>
              </div>
            </div>
            <div class="flex flex--fit flex--nowrap">
              <s-icon :size="15" class="rounded mr-2 " name="vmdi-phone" />
              <div class="">
                <a :href="`tel:${res.mobile_no}`">{{ res.mobile_no }}</a>
              </div>
            </div>
            <div class="flex  flex--fit flex--nowrap">
              <s-icon
                :size="15"
                class="rounded mr-2  flex__fit"
                name="vmdi-domain"
              />
              <div class=" flex__fluid">
                {{ res.address }}
              </div>
            </div>
            <div class="flex  flex--fit flex--nowrap">
              <s-icon :size="15" class="rounded mr-2 " name="Email" />
              <div class="">
                <a :href="`mailto:${res.email}`">
                  {{ res.email }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </request>
</template>
<script>
export default {
  props: {
    business: Object,
  },
  components: {
    Request: require("@/components/Request").default,
  },
  methods: {
    get() {
      return this.business;
    },
  },
};
</script>
<style lang="scss">
.profile-height {
  height: 190px;
  display:flex;
  width:100%;
  align-items: center;
  .business-detail{
    margin-bottom:0px !important;
  }
}
.company-logo {
  .media__wrap {
    img {
      width: 100%;
      height: 135px;
      object-fit: contain;
      object-position: center;
    }
  }
}
.business-detail {
  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}
.company-profile {
  max-width: 800px;
  display: flex;
  &__logo {
    flex: 0 0 320px;
  }
  &__content {
    flex: 1;
    padding-left: 40px;
    &.pl-0{
      padding-left: 0px;
    }
  }
}
</style>
