var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[_c('portal',{attrs:{"to":"header"}},[_c('s-button',{attrs:{"size":"sm","color":"primary","theme":"primary","label":"Add New","to":{ name: 'products-upsert', params: { productId: '+' } },"icon":"vmdi-plus"}})],1),_c('sp-list',{staticClass:"h-100",attrs:{"endpoint":"/v1/product","attrs":_vm.columns,"params":_vm.params,"actions":['settings', 'refresh', 'filters'],"perPage":10,"filters":_vm.filters},on:{"update:filters":function($event){_vm.filters=$event}},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('s-field',{attrs:{"label":"Archive","inline":""}},[_c('s-switch',{attrs:{"onValue":"1","offValue":"0","size":"xs","id":"archive-1","color":"primary","shape":"pill"},model:{value:(_vm.filters.archive),callback:function ($$v) {_vm.$set(_vm.filters, "archive", $$v)},expression:"filters.archive"}})],1)]},proxy:true}])},[_c('sp-list-table',{on:{"rowClick":function($event){return _vm.details($event.id)}},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.name)+" "),(item.product_type === 'variation')?_c('span',{staticClass:"ml-1 text-italic text-grey text-xs"},[_vm._v(" ("+_vm._s(item.variants.length)+" Variations) ")]):_vm._e()])]}},{key:"product_type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.product_type || "simple")+" ")])]}},{key:"media",fn:function(ref){
var item = ref.item;
return [_c('s-media',{staticClass:"radius-3",attrs:{"ratio":"16x9","width":"75px","value":item.image}})]}},{key:"price",fn:function(ref){
var item = ref.item;
return [(item.product_type === 'variation')?_c('span',[_vm._v(" (Pricing start with) ")]):_vm._e(),_vm._v(" "+_vm._s("£" + item.price.toFixed(2))+" ")]}},{key:"is_third_party",fn:function(ref){
var item = ref.item;
return [(!item.is_third_party)?_c('s-button',{staticClass:"bg-warning-lightest text-capitalize text-xs h-4 py-3",attrs:{"color":"text-warning text-normal","shape":"pill","label":"No"}}):_c('s-button',{staticClass:"bg-success-lightest text-capitalize text-xs h-4 py-3",attrs:{"color":"text-success text-normal","shape":"pill","label":"Yes"}})]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }