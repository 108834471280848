<template>
  <p>Logging out...</p>
</template>
<script>
import { resetStore } from "@/store";
export default {
  created() {
    localStorage.removeItem("vfa-token");
    this.$router.push({ name: "login" });
    resetStore();
  },
};
</script>
