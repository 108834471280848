import axios from "@/plugins/axios";


export const get = (data) => {
  return axios.get(`/v1/template-content/${data.slug}?business_id=${data.id}`).then(res => {
    return res.data;
  })
}

export const update = (data) => {
  return axios.put(`/v1/template-content/${data.slug}`, data).then(res => {
    return res.data;
  })
}

export const getTags = (data) => {
  return axios.get(`/v1/template-tags/${data.slug}`).then(res => {
    return res.data;
  })
}