import Vue from "vue";
import Vuex from "vuex";
import account from "./account";
import hydrate from "./hydrate";
import root from "./root";
import funeral from "./funeral";


Vue.use(Vuex);

const store = new Vuex.Store({
  ...root,
  modules: {
    hydrate,
    account,
    funeral
  },
  plugins: [],
});

// Initial state
const initialState = JSON.parse(JSON.stringify(store.state));

//Resets the store when user logs out
const resetStore = () => {
  store.replaceState(JSON.parse(JSON.stringify(initialState)));
  localStorage.removeItem("vuex"); //Removes VueX Persisted Data
};

export default store;
export { resetStore };
