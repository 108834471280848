export default {
  path: "funeral-notice-templates",
  name: "funeral-notice",
  component: require("@/views/notice/Index").default,
  redirect: { name: "noticeTemplates" },
  props: true,
  children: [
    {
      path: "",
      component: require("@/views/notice/List").default,
      name: "noticeTemplates",
      props: true,
      meta: {
        label: "Funeral Notice Templates",
        breadcrumbs: ["dashboard", "noticeTemplates"],
      },
    },
    {
      path: ":templateId",
      component: require("@/views/notice/Template").default,
      name: "template",
      props: true,
      meta: {
        label: "Template",
        breadcrumbs: ["dashboard", "noticeTemplates", "template"],
      },
    }
  ]
}