<template>
  <div class="radius-3 bg-grey-lightest-ultra p-3 text-grey mb-3">
    <div class="pb-2 mb-2 bb-lighter text-md text-grey-darkest">
      {{ title }}
    </div>
    <div v-for="(value, index) in values" :key="index" class="mb-2">
      {{<s-button
        @click.native="copy(`{{${value}}}`)"
        class="p-0 m-0 text-normal text-grey h-auto"
      >
        {{ value }} </s-button
      >}}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    values: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    copy(value) {
      this.$shilp.copy(value);
      this.$notify({
        type: "success",
        title: "Copied!",
        message: value,
        duration: 500,
      });
    },
  },
};
</script>
