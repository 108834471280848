<template>
  <div
    class="card p-2 flex border-radius flex--nowrap mb-3 flex--middle"
    :class="activeClass"
    v-if="item"
    @click="item.product_type === 'variation' ? '' : select(item, $event)"
    :title="item.product_type === 'variation' ? '' : 'Click to add'"
  >
    <div
      class="flex__fit mr-3 lightbox-container"
      :title="image ? 'Click to open' : ''"
    >
      <!-- <float-preview
        v-if="image"
        :src="image"
        class="img-urn-size"
        :max-width="500"
        :max-height="400"
      /> -->
      <silent-box
        v-if="image"
        class="silent-card"
        :ref="'silentbox'"
        :gallery="[
          {
            src: image,
            srcSet: image,
            thumbnailWidth: '90px',
            thumbnailHeight: '65px',
          },
        ]"
      />
      <s-media
        v-else
        :value="image"
        class="radius-3 bg-grey-lightest"
        ratio="16x9"
        width="90px"
        height="65px"
      />
      <s-button
        v-if="image"
        size="xs"
        theme="muted"
        color="primary"
        class="p-1 btn-expand"
        icon="vmdi-arrow-expand-all"
        @click.native="
          imageExpand({
            src: image,
            srcSet: image,
            thumbnailWidth: '90px',
            thumbnailHeight: '65px',
          })
        "
      />
    </div>
    <div class="">
      <div class="text-grey text-sm text-capitalize text-bold">
        {{ item.name }}
        <span v-if="item.is_third_party" class="text-xs text-normal"
          >(Third-party Product)</span
        >
      </div>
      <div class="mt-1" v-if="item.product_type === 'variation'">
        <s-button
          v-for="(v, index) in item.variants"
          :key="index"
          color="primary"
          size="sm"
          theme="muted"
          class="mr-1 mt-1 bt-border"
          @click.native="select({ ...item, price: v.price, variant_id: v.id })"
          :class="{
            'button-border': item.id === value.id && v.id === value.variant_id,
          }"
          title="Click to add"
        >
          <div class="text-normal">
            {{ v.name }}
          </div>
          <div>
            {{ "£" + v.price.toFixed(2) }}
          </div>
        </s-button>
      </div>
      <div v-else class="text-grey-darkest text-bold text-lg">
        {{ "£" + item.price.toFixed(2) }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: Object,
    value: {
      type: [Object],
      default: () => {}
    }
  },
  computed: {
    activeClass() {
      if (this.item?.id === this.value?.id) {
        return "card-active cursor-pointer";
      }
      return "";
    },
    image() {
      return this.item?.image;
    }
  },
  methods: {
    select(item, e) {
      if (e?.target?.localName === "img") {
        return;
      }
      if (item.variant_id) {
        if (
          this.value.variant_id &&
          item?.variant_id == this.value?.variant_id
        ) {
          this.$emit("input", {});
        } else {
          this.$emit("input", { ...item });
        }
      } else {
        if (this.value?.id === item?.id) {
          this.$emit("input", {});
        } else {
          this.$emit("input", { ...item });
        }
      }
    },
    imageExpand(item) {
      this.$refs.silentbox.openOverlay(item);
    }
  }
};
</script>
<style lang="scss">
.bt-border {
  border: 2px solid #daeeec !important;
}
.border-radius {
  border-radius: 10px;
}
.vfp-content-image-placeholder {
  border-radius: 4px;
}
.cursor-pointer {
  cursor: pointer;
}
.button-border {
  border: 2px solid #465659 !important;
}
@media (max-width: 992px) {
  .card {
    width: 100%;
    .media--select {
      width: 60px !important;
      height: 60px !important;
    }
    .text-lg {
      font-size: 16px !important;
    }
  }
  .silent-card {
    width: 90px;
    height: 65px;
    object-fit: contain;
    object-fit: center;
  }
}
</style>
