<template>
  <div class="mb-4">
    <div class="text-primary text-bold text-md mb-3">
      Funeral Cost Estimation
    </div>
    <s-row class="common-row mb-3" :gap="3">
      <s-column
        :size="6"
        class="radius-3 bg-grey-lightest-ultra p-3 text-grey mb-3"
      >
        <div v-if="funeralOptions">
          <div class="pb-2 mb-2 bb-lighter text-sm text-bold text-grey-darkest">
            Funeral Service
          </div>
          <div class="mb-1">
            {{ funeralOptions.desc }}
          </div>
          <div class="text-grey-darkest text-bold text-md mb-2">
            {{ "£" + funeralOptions.price.toFixed(2) }}
          </div>
        </div>
        <div v-else>
          <div class="pb-2 mb-2 bb-lighter text-sm text-bold text-grey-darkest">
            Funeral Service
          </div>
          <div class="text-italic text-xs text-grey text-center">
            (Funeral Service not selected)
          </div>
        </div>
      </s-column>
    </s-row>
    <s-row class="table-row mb-3" :gap="3">
      <s-column :size="6" class="radius-3 bg-grey-lightest-ultra p-3 text-grey">
        <div class="pb-2 bb-lighter text-sm text-bold text-grey-darkest">
          Product Choice
        </div>
        <sp-list
          :actions="['']"
          :data="products"
          :footer="false"
          :per-page="-1"
        >
          <template #default="{ items }">
            <div class="product-list--scroll">
              <s-row
                v-for="(item, index) in items"
                :key="index"
                class="product-card mb-3"
              >
                <s-column :size="4" class="product-card__logo">
                  <s-media
                    class="radius-3 bg-grey-lightest"
                    :readonly="true"
                    ratio="16x9"
                    width="85px"
                    :value="item.image"
                  />
                </s-column>
                <s-column :size="6" class="product-card__content">
                  <div class="text-capitalize">
                    {{ item.name }}
                    <span v-if="item.variant_name"
                      >-
                      {{ item.variant_name }}
                    </span>
                    <span v-if="isQuantity(item)"> × {{ item.quantity }} </span>
                  </div>
                  <div v-if="item.category" class="text-bold">
                    ({{ item.category.name }})
                  </div>
                </s-column>
                <s-column
                  :size="2"
                  class="text-bold text-grey-darkest product-card__price"
                >
                  <div class="flex flex--fit flex--right">
                    {{ "£" + item.price.toFixed(2) }}
                  </div>
                </s-column>
              </s-row>
            </div>
            <div
              class="flex flex--fit flex--between bt-light pt-3 flex--middle"
            >
              <div class="text-grey text-bold text-sm">Subtotal</div>
              <div class="text-bold text-natural">
                {{ "£" + productTotal.toFixed(2) }}
              </div>
            </div>
          </template>
          <template #empty>
            <div class="text-italic text-xs text-grey text-center">
              (Product not selected)
            </div>
          </template>
        </sp-list>
      </s-column>
      <s-column :size="6" class="radius-3 bg-grey-lightest-ultra p-3 text-grey">
        <div class="pb-2 bb-lighter text-sm text-bold text-grey-darkest">
          Third-party Service Choice
        </div>
        <sp-list
          :actions="['']"
          :data="thirdParty"
          :footer="false"
          :per-page="-1"
        >
          <template #default="{ items }">
            <div class="product-list--scroll">
              <s-row
                v-for="(item, index) in items"
                :key="index"
                class="product-card mb-3"
              >
                <s-column :size="4" class="product-card__logo">
                  <s-media
                    class="radius-3 bg-grey-lightest"
                    :readonly="true"
                    ratio="16x9"
                    width="85px"
                    :value="item.image"
                  />
                </s-column>
                <s-column :size="6" class="product-card__content">
                  <div class="text-capitalize">
                    {{ item.name }}
                    <span v-if="item.variant_name"
                      >-
                      {{ item.variant_name }}
                    </span>
                    <span v-if="isQuantity(item)"> × {{ item.quantity }} </span>
                  </div>
                  <div v-if="item.is_third_party" class="text-bold">
                    (Third-party Product)
                  </div>
                </s-column>
                <s-column
                  :size="2"
                  class="text-bold text-grey-darkest product-card__price"
                >
                  <div class="flex flex--fit flex--right">
                    {{ "£" + item.price.toFixed(2) }}
                  </div>
                </s-column>
              </s-row>
            </div>
            <div
              class="flex flex--fit flex--between bt-light pt-3 flex--middle"
            >
              <div class="text-grey text-bold text-sm">Subtotal</div>
              <div class="text-bold text-natural">
                {{ "£" + thirdPartyTotal.toFixed(2) }}
              </div>
            </div>
          </template>
          <template #empty>
            <div class="text-italic text-xs text-grey text-center">
              (Third-party Service not selected)
            </div>
          </template>
        </sp-list>
      </s-column>
    </s-row>
    <s-row class="table-row" :gap="3">
      <s-column :size="6">
        <div
          class="
            bg-grey-lightest
            flex flex--between flex--fit flex--middle
            mb-3
            p-3
            radius-3
            text-bold text-md
          "
        >
          <div>Guide Price Total</div>
          <div>{{ "£" + grandTotal }}</div>
        </div>
      </s-column>
    </s-row>
  </div>
</template>
<script>
export default {
  props: {
    funeralDetails: Object
  },
  computed: {
    funeralOptions() {
      return this.funeralDetails?.products.find(
        p => p.type === "funeral-options"
      );
    },
    products() {
      return this.funeralDetails?.products.filter(
        p =>
          // p.category?.name !== "URN" &&
          p.type !== "funeral-options" &&
          p.type !== "third-party" &&
          !p.is_third_party
      );
    },
    grandTotal() {
      return this.funeralDetails?.total_amount?.toFixed(2) || 0.0;
    },
    productTotal() {
      return (
        this.products?.reduce((initial, current) => {
          return current.price + initial;
        }, 0) || 0
      );
    },
    thirdParty() {
      return this.funeralDetails?.products.filter(
        p => p.type === "third-party" || p.is_third_party
      );
    },
    thirdPartyTotal() {
      return (
        this.thirdParty?.reduce((initial, current) => {
          return initial + current.price;
        }, 0) || 0
      );
    }
  },
  methods: {
    isQuantity(item) {
      if (item?.category?.is_quantity_enable) {
        return true;
      }
      return false;
    }
  }
};
</script>
<style lang="scss" scoped>
.product-list {
  &--scroll {
    padding: 10px 0px 0;
    padding-right: 10px;
    height: 260px;
    overflow: auto;
    margin-top: -8px;
  }
}
.product-card {
  &__content {
    padding-right: 10px;
  }
}
@media (max-width: 1024px) {
  .product-card {
    &__content {
      padding-left: 10px;
    }
  }
  .row.table-row {
    .column + .column {
      margin-top: 20px;
    }
  }
}
</style>