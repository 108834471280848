import {
  HYDRATING,
  HYDRATED,
  HYDRATION_FAILED,
  REHYDRATE,
} from "./mutation-types";

const state = {
  hydrating: false,
  hydrated: false,
  failed: null,
  rehydrate: false,
};

const getters = {
  hydrated: (state) => state.hydrated,
  hydrating: (state) => state.hydrating,
};

const mutations = {
  [HYDRATING](state) {
    state.hydrating = true;
  },
  [REHYDRATE](state) {
    state.rehydrate = true;
  },
  [HYDRATED](state) {
    state.rehydrate = false;
    state.hydrated = true;
    state.hydrating = false;
  },
  [HYDRATION_FAILED](state) {
    state.failed = true;
    state.hydrating = false;
  },
};

const actions = {
  async hydrate({ commit, dispatch }, data) {
    commit(HYDRATING);
    try {
      await dispatch("setUser", data.user);
      await dispatch("setBusiness", data.business);
      commit(HYDRATED);
    } catch (err) {
      commit(HYDRATION_FAILED);
    }
  },

  rehydrate({ commit }) {
    commit(REHYDRATE);
  },
};

export default {
  state,
  getters,
  mutations,
  actions,
};
