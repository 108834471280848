<template>
  <sign>
    <template #default>
      <item-form
        :item="true"
        :fields="fields"
        :save="login"
        :get="get"
        :footer="true"
        :breadcrumb="false"
        :redirect="false"
        width="400px"
        ref="item"
        :notify="true"
      >
        <template #default="{form}">
          <div class="text-sm text-grey text-bold">
            Welcome to Virtual Funeral Arranger portal.
          </div>
          <s-field-validate
            label="Email"
            rules="required|email"
            before-icon="Email"
          >
            <s-textbox v-model="form.email" type="text" />
          </s-field-validate>
          <s-field-validate
            label="Password"
            rules="required"
            before-icon="Lock"
          >
            <s-textbox v-model="form.password" type="password" />
          </s-field-validate>
        </template>
        <template #footer="{saveItem,saving}" :class="['mt-2']">
          <div>
            <div class="bb pb-2 mb-3">
              <s-button
                icon="vmdi-login-variant"
                align="right"
                color="primary"
                fluid
                label="Login"
                @click.native="saveItem"
                :loader="saving"
              />
              <s-button
                align="right"
                type="link"
                label="Forgot password?"
                :to="{ name: 'forgetPassword' }"
                class="p-0"
              />
            </div>
            <div class="flex flex--fit flex--between">
              <s-button
                align="right"
                type="link"
                label="Register as a new Partner"
                :to="{ name: 'signin' }"
                class="p-0"
              />
              <s-button
                align="right"
                type="link"
                label="Help?"
                :to="{ name: 'signin' }"
                class="p-0"
              />
            </div>
          </div>
        </template>
      </item-form>
    </template>
  </sign>
</template>
<script>
import auth from "@/api/auth";
import { setToken } from "@/plugins/axios";
export default {
  components: {
    Sign: require("@/components/Sign").default,
    ItemForm: require("@/components/ItemForm").default,
  },
  data() {
    return {
      fields: ["email", "password"],
      loader: false,
    };
  },
  methods: {
    get() {
      return {};
    },
    async login(id, data) {
      this.loader = true;
      return auth.login(data).then((res) => {
        this.loader = false;
        setToken(res.token);
        localStorage.setItem("vfa-token", res.token);
        if (res.user.user_roles[0] == "admin") {
          // this.$router.push("/funeral-directors");
          this.$router.push({ name: "funeral-directors" });
        } else {
          this.$router.push("/");
        }
        return res;
      });
    },
  },
};
</script>
