<template>
  <div>
    <sign>
      <template #default>
        <item-form
          :item="true"
          :fields="fields"
          :save="signup"
          :get="get"
          :footer="true"
          :breadcrumb="false"
          :redirect="false"
          width="400px"
          ref="item"
          :notify="true"
        >
          <template #default="{form}">
            <!-- part one -->
            <p class="text-sm text-grey text-bold">
              After registration, we will send you a code for you to validate
              your email and activate your account.
            </p>
            <!-- <p class="text-grey-light text-lg">
              Subscription
            </p> -->

            <!-- <s-field-validate
              rules="required"
              before-icon="Email"
              class="bb-light pb-4"
              name="Business Standard"
            >
              <s-textbox
                v-model="form.subscription_plan_id"
                type="text"
                placeholder="Business Standard"
              />
            </s-field-validate> -->
            <!-- <s-field label="Basic Plan" desc=" *Stripe integration is pending.">
            </s-field> -->

            <!-- part two -->
            <p class="text-grey-light text-lg">
              Login Profile
            </p>
            <s-field-validate
              rules="required"
              name="Business Name"
              label="Business Name"
            >
              <s-textbox v-model="form.business_name" type="text" />
            </s-field-validate>
            <s-field-validate label="Your Full Name" rules="required">
              <s-textbox v-model="form.name" type="text" />
            </s-field-validate>
            <s-field-validate
              label="Your Email Address"
              rules="required|email"
              desc="Your registered email will be your username during login."
            >
              <s-textbox v-model="form.email" type="text" />
            </s-field-validate>
            <s-field-validate
              label="Mobile no or Landline"
              rules="required|min:7|max:15"
            >
              <s-textbox v-model="form.mobile_no" type="number" />
            </s-field-validate>
            <s-field-validate label="Password" rules="required">
              <s-textbox v-model="form.password" type="password" />
            </s-field-validate>
            <s-field-validate
              label="Confirm Password"
              rules="required"
              desc="By clicking Sign up, you agree to the Terms of Use and you have read the website's Privacy Policy, including the website's Cookie Use."
            >
              <s-textbox v-model="form.password_confirmation" type="password" />
            </s-field-validate>
          </template>
          <template #footer="{saveItem,saving}" :class="['mt-2']">
            <div>
              <div class="bb-light pb-3 mb-3">
                <s-button
                  icon="vmdi-login-variant"
                  align="right"
                  color="primary"
                  fluid
                  label="Signup"
                  @click.native="saveItem"
                  :loader="saving"
                />
              </div>
              <s-button
                align="right"
                type="link"
                label="Already a Partner? Sign-in now"
                :to="{ name: 'login' }"
                class="p-0"
              />
            </div>
          </template>
        </item-form>
      </template>
    </sign>
  </div>
</template>
<script>
import auth from "@/api/auth";

export default {
  components: {
    Sign: require("@/components/Sign").default,
    ItemForm: require("@/components/ItemForm").default,
  },
  data() {
    return {
      fields: [
        "business_name",
        // { name: "subscription_plan_id", value: 1 },
        "name",
        "email",
        "mobile_no",
        "password",
        "password_confirmation",
      ],
      loader: false,
    };
  },
  methods: {
    get() {
      return {};
    },
    async signup(id, data) {
      this.loader = true;
      return auth.registration(data).then((res) => {
        this.loader = false;
        localStorage.setItem("vfa-token", res.token);
        this.$router.push("/");
        return res;
      });
    },
  },
};
</script>
