var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.item)?_c('div',{staticClass:"card p-2 flex border-radius flex--nowrap mb-3 flex--middle",class:_vm.activeClass,attrs:{"title":_vm.item.product_type === 'variation' ? '' : 'Click to add'},on:{"click":function($event){_vm.item.product_type === 'variation' ? '' : _vm.select(_vm.item, $event)}}},[_c('div',{staticClass:"flex__fit mr-3 lightbox-container",attrs:{"title":_vm.image ? 'Click to open' : ''}},[(_vm.image)?_c('silent-box',{ref:'silentbox',staticClass:"silent-card",attrs:{"gallery":[
        {
          src: _vm.image,
          srcSet: _vm.image,
          thumbnailWidth: '90px',
          thumbnailHeight: '65px',
        } ]}}):_c('s-media',{staticClass:"radius-3 bg-grey-lightest",attrs:{"value":_vm.image,"ratio":"16x9","width":"90px","height":"65px"}}),(_vm.image)?_c('s-button',{staticClass:"p-1 btn-expand",attrs:{"size":"xs","theme":"muted","color":"primary","icon":"vmdi-arrow-expand-all"},nativeOn:{"click":function($event){return _vm.imageExpand({
          src: _vm.image,
          srcSet: _vm.image,
          thumbnailWidth: '90px',
          thumbnailHeight: '65px',
        })}}}):_vm._e()],1),_c('div',{},[_c('div',{staticClass:"text-grey text-sm text-capitalize text-bold"},[_vm._v(" "+_vm._s(_vm.item.name)+" "),(_vm.item.is_third_party)?_c('span',{staticClass:"text-xs text-normal"},[_vm._v("(Third-party Product)")]):_vm._e()]),(_vm.item.product_type === 'variation')?_c('div',{staticClass:"mt-1"},_vm._l((_vm.item.variants),function(v,index){return _c('s-button',{key:index,staticClass:"mr-1 mt-1 bt-border",class:{
          'button-border': _vm.item.id === _vm.value.id && v.id === _vm.value.variant_id,
        },attrs:{"color":"primary","size":"sm","theme":"muted","title":"Click to add"},nativeOn:{"click":function($event){return _vm.select(Object.assign({}, _vm.item, {price: v.price, variant_id: v.id}))}}},[_c('div',{staticClass:"text-normal"},[_vm._v(" "+_vm._s(v.name)+" ")]),_c('div',[_vm._v(" "+_vm._s("£" + v.price.toFixed(2))+" ")])])}),1):_c('div',{staticClass:"text-grey-darkest text-bold text-lg"},[_vm._v(" "+_vm._s("£" + _vm.item.price.toFixed(2))+" ")])])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }