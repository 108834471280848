import axios from "@/plugins/axios";

export const add = (data) => {
  return axios.post(`/v1/service`, data).then(res => {
    return res.data;
  })
}

export const get = (id, bId) => {
  return axios.get(`/v1/service/${id}?business_id=${bId}`).then(res => {
    return res.data;
  })
}

export const update = (data) => {
  return axios.put(`/v1/service/${data.id}`, data).then(res => {
    return res.data;
  })
}

export const archive = (id, bId) => {
  return axios.patch(`/v1/service/${id}/archive?business_id=${bId}`).then(res => {
    return res.data;
  })
}

export const unarchive = (id, bId) => {
  return axios.patch(`/v1/service/${id}/unarchive?business_id=${bId}`).then(res => {
    return res.data;
  })
}


export const serviceTypes = (id) => {
  return axios.get(`/v1/service/type`).then(res => {
    return res.data?.data.map(type => {
      return type
    })
  })
}