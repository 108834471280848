<template>
  <div>
    <item-form
      :item="serviceId"
      :fields="fields"
      :save="save"
      :get="get"
      :archive="archived"
      class="p-4"
      :footer="isDisabled"
      :form-state.sync="formState"
      :breadcrumb="false"
      :redirect="false"
      width="400px"
      ref="item"
      :notify="true"
    >
      <template #default="{form}">
        <div
          v-if="isItemArchived !== null"
          :key="isItemArchived"
          class="bg-warning-lightest p-4 mb-2 radius-3 mb-4"
        >
          <p class="text-warning-darkest">
            This item is archived. Unarchive it to make any changes.
          </p>
          <div class="mt-2 flex flex--nowrap flex--between flex--fit">
            <s-button
              color="warning"
              label="Unarchive"
              icon="ArchiveArrowUp"
              @click.native="unarchiveItem"
              :loader="archiving"
            />
          </div>
        </div>
        <s-field-validate label="Service Type" rules="required">
          <s-select
            placeholder="Select"
            v-model="form.type"
            :request="serviceTypes"
            :disabled="isItemArchived ? true : false"
            :class="categoryId === 7 ? 'media-disabled' : ''"
          />
          <!-- :options="serviceTypeOption" -->
        </s-field-validate>
        <!-- <s-field-validate
        v-if="form.type === 'third-party'"
        label="Compulsory Service"
        rules="required"
      >
        <s-select
          placeholder="Select"
          v-model="form.is_compulsory"
          :options="compulsoryOption"
          :disabled="isItemArchived ? true : false"
        />
      </s-field-validate> -->
        <s-field-validate
          v-if="form.type === 'third-party'"
          label="Compulsory Service"
          rules="required"
        >
          <s-radio
            :options="compulsoryOption"
            v-model="form.is_compulsory"
            :class="isItemArchived ? 'media-disabled' : ''"
          />
        </s-field-validate>
        <s-field-validate
          label="Funeral Type"
          rules="required"
          v-if="categoryId !== 7"
        >
          <s-radio
            :options="funnelTypeOption"
            v-model="form.funeral_type"
            :class="isItemArchived ? 'media-disabled' : ''"
          />
        </s-field-validate>
        <s-field-validate
          rules="required"
          label="Description"
          v-if="form.type === 'funeral-options'"
        >
          <s-textarea
            rows="4"
            v-model="form.desc"
            :disabled="isItemArchived ? true : false"
            spellcheck="true"
          />
        </s-field-validate>
        <s-field-validate label="Title" v-else rules="required">
          <s-textbox
            v-model="form.name"
            type="text"
            :disabled="isItemArchived ? true : false"
            spellcheck="true"
          />
        </s-field-validate>
        <s-field-validate label="Price" rules="required">
          <s-textbox
            v-model="form.price"
            type="number"
            :disabled="isItemArchived ? true : false"
          />
        </s-field-validate>
        <s-field-validate label="Add Photo">
          <div>
            <s-media
              :readonly="false"
              ratio="16x9"
              width="160px"
              :value="form.image ? form.image : serviceImage"
              @input="openCropper('serviceImage', $event, 16 / 9)"
              @remove="remove()"
              accept=".jpg,.jpeg,.png"
              value-type="base64"
              :max-file-size="5 * 1024 * 1024"
              :changeable="false"
              :class="isItemArchived ? 'radius-3 media-disabled' : 'radius-3'"
            />
          </div>
        </s-field-validate>
      </template>
    </item-form>
    <cropper ref="cropper" @input="onCrop($event)" />
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import user from "@/api/user";
import {
  add,
  get,
  update,
  archive,
  unarchive,
  serviceTypes,
} from "@/api/service";
export default {
  props: {
    serviceId: [String, Number],
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    Cropper: require("@/components/Cropper").default,
  },
  data() {
    return {
      serviceTypes,
      fields: [
        "type",
        "name",
        "price",
        "desc",
        "funeral_type",
        "is_compulsory",
        "image",
      ],
      isItemArchived: null,
      archiving: false,
      isDisabled: true,
      serviceImage: null,
      formState: null,
      mediaId: null,
      categoryId: null,
    };
  },
  computed: {
    ...mapGetters(["business"]),
    compulsoryOption() {
      return [
        {
          label: "Yes",
          value: 1,
        },
        {
          label: "No",
          value: 0,
        },
      ];
    },
    funnelTypeOption() {
      return [
        {
          label: "Burial",
          value: "Burial",
        },
        {
          label: "Cremation",
          value: "Cremation",
        },
      ];
    },
    serviceTypeOption() {
      return [
        { label: "Funeral Options", value: "funeral-options" },
        { label: "Third Party", value: "third-party" },
      ];
    },
  },
  methods: {
    get() {
      if (!this.business?.id) {
        return;
      }
      return get(this.serviceId, this.business?.id).then((res) => {
        this.mediaId = res.media_id;
        if (res.deleted_at) {
          this.isItemArchived = res.deleted_at;
          this.isDisabled = false;
        } else {
          this.isItemArchived = null;
        }
        this.categoryId = res?.category?.id;
        return { ...res, is_compulsory: res?.is_compulsory ? 1 : 0 };
      });
    },
    onCrop({ id, value }) {
      this[id] = value;
    },
    openCropper(id, img, ratio) {
      if (!img) return;
      this.$refs.cropper.open(id, img, {
        force: true,
        aspectRatio: ratio || 1,
      });
    },
    async save(id, data) {
      data.image = this.serviceImage;
      data.business_id = this.business?.id;
      if (this.categoryId === 7) {
        data.category_id = this.categoryId;
      }
      if (Number(this.serviceId)) {
        data.id = this.serviceId;
        return update(data).then((res) => {
          this.$router.push({ name: "services" });
          return res;
        });
      } else {
        return add(data).then((res) => {
          this.$router.push({ name: "services" });
          return res;
        });
      }
    },
    archived() {
      return archive(this.serviceId, this.business?.id)
        .then((res) => {
          this.$router.push({ name: "services" });
        })
        .catch((err) => {
          console.log("ERR ", err);
        });
    },
    unarchiveItem() {
      this.archiving = true;
      return unarchive(this.serviceId, this.business?.id).then((res) => {
        this.$notify({
          title: res.message,
          type: "success",
          text: this.error,
        });
        this.archiving = false;
        this.isDisabled = true;
        this.get();
      });
    },
    remove() {
      let data = this.mediaId;
      this.formState.image = null;
      this.serviceImage = null;
      return user
        .mediaRemove(data)
        .then((res) => {
          this.$notify({
            title: res.message,
            type: "success",
            text: this.error,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
  },
};
</script>
