<template>
  <item-form
    :item="true"
    :fields="fields"
    :save="save"
    :get="get"
    :form-state.sync="formState"
    class="service-form p-4"
    :footer="true"
    :breadcrumb="false"
    :redirect="false"
    ref="item"
    :notify="true"
    :gap="3"
  >
    <template #default="{form}">
      <div>
        <s-row :gap="2" class="pb-2">
          <s-column :size="5" class="text-bold text-grey">Name</s-column>
          <s-column :size="2" class="text-bold text-grey">Tailored</s-column>
          <s-column :size="2" class="text-bold text-grey">Simple</s-column>
          <s-column :size="2" class="text-bold text-grey">Direct</s-column>
          <s-column :size="1" class="text-bold text-grey">Action</s-column>
        </s-row>
        <s-row :gap="2" class="mb-3">
          <s-column :size="5" class="text-bold text-grey"></s-column>
          <s-column :size="2" class="text-bold text-grey">
            <s-field-validate
              size="sm"
              rules="required"
              name="Tailored Funeral Price"
              before-icon="vmdi-currency-gbp"
            >
              <s-textbox
                type="number"
                placeholder="Tailored Funeral Price"
                v-model="form.tailored_funeral_price"
              />
            </s-field-validate>
          </s-column>
          <s-column :size="2" class="text-bold text-grey">
            <s-field-validate
              size="sm"
              rules="required"
              name="Simple Funeral Price"
              before-icon="vmdi-currency-gbp"
            >
              <s-textbox
                type="number"
                placeholder="Simple Funeral Price"
                v-model="form.simple_funeral_price"
              />
            </s-field-validate>
          </s-column>
          <s-column :size="2" class="text-bold text-grey">
            <s-field-validate
              size="sm"
              rules="required"
              name="Direct Cremation Price"
              before-icon="vmdi-currency-gbp"
            >
              <s-textbox
                type="number"
                placeholder="Direct Cremation Price"
                v-model="form.direct_cremation_price"
              />
            </s-field-validate>
          </s-column>
        </s-row>
        <div v-for="(service, index) in form.services" :key="index">
          <s-row :gap="2" class="mb-3" v-if="service.action !== 'delete'">
            <s-column :size="5">
              <s-field-validate
                name="Name"
                rules="required"
                :vid="form.services[index]['name']"
              >
                <s-textbox type="text" v-model="form.services[index]['name']" />
              </s-field-validate>
            </s-column>
            <s-column :size="2">
              <s-field-validate
                name="Tailored"
                rules="required"
                :vid="form.services[index]['tailored']"
              >
                <s-textbox
                  type="text"
                  v-model="form.services[index]['tailored']"
                />
              </s-field-validate>
            </s-column>
            <s-column :size="2">
              <s-field-validate
                name="Simple"
                rules="required"
                :vid="form.services[index]['simple']"
              >
                <s-textbox
                  type="text"
                  v-model="form.services[index]['simple']"
                />
              </s-field-validate>
            </s-column>
            <s-column :size="2">
              <s-field-validate
                name="Direct"
                rules="required"
                :vid="form.services[index]['direct']"
              >
                <s-textbox
                  type="text"
                  v-model="form.services[index]['direct']"
                />
              </s-field-validate>
            </s-column>
            <s-column :size="1">
              <s-button
                shape="circle"
                icon="Delete"
                theme="muted"
                color="danger"
                @click.native="form.services[index]['action'] = 'delete'"
              />
            </s-column>
          </s-row>
        </div>
        <s-row class="mb-4">
          <s-column :size="1">
            <s-button
              fluid
              icon="vmdi-plus"
              align="right"
              color="primary"
              theme="muted"
              label="Add New"
              size="sm"
              @click.native="addField"
            />
          </s-column>
        </s-row>
      </div>
    </template>
    <template #footer="{saveItem,saving}" :class="['']">
      <s-row>
        <s-column :size="11" class="flex flex--fit flex--right">
          <s-button
            color="primary"
            label="Save Service Comparison"
            @click.native="saveItem"
            :loader="saving"
          />
        </s-column>
      </s-row>
    </template>
  </item-form>
</template>
<script>
import { get, add } from "@/api/serviceComparison";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      fields: [
        "direct_cremation_price",
        "simple_funeral_price",
        "tailored_funeral_price",
        { name: "services", value: [] },
      ],
      formState: null,
    };
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
  },
  computed: {
    ...mapGetters(["business"]),
  },
  methods: {
    get() {
      if (this.business?.id) {
        return get(this.business.id).then((res) => {
          let services = res.data.map((s) => {
            return {
              ...s,
              action: "update",
            };
          });
          return {
            direct_cremation_price: this.business?.direct_cremation_price,
            simple_funeral_price: this.business?.simple_funeral_price,
            tailored_funeral_price: this.business?.tailored_funeral_price,
            services: [...(services || [])],
          };
        });
      }
    },
    save(id, data) {
      let finalData = {
        ...data,
        services: data.services.filter(
          (s) => s.name && s.direct && s.simple && s.tailored
        ),
        // services: this.services,
        busienss_id: this.business.id,
      };
      return add(finalData).then((res) => {
        this.$root.$emit("rehydrate");
        return res;
      });
    },
    addField() {
      this.formState.services = [
        ...this.formState.services,
        { name: "", direct: "", simple: "", tailored: "", action: "insert" },
      ];
    },
  },
};
</script>
<style lang="scss">
@media (max-width: 780px) {
  .service-form {
    overflow: auto;
    > form {
      width: 700px;
    }
  }
}
</style>
