export const toBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
};

// export const urlToBase64 = (url) => {
//   return new Promise(async (resolve, reject) => {
//     const res = await fetch(url)
//       .then((res) => res.blob())
//       .catch((err) => {
//         console.error(err);
//       });
//     const reader = new FileReader();
//     reader.onloadend = () => resolve(reader.result);
//     reader.onerror = reject;
//     reader.readAsDataURL(res);
//   });
// };
