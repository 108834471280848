export const HYDRATING = "⏳ Hydrating";
export const WAPI_LOADED = "✨ WAPI Loaded";
export const WEB_WHATSAPP_INFO = "ℹ️ Web WhatsApp Info";
export const HYDRATED = "⌛️ Hydrated";
export const HYDRATION_FAILED = "🔴 Hydration Failed";
export const REHYDRATE = "💦 Rehydrate";
export const SET_ACCOUNT = "😎 Set Account";
export const SET_PLATFORM_USER = "😎 Set Platform User";
export const SET_USER = "😎 Set User";
export const SET_BUSINESS = "😎 Set Business";
export const SET_PROFILE = "🧑‍💻 Setting Contact Profile";
export const SET_PROFILE_PIC = "🧑‍💻 Setting Contact Profile Picture";
export const SET_PREF = "🛠 Setting Preference";
export const SET_FUNERAL_ID = "Setting Funeral Id";
export const SET_FUNERAL_TYPE = "Set Funeral Type";

