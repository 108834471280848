var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('sp-list',{ref:"list",staticClass:"h-100",attrs:{"attrs":_vm.columns,"endpoint":"/v1/admin/user?limit=-1","actions":['settings', 'refresh'],"per-page":10}},[_c('sp-list-table',{on:{"rowClick":function($event){return _vm.detailsPage($event.id)}},scopedSlots:_vm._u([{key:"business_name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"profile-box"},[_c('div',{staticClass:"profile-box__image"},[(item.businesses[0].logo)?_c('img',{attrs:{"src":item.businesses[0].logo,"alt":""}}):_c('img',{attrs:{"src":"img/default-logo.png"}})]),_c('div',{staticClass:"profile-box__title"},[_vm._v(" "+_vm._s(item.businesses[0].name)+" ")])])]}},{key:"mobile_no",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.businesses[0].mobile_no)+" ")]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.businesses[0].email)+" ")]}},{key:"funerals_count",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.businesses[0].funeral_count)+" ")]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formateDate(item.businesses[0].created_at))+" ")]}},{key:"subscription_status",fn:function(ref){
var item = ref.item;
return [_c('s-button',{staticClass:"text-xs text-bold h-4 py-3",class:_vm.background[item.subscription_status],attrs:{"shape":"pill","label":item.subscription_status}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }