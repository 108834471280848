var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100"},[(!_vm.isDashboard)?_c('portal',{attrs:{"to":"header"}},[_c('s-button',{attrs:{"size":"sm","color":"primary","theme":"primary","label":"Add New","icon":"vmdi-plus","to":{ name: 'funeral-first', params: { funeralId: '+' } }}})],1):_vm._e(),_c('sp-list',{ref:"list",staticClass:"funeral-list h-100",attrs:{"endpoint":"/v1/funeral","params":_vm.params,"attrs":_vm.columns,"actions":_vm.actions,"per-page":10,"footer":!_vm.isDashboard}},[_c('sp-list-table',{on:{"rowClick":function($event){return _vm.detailsPage($event.uuid)}},scopedSlots:_vm._u([{key:"deceased",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"deceased-details"},[_c('s-media',{staticClass:"radius-circle bg-grey-light mr-3",attrs:{"ratio":"","height":"40px","width":"40px","value":(item.notice && item.notice.image) || 'img/avatar.svg'}}),_c('div',{staticClass:"name-container"},[_c('div',{staticClass:"text-grey-darkest text-bold mb-1"},[_vm._v(" "+_vm._s(item.first_name + " " + item.last_name)+" ")]),_c('div',{staticClass:"text-xs text-grey-light"},[(item.ref_no_display)?_c('span',[_vm._v(_vm._s("#" + item.ref_no_display))]):_vm._e()])])],1)]}},{key:"representative",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"rep-container"},[_c('div',{staticClass:"text-grey-darkest text-bold mb-1"},[_vm._v(" "+_vm._s(item.contact_name)+" ")]),_c('div',{staticClass:"text-xs text-grey-light"},[_vm._v(" "+_vm._s(item.contact_email)+" "),(item.contact_mobile_no)?_c('span',[_vm._v(" /")]):_vm._e(),_vm._v(" "+_vm._s(item.contact_mobile_no)+" ")])])]}},{key:"toPay",fn:function(ref){
var item = ref.item;
return [_c('s-button',{staticClass:"text-xs text-normal h-4 py-3",class:_vm.background[item.payment_status],attrs:{"shape":"pill","label":item.payment_status}})]}},{key:"funeralStatus",fn:function(ref){
var item = ref.item;
return [_c('s-button',{staticClass:"text-xs text-normal h-4 py-3",class:_vm.background[item.status],attrs:{"shape":"pill","label":item.status}})]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"flex flex--fit flex--nowrap"},[_c('s-button',{staticClass:"bg-primary-lightest",attrs:{"icon":"vmdi-square-edit-outline","color":"text-primary","shape":"circle"},nativeOn:{"click":function($event){return _vm.detailsPage(item.uuid)}}}),_c('s-button',{staticClass:"bg-primary-lightest ml-2",attrs:{"icon":"vmdi-content-copy","color":"text-primary","shape":"circle","title":"Copy Link"},nativeOn:{"click":function($event){return _vm.copy(item.uuid)}}}),_c('s-button',{staticClass:"ml-2 p-0",attrs:{"icon":"vmdi-dots-vertical","color":"text-primary","theme":"link"},nativeOn:{"click":function($event){return _vm.changeStatus(item)}}})],1)]}}])})],1),_c('status-change-modal',{attrs:{"item":_vm.item},on:{"refresh":function($event){return _vm.$refs.list.refresh()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }