<template>
  <dropdown-datepicker
    wrapperClass="select field-block dropdown-date-time"
    :allowFuture="allowFuture"
    :allowPast="allowPast"
    :default-date="value"
    v-bind:on-change="onChange"
    :minYear="1920"
  />
</template>

<script>
import DropdownDatepicker from "vue-dropdown-datepicker";
import { getDay, getMonth, getYear } from "date-fns";

export default {
  props: {
    value: [String],
    allowFuture: {
      type: Boolean,
      default: false
    },
    allowPast: {
      type: Boolean,
      default: true
    }
  },
  components: {
    DropdownDatepicker
  },
  mounted() {
    if (this.value) {
      const year = getYear(new Date(this.value));
      const month = getMonth(new Date(this.value));
      const day = getDay(new Date(this.value));
      this.onChange(day, month, year);
    }
  },
  methods: {
    onChange(day, month, year) {
      console.log(day, month, year);
      if (day != "" && month != "" && year != "") {
        this.$emit("input", `${year}-${month}-${day}`);
      }
    }
  }
};
</script>

<style lang="scss">
.dropdown-date-time {
  display: flex;
  select {
    appearance: auto !important;
  }
  select + select {
    border-left: 4px solid #fff;
    padding-right: 17px !important;
  }
  &:after {
    background: none !important;
  }
}
</style>
