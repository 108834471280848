<template>
  <!-- <request class="h-100" :get="get" #default="{res}" ref="formDetails"> -->
  <s-layout class="notice-box" full-height :left-classlist="['br-lightest']" left-size="305px">
    <template #left>
      <div class="p-3">
        <s-media
          class="bg-grey-lightest radius-4"
          ratio="1x1"
          width="auto"
          height="260px"
          :value="media"
        />
      </div>
      <sub-nav class="bt-lightest p-3">
        <s-nav-item
          class="is-root"
          :to="{ name: 'deceased-details' }"
          label="Details of the Deceased"
        />
        <s-nav-item
          :to="{ name: 'family-references' }"
          label="Funeral Notice Family References"
          :disabled="isAcive"
        />
        <s-nav-item
          :to="{ name: 'photograph' }"
          label="Photograph"
          :disabled="isAcive"
        />
        <s-nav-item
          :to="{ name: 'publish-options' }"
          label="Funeral Notice Publishing Options"
          :disabled="isAcive"
        />
        <s-nav-item
          :to="{ name: 'preview' }"
          label="Funeral Notice Preview"
          :disabled="isAcive"
        />
      </sub-nav>
    </template>
    <template #default>
      <router-view :funeral-details="funeralDetails" @refresh="refresh" />
    </template>
  </s-layout>
  <!-- </request> -->
</template>
<script>
import checkFuneral from "@/views/new-request/common/checkFuneral";
export default {
  mixins: [checkFuneral],
  props: {
    funeralDetails: [Object],
  },
  components: {
    SubNav: require("@/components/SubNav").default,
  },
  computed: {
    isAcive() {
      if (this.funeralDetails?.is_notice_required) {
        return false;
      }
      return true;
    },
    media() {
      return this.funeralDetails?.notice?.image;
    },
  },
  mounted() {
    this.routeAllowed();
  },
  methods: {
    refresh() {
      this.$emit("refresh");
    },
  },
};
</script>
<style lang="scss">
@media (max-width:992px){
  .notice-box{
    display: block;
    .layout__left{
      overflow-x:hidden !important;
      .nav{
        overflow: auto;
      }
    }
  }
}
</style>