<template>
  <modal
    size="sm"
    id="company-profile-modal"
    title="Company Profile"
    :closeOnEsc="false"
    :closeOnOverlay="false"
    :close="false"
  >
    <company-profile :modal="true" />
  </modal>
</template>
<script>
export default {
  components: {
    Modal: require("@/components/Modal").default,
    CompanyProfile: require("./Profile").default,
  },
  props: {},
  data() {
    return {};
  },
  computed: {},
};
</script>
