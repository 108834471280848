<template>
  <sp-list
    class="h-100"
    :attrs="columns"
    endpoint="/v1/admin/user?limit=-1"
    :actions="['settings', 'refresh']"
    :per-page="10"
    ref="list"
  >
    <sp-list-table @rowClick="detailsPage($event.id)">
      <template #business_name="{item}">
        <div class="profile-box">
          <div class="profile-box__image">
            <img
              v-if="item.businesses[0].logo"
              :src="item.businesses[0].logo"
              alt=""
            />
            <img v-else src="img/default-logo.png" />
          </div>
          <div class="profile-box__title">
            {{ item.businesses[0].name }}
          </div>
        </div>
      </template>
      <template #mobile_no="{item}">
        {{ item.businesses[0].mobile_no }}
      </template>
      <template #email="{item}">
        {{ item.businesses[0].email }}
      </template>

      <template #funerals_count="{item}">
        {{ item.businesses[0].funeral_count }}
      </template>
      <template #created_at="{item}">
        {{ formateDate(item.businesses[0].created_at) }}
      </template>
      <template #subscription_status="{item}">
        <s-button
          :class="background[item.subscription_status]"
          class="text-xs text-bold h-4 py-3"
          shape="pill"
          :label="item.subscription_status"
        />
      </template>
    </sp-list-table>
  </sp-list>
</template>
<script>
import { format } from "date-fns";
export default {
  data() {
    return {
      background: {
        Active: "bg-success-lightest text-success",
        Cancelled: "bg-danger-lightest text-danger",
        Inactive: "bg-danger-lightest text-danger",
      },
    };
  },
  computed: {
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "business_name", label: "Business Name",width:250,fix:250 },
        { name: "name", label: "Contact Person" },
        { name: "mobile_no", label: "Phone" },
        { name: "email", label: "Email" },
        { name: "funerals_count", label: "Funerals Count" },
        { name: "created_at", label: "Created At" },
        { name: "subscription_status", label: "Subscription Status" },
      ];
    },
  },
  methods: {
    formateDate(val) {
      return format(val * 1000, "dd/MM/yyyy ");
    },
    detailsPage(id) {
      this.$router.push({
        name: "funeral-director-view",
        params: { id: id },
      });
    },
  },
};
</script>
<style lang="scss">
.profile-box {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width:200px;
  &__image {
    flex: 0 0 50px;
    height: 50px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 5px;
    img {
      width: 100%;
      height: 50px;
      object-fit: contain;
      object-position: center;
      border-radius: 5px;
    }
  }
  &__title{
    flex:0 0 150px;
    padding-right:10px;
    white-space: normal;
  }
}
</style>
