<template>
  <div class="h-100">
    <portal to="header" v-if="!isDashboard">
      <s-button
        class=""
        size="sm"
        color="primary"
        theme="primary"
        label="Add New"
        icon="vmdi-plus"
        :to="{ name: 'funeral-first', params: { funeralId: '+' } }"
      />
    </portal>
    <sp-list
      class="funeral-list h-100"
      endpoint="/v1/funeral"
      :params="params"
      :attrs="columns"
      :actions="actions"
      :per-page="10"
      :footer="!isDashboard"
      ref="list"
    >
      <sp-list-table @rowClick="detailsPage($event.uuid)">
        <template #deceased="{ item }">
          <!-- <div class="flex flex--fit flex--middle flex--nowrap">
            <s-media
              class="radius-circle bg-grey-light mr-3"
              ratio=""
              height="40px"
              width="40px"
              :value="(item.notice && item.notice.image) || 'img/avatar.svg'"
            />
            <div class="flex__fit">
              <div class="text-grey-darkest text-bold mb-1">
                {{ item.first_name + " " + item.last_name }}
              </div>
              <div class="text-xs text-grey-light">
                <span v-if="item.ref_no_display">{{
                  "#" + item.ref_no_display
                }}</span>
              </div>
            </div>
          </div> -->
          <div class="deceased-details">
            <s-media
              class="radius-circle bg-grey-light mr-3"
              ratio=""
              height="40px"
              width="40px"
              :value="(item.notice && item.notice.image) || 'img/avatar.svg'"
            />
            <div class="name-container">
              <div class="text-grey-darkest text-bold mb-1">
                {{ item.first_name + " " + item.last_name }}
              </div>
              <div class="text-xs text-grey-light">
                <span v-if="item.ref_no_display">{{
                  "#" + item.ref_no_display
                }}</span>
              </div>
            </div>
          </div>
        </template>
        <template #representative="{ item }">
          <div class="rep-container">
            <div class="text-grey-darkest text-bold mb-1">
              {{ item.contact_name }}
            </div>
            <div class="text-xs text-grey-light">
              {{ item.contact_email }}
              <span v-if="item.contact_mobile_no"> /</span>
              {{ item.contact_mobile_no }}
            </div>
          </div>
        </template>
        <template #toPay="{ item }">
          <s-button
            :class="background[item.payment_status]"
            class="text-xs text-normal h-4 py-3"
            shape="pill"
            :label="item.payment_status"
          />
        </template>
        <template #funeralStatus="{ item }">
          <s-button
            :class="background[item.status]"
            class="text-xs text-normal h-4 py-3"
            shape="pill"
            :label="item.status"
          />
        </template>
        <template #action="{ item }">
          <div class="flex flex--fit flex--nowrap">
            <s-button
              icon="vmdi-square-edit-outline"
              color="text-primary"
              class="bg-primary-lightest"
              shape="circle"
              @click.native="detailsPage(item.uuid)"
            />
            <s-button
              icon="vmdi-content-copy"
              color="text-primary"
              class="bg-primary-lightest ml-2"
              shape="circle"
              title="Copy Link"
              @click.native="copy(item.uuid)"
            />
            <s-button
              class="ml-2 p-0"
              icon="vmdi-dots-vertical"
              color="text-primary"
              theme="link"
              @click.native="changeStatus(item)"
            />
          </div>
        </template>
      </sp-list-table>
    </sp-list>
    <status-change-modal :item="item" @refresh="$refs.list.refresh()" />
  </div>
</template>
<script>
import { format } from "date-fns";

export default {
  props: {
    business: Object,
    isDashboard: {
      type: Boolean,
      default: false
    }
  },
  components: {
    StatusChangeModal: require("../dashboard/StatusChangeModal").default
  },
  data() {
    return {
      background: {
        Paid: "bg-success-lightest text-success",
        Completed: "bg-success-lightest text-success",
        Unpaid: "bg-warning-lightest text-warning",
        Open: "bg-warning-lightest text-warning",
        Refund: "bg-danger-lightest text-danger",
        Cancelled: "bg-danger-lightest text-danger",
        Pending: "bg-warning-lightest text-warning"
      },
      item: null
    };
  },
  computed: {
    actions() {
      if (this.isDashboard) {
        return [""];
      }
      return ["settings", "refresh"];
    },
    columns() {
      return [
        { name: "_index", label: "#", fix: true },
        { name: "deceased", fix: 100 },
        { name: "representative" },
        {
          name: "created_at",
          label: "Date of form submission",
          value: item => {
            if (item.created_at) {
              let dateChange = item.created_at * 1000;
              let newDate = new Date(dateChange);
              let d = "0" + newDate.getDate();
              let m = "0" + (newDate.getMonth() + 1);
              let y = newDate.getFullYear();
              let date = d.substr(-2) + "/" + m.substr(-2) + "/" + y;
              return date;
            }
            return "";
          }
        },
        {
          name: "dod",
          label: "Date of Death",
          value: item => {
            if (item.dod) {
              return format(new Date(item.dod), "dd/mm/yyyy");
            }
            return "";
          }
        },
        { name: "toPay" },
        { name: "funeralStatus", label: "Status" },
        { name: "action", label: "", rowClick: false }
      ];
    },
    params() {
      return {
        business_id: this.business?.id
      };
    }
  },
  methods: {
    changeStatus(id) {
      this.item = id;
      if (this.item) {
        this.$root.$emit("shilp-modal-open", "status-change-modal");
      }
    },
    detailsPage(id) {
      this.$router.push({
        name: "funeral-starting",
        params: { funeralId: id }
      });
    },
    copy(uuid) {
      const link =
        window.location.origin +
        "/app/#/" +
        this.business?.url_slug +
        "/" +
        uuid;
      this.$shilp.copy(link);
      this.$notify({
        type: "success",
        title: "Copied!",
        duration: 1000
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.deceased-details {
  display: flex;
  align-items: center;
  .name-container {
    max-width: 250px;
  }
}
.rep-container {
  max-width: 250px;
}
</style>
