import Vue from "vue";
import VueRouter from "vue-router";
import privateRoutes from "./private";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "/",
  },
  {
    path: "/",
    component: require("@/views/public/Private").default,
    meta: {
      public: false,
    },
    children: privateRoutes
  },
  {
    path: "/login",
    name: "login",
    component: require("@/views/public/Login").default,
    meta: {
      public: true,
    },
  },
  {
    path: "/forget-password",
    name: "forgetPassword",
    component: require("@/views/public/ForgetPassword").default,
    meta: {
      public: true,
    },
  },
  {
    path: "/signin",
    name: "signin",
    component: require("@/views/public/Signup").default,
    meta: {
      public: true,
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: require("@/views/public/Logout").default,
    meta: {
      public: true,
    },
  },
  {
    path: "/help",
    name: "help",
    component: require("@/views/public/Logout").default,
    meta: {
      public: true,
    },
  }
];

const router = new VueRouter({
  routes,
  mode: "hash"
});

router.beforeEach(async (from, to, next) => {
  if (from.meta.public === true) {
    next();
  } else {
    const token = localStorage.getItem("vfa-token");
    if (token) {
      next();
    } else {
      next({ name: "logout" });
    }
  }
});

export default router;
