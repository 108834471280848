import services from './services';
import products from './products';
import company from './company';
import funeralNoticeTemplate from './funeralNoticeTemplate';
import funeralNewRequest from "./funeralNewRequest";
import funeralDirectors from "./funeralDirectors";
import funeralDirectorsView from "./funeralDirectorsView";

export default [
  {
    path: "",
    redirect: "dashboard",
  },
  {
    path: "dashboard",
    name: "dashboard",
    component: require("@/views/dashboard/Index").default,
    meta: {
      label: "Dashboard",
      breadcrumbs: ["dashboard"],
      icon: "vmdi-view-dashboard",
    },
  },

  {
    path: "funerals",
    name: "funerals",
    component: require("@/views/completed/Index").default,
    redirect: { name: "funeral-list" },
    props: true,
    children: [
      {
        path: "",
        name: "funeral-list",
        component: require("@/views/completed/List").default,
        props: true,
        meta: {
          label: "Funerals",
          breadcrumbs: ["dashboard", "funeral-list"],
        },
      },
      {
        path: "welcome",
        name: "funeral-first",
        component: require("@/views/new-request/LandingPage").default,
        props: true,
        meta: {
          label: "New Request",
          breadcrumbs: ["dashboard", "funeral-list", "funeral-first"],
        },
      },
      funeralNewRequest
    ]
  },


  products,
  services,
  company,
  funeralNoticeTemplate,
  funeralDirectors,
  funeralDirectorsView,
  {
    path: "change-password",
    name: "change-password",
    component: require("@/views/profile/ChangePassword").default,
    meta: {
      label: "Change Password",
      breadcrumbs: ["dashboard", "change-password"],
    },
  },
  // {
  //   path: "subscription",
  //   name: "subscription",
  //   component: require("@/views/profile/Subscription").default,
  //   meta: {
  //     label: "Subscription",
  //     breadcrumbs: ["dashboard", "subscription"],
  //   },
  // },
  {
    path: "edit-profile",
    name: "edit-profile",
    component: require("@/views/profile/Edit").default,
    meta: {
      label: "Edit Profile",
      breadcrumbs: ["dashboard", "edit-profile"],
    },
  },
]