<template>
  <div>
    <!-- <request class="h-100" :get="get" #default="{res}" ref="companyDetails"> -->
    <company-profile v-if="colorExsist" :business="business" />
    <div class="main-space">
      <div class="px-5 pt-5 main-space--reduce">
        <div class="pb-3 bb-lightest mb-3">
          <p class="text-xl text-primary-light request-title">
            Funeral Request Link
          </p>
        </div>
        <div class="copy-link flex flex--start flex--fit flex--between mb-5">
          <div class="">
            <div class="text-grey-light mb-2">Click to Copy Link :</div>
            <div class="link-text text-lg text-primary-darkest text-bold">
              <p>{{ businessSlug }}</p>
              <s-button
                :href="businessSlug"
                target="_blank"
                icon="vmdi-open-in-new"
                color="text-primary"
                class="bg-primary-lightest"
                shape="circle"
                title="Open Link in New Tab"
              />
            </div>
          </div>
          <s-button
            icon="vmdi-content-copy"
            color="text-primary"
            class="bg-primary-lightest copy-button"
            shape="circle"
            title="Copy Link"
            @click.native="copy"
          />
        </div>
      </div>
      <funeral-list :business="business" :is-dashboard="true" />
    </div>
    <!-- </request> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  components: {
    CompanyProfile: require("@/components/CompanyProfile").default,
    FuneralList: require("@/views/completed/List").default
  },
  computed: {
    ...mapGetters(["business"]),
    colorExsist() {
      return this.business?.brand_color || this.business?.text_color;
    },
    businessSlug() {
      return (
        window.location.origin + "/app/#/" + (this.business?.url_slug || "")
      );
    }
  },
  methods: {
    copy() {
      this.$shilp.copy(this.businessSlug);
      this.$notify({
        type: "success",
        title: "Copied!",
        duration: 1000
      });
    }
  }
};
</script>
<style lang="scss">
@media (max-width: 1024px) {
  .main-space .main-space--reduce {
    padding: 20px !important;
  }
}
</style>