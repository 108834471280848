
import axios from "@/plugins/axios";

export const serviceType = async (data) => {
  return axios.get(`/v1/service?limit=-1&filter[type]=funeral-options&filter[funeral_type]=${data.type}&business_id=${data.business_id}`).then((res) => {
    return res.data;
  })
};

export const productSave = (data, code) => {
  return axios.put(`/v1/funeral/${data.id}/product?verify=${code}`, data).then(res => {
    return res.data
  })
}

export const getProducts = (id, code) => {
  return axios.get(`/v1/funeral/${id}/product?verify=${code}`).then(res => {
    return res.data
  })
}

export const getProductList = async (id, urns) => {
  // funeral notice,
  return axios.get(`/v1/category?filter[category_not]=${urns}funeral notice&limit=-1&include=products&business_id=${id}`).then(res => {
    return res.data
  })
}

export const getServices = async (data) => {
  return axios.get(`/v1/service?filter[type]=third-party&filter[funeral_type]=${data.type}&limit=-1&business_id=${data.id}`).then(res => {
    return res.data
  })
}

export const routeOptions = async () => {
  return axios.get(`/v1/travel-arrangement`).then((res) => {
    return res.data.data
  })
};

export const getFuneralNotice = async (data) => {
  return axios.get(`/v1/service?page=1&business_id=${data.business_id}&filter[type]=third-party&limit=-1&filter[category.name]=funeral notice`).then((res) => {
    return res.data
  })
};