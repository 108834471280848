import Vue from "vue";

/**
 * Vee Validate
 */
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import { required, email, min, max } from "vee-validate/dist/rules";
import en from "vee-validate/dist/locale/en";

const rules = { required, email, min, max };

for (let rule in rules) {
  extend(rule, {
    ...rules[rule], // add the rule
    message: en.messages[rule], // add its message
  });
}

extend("url", {
  validate: (value) => {
    const regex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,63}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
    return value.match(new RegExp(regex));
  },
});

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target;
  },
  message: 'Password confirmation does not match'
});

extend("domain", {
  validate: (value) => {
    if (
      value.startsWith("http:") ||
      value.startsWith("https:") ||
      // value.startsWith("www.") ||
      value.startsWith(".")
    ) {
      return "Domain should not start with 'http', 'www' or '.'";
    }

    if (value.includes("/")) {
      return "Domain should not contain any '/'";
    }

    if (value.endsWith(".")) {
      return "Domain should not end with '.'";
    }

    if (value.indexOf(" ") >= 0) {
      return "Domain should not contain any space.";
    }

    if (value.indexOf(".") < 0) {
      return "Domain must contain a single '.'";
    }

    return true;
  },
});

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
