<template>
  <item-form
    :item="true"
    :fields="fields"
    :save="save"
    :get="get"
    class="p-4"
    :footer="true"
    :breadcrumb="false"
    :redirect="false"
    ref="item"
    :notify="true"
    :gap="3"
    :width="!modal ? '400px' : ''"
  >
    <template #default="{form}">
      <s-field-validate label="Business Name" rules="required">
        <s-textbox v-model="form.name" type="text" />
      </s-field-validate>
      <s-field-validate label="Address">
        <s-textarea rows="4" v-model="form.address" spellcheck="true" />
      </s-field-validate>
      <s-field-validate label="Domain or Website URL" rules="required|domain">
        <s-textbox v-model="form.website" type="text" />
      </s-field-validate>
      <s-field-validate label="Contact" rules="required">
        <s-textbox v-model="form.mobile_no" type="number" />
      </s-field-validate>
      <s-field-validate label="Email" rules="required|email">
        <s-textbox v-model="form.email" type="email" />
      </s-field-validate>
      <div class="grid grid--2 g-2">
        <s-field-validate label="Brand Color" rules="required">
          <s-textbox v-model="form.brand_color" type="color" class="w-7" />
          <s-textbox type="text" v-model="form.brand_color" />
        </s-field-validate>
        <s-field-validate label="Text Color" rules="required">
          <s-textbox v-model="form.text_color" type="color" class="w-7" />
          <s-textbox type="text" v-model="form.text_color" />
        </s-field-validate>
      </div>
    </template>
  </item-form>
</template>
<script>
import company from "@/api/company";
import { mapGetters } from "vuex";

export default {
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
  },
  data() {
    return {
      fields: [
        "name",
        "website",
        "address",
        "mobile_no",
        "email",
        { name: "brand_color", value: "#000000" },
        { name: "text_color", value: "#ffffff" },
      ],
      loader: false,
    };
  },
  computed: {
    ...mapGetters(["business"]),
  },
  methods: {
    get() {
      let data = { ...this.business };
      if (!this.business?.brand_color) {
        data.brand_color = "#000000";
      }
      if (!this.business?.text_color) {
        data.text_color = "#ffffff";
      }
      return data;
    },
    async save(id, data) {
      return company.upsertProfile(this.business.id, data).then((res) => {
        this.$root.$emit("shilp-modal-close", "company-profile-modal");
        this.$root.$emit("rehydrate");
        return res;
      });
    },
  },
};
</script>
