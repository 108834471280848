<template>
  <div>
    <item-form
      :item="true"
      :fields="fields"
      :save="save"
      :get="get"
      class="p-4"
      :footer="true"
      :breadcrumb="false"
      :redirect="false"
      width="400px"
      ref="item"
      :notify="true"
      :gap="3"
      :form-state.sync="formState"
    >
      <template #default="{form}">
        <s-field label="Photograph" class="text-primary text-bold text-md">
          <div class="mt-2">
            <s-media
              class="radius-3"
              :readonly="false"
              ratio="1x1"
              :value="form.photographId ? form.photographId : photograph"
              @input="openCropper('photograph', $event)"
              @remove="removeProfile()"
              accept=".jpg,.jpeg,.png"
              value-type="base64"
              :max-file-size="5 * 1024 * 1024"
              :changeable="false"
            />
          </div>
        </s-field>
      </template>
      <template #footer="{saveItem,saving}" :class="['']">
        <s-row class="g-2">
          <s-column :size="3">
            <s-button
              fluid
              class="bg-grey-lighter text-grey"
              theme="muted"
              label="Back"
              @click.native="back"
            />
          </s-column>
          <s-column :size="9">
            <s-button
              fluid
              icon="vmdi-arrow-right"
              align="right"
              color="primary"
              label="Publishing Options"
              @click.native="saveItem"
              :loader="saving"
            />
          </s-column>
        </s-row>
      </template>
    </item-form>
    <!-- Cropper -->
    <cropper ref="cropper" @input="onCrop($event)" />
  </div>
</template>
<script>
import { media } from "@/api/new-request/notice";
import user from "@/api/user";

export default {
  props: {
    funeralDetails: [Object],
    funeralId: [Number, String],
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    Cropper: require("@/components/Cropper").default,
  },
  data() {
    return {
      fields: ["photographId"],
      photograph: null,
      mediaId: null,
      formState: null,
    };
  },
  methods: {
    get() {
      this.mediaId = this.funeralDetails?.notice.media_id;
      return { photographId: this.funeralDetails?.notice?.image };
    },
    removeProfile() {
      let data = this.mediaId;
      this.formState.photographId = null;
      this.photograph = null;
      return user
        .mediaRemove(data)
        .then((res) => {
          this.$notify({
            title: res.message,
            type: "success",
            text: this.error,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    },
    onCrop({ id, value }) {
      this[id] = value;
    },
    openCropper(id, img) {
      if (!img) return;
      this.$refs.cropper.open(id, img, {
        force: true,
        aspectRatio: 1,
      });
    },
    async save(id, data) {
      data.image = this.photograph;
      data.id = this.funeralId;
      let code = localStorage.getItem("vfa-code");
      return media(data, code).then((res) => {
        this.$router.push({ name: "publish-options" });
        this.$emit("refresh");
        return res;
      });
    },
    back() {
      this.$router.push({ name: "family-references" });
    },
  },
};
</script>
