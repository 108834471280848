<template>
  <item-form
    :item="true"
    :fields="fields"
    class="p-4"
    :save="save"
    :get="get"
    :footer="true"
    :breadcrumb="false"
    :redirect="false"
    width="400px"
    ref="item"
    :notify="true"
    :gap="3"
  >
    <template #default="{ form }">
      <div class="text-primary text-bold text-md">Your information</div>
      <s-field-validate label="Your Name" rules="required">
        <s-textbox v-model="form.contact_name" type="text" spellcheck="true" />
      </s-field-validate>
      <s-field-validate label="Your Surname" rules="required">
        <s-textbox
          v-model="form.contact_surname"
          type="text"
          spellcheck="true"
        />
      </s-field-validate>
      <s-field-validate label="Your Email" rules="required|email">
        <s-textbox v-model="form.contact_email" type="text" />
      </s-field-validate>
      <s-field-validate label="Your Contact Phone Number" rules="min:7|max:15">
        <s-textbox v-model="form.contact_mobile_no" type="number" />
      </s-field-validate>

      <div class="text-primary text-md text-bold mt-3 pt-2 bt-lightest mb-1">
        Details of the person who has died
      </div>
      <s-field-validate label="First Name" rules="required">
        <s-textbox v-model="form.first_name" type="text" spellcheck="true" />
      </s-field-validate>
      <s-field-validate label="Surname or Last Name" rules="required">
        <s-textbox v-model="form.last_name" type="text" spellcheck="true" />
      </s-field-validate>
      <s-field-validate label="Maiden Name">
        <s-textbox v-model="form.maiden_name" type="text" spellcheck="true" />
      </s-field-validate>
      <s-field-validate label="Gender">
        <s-select
          placeholder="Select"
          v-model="form.gender"
          :options="genderOptions"
        />
      </s-field-validate>

      <!-- <s-field-validate label="Date of Birth" class="v-calendar-custom">
        <v-date-picker v-model="form.dob" :clickable="true" />
      </s-field-validate> -->
      <s-field-validate label="Date of Birth">
        <dropdown-date-time v-model="form.dob" />
      </s-field-validate>

      <!-- <s-field-validate label="Date of Death" class="v-calendar-custom">
        <v-date-picker v-model="form.dod" :clickable="true" />
      </s-field-validate> -->
      <s-field-validate label="Date of Death">
        <dropdown-date-time v-model="form.dod" />
      </s-field-validate>

      <s-field-validate label="Current Location of the person who has died">
        <s-textbox
          v-model="form.place_of_death"
          type="text"
          spellcheck="true"
        />
      </s-field-validate>
      <s-field-validate label="Marital Status">
        <s-select :options="maritalOptions" v-model="form.marital_status" />
      </s-field-validate>
      <!-- <s-field-validate label="Marital Status">
        <s-textbox
          v-model="form.marital_status"
          type="text"
          spellcheck="true"
        />
      </s-field-validate> -->
      <s-field-validate label="Religion - if any">
        <s-textbox v-model="form.religion" type="text" spellcheck="true" />
      </s-field-validate>
      <div class="text-primary text-md text-bold mt-3 pt-2 bt-lightest mb-1">
        Home Address
      </div>
      <s-field-validate label="Street">
        <s-textbox v-model="form.street" type="text" spellcheck="true" />
      </s-field-validate>
      <s-field-validate label="Town">
        <s-textbox v-model="form.town" type="text" spellcheck="true" />
      </s-field-validate>
      <s-field-validate label="Country">
        <s-textbox v-model="form.country" type="text" spellcheck="true" />
      </s-field-validate>
      <s-field-validate label="Post Code">
        <s-textbox v-model="form.post_code" type="text" />
      </s-field-validate>
    </template>
    <template #footer="{ saveItem, saving }" :class="['']">
      <s-button
        icon="vmdi-arrow-right"
        align="right"
        color="primary"
        fluid
        label=" Select Funeral Options"
        @click.native="saveItem"
        :loader="saving"
      />
    </template>
  </item-form>
</template>
<script>
import { contactDetails } from "@/api/new-request/funeral";

export default {
  props: {
    funeralDetails: [Object],
    funeralId: [Number, String]
  },
  components: {
    // DropdownDatepicker,
    ItemForm: require("@/components/ItemForm").default,
    // VDatePicker: require("@/components/VDatePicker").default,
    DropdownDateTime: require("@/components/DropdownDateTime").default
  },
  data() {
    return {
      fields: [
        "contact_name",
        "contact_surname",
        "contact_email",
        "contact_mobile_no",
        "first_name",
        "last_name",
        "maiden_name",
        "gender",
        "dob",
        "dod",
        "place_of_death",
        "street",
        "town",
        "country",
        "post_code",
        "marital_status",
        "religion"
      ],
      loader: false
    };
  },
  computed: {
    genderOptions() {
      return [
        { label: "Male", value: "Male" },
        { label: "Female", value: "Female" },
        { label: "Other", value: "Other" }
      ];
    },
    maritalOptions() {
      return [
        { label: "Single", value: "Single" },
        { label: "Married", value: "Married" },
        { label: "Divorced", value: "Divorced" },
        { label: "Widowed", value: "Widowed" },
        { label: "Separated", value: "Separated" },
        { label: "Civil Partnership", value: "Civil Partnership" },
        { label: "Other", value: "Other" }
      ];
    }
  },
  methods: {
    get() {
      return this.funeralDetails;
    },
    getDate(date) {
      console.log("HHHHHHHH ", date);
    },
    async save(id, data) {
      this.loader = true;
      data.id = this.funeralId;

      if (!data.religion) {
        delete data.religion;
      }
      if (!data.maiden_name) {
        delete data.maiden_name;
      }
      let code = localStorage.getItem("vfa-code");
      return contactDetails(data, code).then(res => {
        this.loader = false;
        this.$emit("refresh");
        this.$router.push({ name: "funeral-options" });
      });
    }
  }
};
</script>
<style lang="scss">
.v-calendar-custom {
  position: relative;
  .field {
    &-block {
      background-color: transparent;
      position: relative;
      z-index: 1;
    }
    &__content {
      .flex__fluid {
        border-radius: 5px;
        overflow: hidden;
        background-color: var(--field--circum-bg);
      }
      .field__after {
        position: absolute;
        top: 0px;
        right: 0px;
        top: 30px;
        right: 0px;
      }
    }
  }
}
</style>
