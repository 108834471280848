export default {
    path: "funeral-directors",
    name: "funeral-directors",
    component: require("@/views/funeral-directors/Index").default,
    props:true,
    meta: {
        label: "Funeral Directors",
        breadcrumbs: ["dashboard","funeral-directors"],
        icon: "vmdi-view-dashboard",
    },
}