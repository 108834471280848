<template>
  <div class="bg-grey-lightest" :class="editable ? '' : 'media-disabled'">
    <div v-if="editor" class="bb-light p-2">
      <s-button
        size="sm"
        @click.native="editor.chain().focus().toggleBold().run()"
        class="px-2 text-grey-lightest mr-2"
        theme="muted"
        icon="vmdi-format-bold"
        :class="editor.isActive('bold') ? 'bg-primary' : ' bg-grey'"
        v-tooltip="tooltip('Bold')"
      />
      <s-button
        size="sm"
        @click.native="editor.chain().focus().toggleItalic().run()"
        class="px-2 text-grey-lightest mr-2"
        theme="muted"
        icon="vmdi-format-italic"
        :class="editor.isActive('italic') ? 'bg-primary' : ' bg-grey'"
        v-tooltip="tooltip('Italic')"
      />
      <s-button
        size="sm"
        @click.native="editor.chain().focus().toggleStrike().run()"
        class="px-2 text-grey-lightest mr-2"
        theme="muted"
        icon="vmdi-format-strikethrough"
        :class="editor.isActive('strike') ? 'bg-primary' : ' bg-grey'"
        v-tooltip="tooltip('Strike')"
      />
      <!-- <s-button
        size="sm"
        @click.native="
          editor
            .chain()
            .focus()
            .setParagraph()
            .run()
        "
        class="px-2 text-grey-lightest mr-2"
        theme="muted"
        icon="vmdi-format-paragraph"
        :class="editor.isActive('paragraph') ? 'bg-primary' : ' bg-grey'"
      /> -->
      <s-button
        size="sm"
        @click.native="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        class="px-2 text-grey-lightest mr-2"
        theme="muted"
        icon="vmdi-format-header-1"
        :class="
          editor.isActive('heading', { level: 1 }) ? 'bg-primary' : ' bg-grey'
        "
        v-tooltip="tooltip('H1')"
      />
      <s-button
        size="sm"
        @click.native="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        class="px-2 text-grey-lightest mr-2"
        theme="muted"
        icon="vmdi-format-header-2"
        :class="
          editor.isActive('heading', { level: 2 }) ? 'bg-primary' : ' bg-grey'
        "
        v-tooltip="tooltip('H2')"
      />
      <s-button
        size="sm"
        @click.native="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        class="px-2 text-grey-lightest mr-2"
        theme="muted"
        icon="vmdi-format-header-3"
        v-tooltip="tooltip('H3')"
        :class="
          editor.isActive('heading', { level: 3 }) ? 'bg-primary' : ' bg-grey'
        "
      />
      <s-button
        size="sm"
        @click.native="editor.chain().focus().toggleHeading({ level: 4 }).run()"
        class="px-2 text-grey-lightest mr-2"
        theme="muted"
        icon="vmdi-format-header-4"
        v-tooltip="tooltip('H4')"
        :class="
          editor.isActive('heading', { level: 4 }) ? 'bg-primary' : ' bg-grey'
        "
      />
      <s-button
        size="sm"
        @click.native="editor.chain().focus().toggleHeading({ level: 5 }).run()"
        class="px-2 text-grey-lightest mr-2"
        theme="muted"
        icon="vmdi-format-header-5"
        v-tooltip="tooltip('H5')"
        :class="
          editor.isActive('heading', { level: 5 }) ? 'bg-primary' : ' bg-grey'
        "
      />
      <s-button
        size="sm"
        @click.native="editor.chain().focus().toggleHeading({ level: 6 }).run()"
        class="px-2 text-grey-lightest mr-2"
        theme="muted"
        icon="vmdi-format-header-6"
        :class="
          editor.isActive('heading', { level: 6 }) ? 'bg-primary' : ' bg-grey'
        "
        v-tooltip="tooltip('H6')"
      />
      <s-button
        size="sm"
        @click.native="editor.chain().focus().toggleBulletList().run()"
        class="px-2 text-grey-lightest mr-2"
        theme="muted"
        icon="vmdi-format-list-bulleted"
        :class="editor.isActive('bulletList') ? 'bg-primary' : ' bg-grey'"
        v-tooltip="tooltip('Bullet List')"
      />
      <s-button
        size="sm"
        @click.native="editor.chain().focus().toggleOrderedList().run()"
        class="px-2 text-grey-lightest mr-2"
        theme="muted"
        icon="vmdi-format-list-numbered"
        :class="editor.isActive('orderedList') ? 'bg-primary' : ' bg-grey'"
        v-tooltip="tooltip('Number List')"
      />
      <s-button
        size="sm"
        @click.native="editor.chain().focus().toggleBlockquote().run()"
        class="px-2 text-grey-lightest mr-2"
        theme="muted"
        icon="vmdi-format-quote-close"
        :class="editor.isActive('blockquote') ? 'bg-primary' : ' bg-grey'"
        v-tooltip="tooltip('Quotes')"
      />
      <s-button
        size="sm"
        @click.native="editor.chain().focus().setHorizontalRule().run()"
        class="bg-grey px-2 text-grey-lightest mr-2"
        theme="muted"
        icon="vmdi-drag-horizontal-variant"
        v-tooltip="tooltip('Separator')"
      />
      <s-button
        size="sm"
        @click.native="editor.chain().focus().setHardBreak().run()"
        class="bg-grey px-2 text-grey-lightest mr-2"
        theme="muted"
        icon="vmdi-format-line-weight"
        v-tooltip="tooltip('New Line')"
      />

      <s-button
        size="sm"
        @click.native="editor.chain().focus().undo().run()"
        class="bg-grey px-2 text-grey-lightest mr-2 mt-2"
        theme="muted"
        icon="vmdi-undo"
        v-tooltip="tooltip('Undo')"
      />
      <s-button
        size="sm"
        @click.native="editor.chain().focus().redo().run()"
        class="bg-grey px-2 text-grey-lightest mr-2 mt-2"
        theme="muted"
        icon="vmdi-redo"
        v-tooltip="tooltip('Redo')"
      />
    </div>
    <editor-content
      class="p-3"
      :editor="editor"
      :class="defaultHeight ? 'editor-height' : ''"
      :disabled="true"
      spellcheck="true"
    />
  </div>
</template>

<script>
import { Editor, EditorContent } from "@tiptap/vue-2";
import { defaultExtensions } from "@tiptap/starter-kit";

export default {
  components: {
    EditorContent
  },

  props: {
    value: {
      type: String,
      default: ""
    },
    defaultHeight: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      editor: null
    };
  },

  watch: {
    value(value) {
      // HTML
      const isSame = this.editor.getHTML() === value;

      // JSON
      // const isSame = this.editor.getJSON().toString() === value.toString()

      if (isSame) {
        return;
      }

      this.editor.commands.setContent(this.value, false);
    },
    editable() {
      this.editor.setEditable(this.editable);
    }
  },
  methods: {
    tooltip(content) {
      return {
        content: content,
        disabled: !this.collapsed,
        delay: { show: 500, hide: 0 },
        placement: "top"
      };
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: defaultExtensions(),
      editable: this.editable,
      content: this.value,
      onUpdate: () => {
        // HTML
        this.$emit("input", this.editor.getHTML());

        // JSON
        // this.$emit('input', this.editor.getJSON())
      }
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  }
};
</script>
<style lang="scss">
.editor-height {
  .ProseMirror {
    height: 400px;
    overflow: scroll;
  }
}
.ProseMirror {
  outline: 0;
}
</style>
