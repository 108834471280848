import axios from "@/plugins/axios";

const get = async () => {
    return axios.get(`/v1/me`).then((res) => {
        return res.data;
    })
};

const mediaRemove = async (data) => {
    return axios.delete(`/v1/media/${data}`).then((res) => {
        return res.data;
    })
}

const upsert = async (data) => {
    return axios.put(`/v1/me`, data).then((res) => {
        return res.data;
    })
};

const changePassword = async (data) => {
    return axios.post(`/v1/change-password`, data).then((res) => {
        return res.data;
    })
};

const portal = async (data) => {
    return axios.post(`/v1/stripe/customer-portal`, data).then((res) => {
        return res.data;
    })
};


export default {
    get,
    upsert,
    changePassword,
    mediaRemove,
    portal
};
