import axios from "@/plugins/axios";

const login = async (data) => {
  return axios.post(`/v1/login`, data).then((res) => {
    return res.data;
  })
};

const registration = async (data) => {
  return axios.post(`/v1/signup`, data).then((res) => {
    return res.data;
  })
};

const forgetPassword = async (data) => {
  return axios.post(`/v1/forget-password`, data).then((res) => {
    return res.data;
  })
};

const resetPassword = async (data) => {
  return axios.post(`/v1/reset-password`, data).then((res) => {
    return res.data;
  })
};

export default {
  login,
  registration,
  forgetPassword,
  resetPassword
};
