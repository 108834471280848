<template>
  <item-form
    :item="funeralId"
    :fields="fields"
    class="p-4"
    :save="save"
    :get="get"
    :footer="true"
    :breadcrumb="false"
    :redirect="false"
    width="400px"
    ref="item"
    :notify="true"
    :gap="3"
  >
    <template #default="{form}">
      <div class="text-primary text-bold text-md">
        Funeral Notice Publishing Options
      </div>
      <s-field label="Family Flowers Only with Donation">
        <s-radio
          :options="donationOption"
          v-model="withDonationValue"
          @change.native="donationCheck($event.target.value)"
        />
      </s-field>
      <s-field label="Family Flowers or Donation">
        <s-radio
          :options="orDonationOption"
          v-model="orDonationValue"
          @change.native="orDoationCheck($event.target.value)"
        />
      </s-field>
      <s-field-validate
        label="Charity Name"
        v-if="withDonationValue === 'yes' || orDonationValue === 'y'"
        rules="required"
      >
        <s-textbox v-model="form.charity_name" type="text" spellcheck="true" />
      </s-field-validate>
      <div class="text-xxs text-grey">
        Where would you like to publish the funeral notice?
      </div>
      <s-field-validate rules="required">
        <s-row>
          <s-column :size="12">
            <s-field>
              <s-checkbox
                v-model="form.is_local_newspaper"
                id="checkbox-1"
                label="Local Newspaper"
                class="choice"
              />
            </s-field>
          </s-column>
          <s-column :size="12" class="mt-2" v-if="form.is_local_newspaper">
            <s-textbox
              v-model="form.local_newspaper"
              type="text"
              spellcheck="true"
            />
          </s-column>
        </s-row>
      </s-field-validate>
      <s-field-validate rules="required" class="flex flex--nowrap">
        <s-row>
          <s-column :size="12">
            <s-field>
              <s-checkbox
                v-model="form.is_national_newspaper"
                id="checkbox-2"
                label="National Newspaper"
                class="choice"
              />
            </s-field>
          </s-column>
          <s-column :size="12" class="mt-2" v-if="form.is_national_newspaper">
            <s-textbox
              v-model="form.national_newspaper"
              type="text"
              spellcheck="true"
            />
          </s-column>
        </s-row>
      </s-field-validate>
      <s-field>
        <s-checkbox
          v-model="form.facebook_page"
          id="checkbox-3"
          :label="`${businessName}'s Facebook Page (Free)`"
          class="choice"
        />
      </s-field>
    </template>
    <template #footer="{saveItem,saving}" :class="['']">
      <s-row class="g-2">
        <s-column :size="3">
          <s-button
            fluid
            class="bg-grey-lighter text-grey"
            theme="muted"
            label="Back"
            @click.native="back"
          />
        </s-column>
        <s-column :size="9">
          <s-button
            fluid
            icon="vmdi-arrow-right"
            align="right"
            color="primary"
            label="Funeral Notice Preview"
            @click.native="saveItem"
            :loader="saving"
          />
        </s-column>
      </s-row>
    </template>
  </item-form>
</template>
<script>
import { publishOption } from "@/api/new-request/notice";

export default {
  props: {
    funeralDetails: [Object],
    funeralId: [Number, String],
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
  },
  data() {
    return {
      fields: [
        "local_newspaper",
        "national_newspaper",
        "facebook_page",
        "charity_name",
        "is_local_newspaper",
        "is_national_newspaper",
      ],
      localNewsPaper: false,
      nationalNewsPaper: false,
      withDonationValue: "no",
      orDonationValue: "n",
    };
  },
  computed: {
    businessName() {
      return this.funeralDetails?.business?.name;
    },
    donationOption() {
      return [
        {
          label: "Yes",
          value: "yes",
        },
        {
          label: "No",
          value: "no",
        },
      ];
    },
    orDonationOption() {
      return [
        {
          label: "Yes",
          value: "y",
        },
        {
          label: "No",
          value: "n",
        },
      ];
    },
  },
  methods: {
    get() {
      this.nationalNewsPaper = this.funeralDetails?.notice?.national_newspaper
        ? true
        : false;
      this.localNewsPaper = this.funeralDetails?.notice?.local_newspaper
        ? true
        : false;

      let {
        family_flowers_only_with_donation,
        family_flowers_or_donation,
      } = this.funeralDetails?.notice;

      this.withDonationValue = family_flowers_only_with_donation ? "yes" : "no";
      this.orDonationValue = family_flowers_or_donation ? "y" : "n";

      return { ...this.funeralDetails?.notice };
    },
    async save(id, data) {
      data.id = this.funeralId;

      data.family_flowers_only_with_donation =
        this.withDonationValue === "yes" ? true : false;
      data.family_flowers_or_donation =
        this.orDonationValue === "y" ? true : false;
      let code = localStorage.getItem("vfa-code");
      return publishOption(data, code).then((res) => {
        this.$emit("refresh");
        this.$router.push({ name: "preview" });
        return res;
      });
    },
    donationCheck(value) {
      if (value === "yes") {
        this.orDonationValue = "n";
      }
      this.withDonationValue = value;
    },
    orDoationCheck(value) {
      if (value === "y") {
        this.withDonationValue = "no";
      }
      this.orDonationValue = value;
    },
    back() {
      this.$router.push({ name: "photograph" });
    },
  },
};
</script>
