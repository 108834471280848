import axios from "@/plugins/axios";

const get = async (id) => {
  return axios.get(`/v1/business-detail/${id}`).then((res) => {
    return res.data;
  })
};

const upsertProfile = async (id, data) => {
  return axios.put(`/v1/business/${id}/profile`, data).then((res) => {
    return res.data;
  })
};

const upsertLogo = async (id, data) => {
  return axios.put(`/v1/business/${id}/logo`, data).then((res) => {
    return res.data;
  })
};

const upsertBanner = async (id, data) => {
  return axios.put(`/v1/business/${id}/welcome-screen`, data).then((res) => {
    return res.data;
  })
};


export default {
  get,
  upsertProfile,
  upsertLogo,
  upsertBanner
};
