<template>
  <div class="radius-3 bg-grey-lightest-ultra p-3 text-grey mb-3" v-if="item">
    <div class="pb-2 mb-2 bb-lighter text-md text-grey-darkest">
      {{ title }}
    </div>
    <div class="mb-1">
      <!-- <div>Tailored {{ item.funeral_type }}</div> -->
      {{ item.desc }}
    </div>
    <div class="text-grey-darkest text-bold text-md mb-2">
      {{ "£" + item.price.toFixed(2) }}
    </div>
    <!-- <div class="text-xs ">
      {{ item.desc }}
    </div> -->
  </div>
</template>
<script>
import { serviceType } from "@/api/new-request/tailored";

export default {
  props: {
    type: String,
    businessId: [String, Number],
    title: {
      type: String,
      default: "Funeral Directors Fees",
    },
  },
  data() {
    return {
      item: null,
    };
  },
  watch: {
    type: function(value) {
      this.getServiceInfo(value);
    },
  },
  mounted() {
    if (this.type) {
      this.getServiceInfo(this.type);
    }
  },
  methods: {
    getServiceInfo(type) {
      let data = {
        business_id: this.businessId,
        type: type,
      };
      serviceType(data)
        .then((res) => {
          this.item = res.data?.[0];
          this.$emit("typeTotal", {
            total: this.item?.product_price,
            name: "type",
          });
        })
        .catch((err) => {
          this.$emit("typeTotal", { total: 0, name: "type" });
          console.log("SOMETHING WENT WRONG ", err);
        });
    },
  },
};
</script>
