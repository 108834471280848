<template>
  <s-layout
    class="child-box"
    full-height
    left-size="320px"
    right-size="60%"
    :right-classlist="['p-3', 'bl-lightest']"
  >
    <template #default>
      <div v-if="loading" class="p-5" v-shilp-loader="true" />
      <item-form
        v-else
        :item="true"
        :fields="fields"
        class="p-4 option-form"
        :save="save"
        :get="get"
        :footer="true"
        :breadcrumb="false"
        :redirect="false"
        ref="item"
        :notify="true"
      >
        <template #default="{saveItem,form}">
          <div class="text-primary text-bold text-md">
            Choose Funeral Option
          </div>
          <s-field-validate rules="required" name="Funeral option">
            <div class="options-list">
              <option-card
                v-for="(option, index) in optionaData"
                :key="index"
                :item="option"
                v-model="form.funeral_type_id"
                @click.native="saveItem"
              />
            </div>
          </s-field-validate>
        </template>
        <template #footer="" :class="['']">
          <s-row class="g-2">
            <s-column :size="3">
              <s-button
                fluid
                class="bg-grey-lighter mr-3 text-grey"
                theme="muted"
                label="Back"
                @click.native="back"
              />
            </s-column>
          </s-row>
        </template>
      </item-form>
    </template>
    <template #right>
      <request :get="getService" #default="{res}" ref="serviceComparision">
        <div class="mb-2 text-md text-bold text-accent">
          Compare our Direct, Simple and Tailored funeral services
        </div>
        <div class="mb-4 text-grey-light">
          The table below highlights the main differences between our funeral
          services. If you are not sure or have any questions, please contact
          our office.
        </div>
        <s-row :gap="0" class="mb-2 bb-lighter radius-3">
          <s-column
            :size="1"
            class="text-bold bg-info-lightest text-grey p-2 text-xs"
            >#</s-column
          >
          <s-column
            :size="5"
            class="text-bold bg-info-lightest text-grey p-2 text-xs"
            >Name</s-column
          >
          <s-column :size="2" class="bg-secondary text-light p-2 text-xs">
            <div class="text-bold pb-1">
              Tailored
            </div>
            <div>
              {{ "£ " + business.tailored_funeral_price.toFixed(2) }}
            </div>
          </s-column>
          <s-column :size="2" class="bg-accent text-light p-2 text-xs">
            <div class="text-bold pb-1">
              Simple
            </div>
            <div>
              {{ "£ " + business.simple_funeral_price.toFixed(2) }}
            </div>
          </s-column>
          <s-column :size="2" class="bg-grey text-light p-2 text-xs">
            <div class="text-bold pb-1">
              Direct
            </div>
            <div>
              {{ "£ " + business.direct_cremation_price.toFixed(2) }}
            </div>
          </s-column>
        </s-row>
        <s-row
          :gap="0"
          v-for="(service, index) in res"
          :key="index"
          class="mb-2 bb-lighter bg-info-lightest radius-3"
        >
          <s-column :size="1" class="p-2 text-xs">{{ index + 1 }}</s-column>
          <s-column :size="5" class="p-2 text-xs">{{ service.name }}</s-column>
          <s-column :size="2" class="p-2 text-xs">
            <s-icon
              v-if="showIcon(service.tailored)"
              :class="iconColor[service.tailored.toLowerCase()]"
              :name="icon[service.tailored.toLowerCase()]"
              :size="20"
            />
            <div v-else>
              {{ service.tailored }}
            </div>
          </s-column>
          <s-column :size="2" class="p-2 text-xs">
            <s-icon
              v-if="showIcon(service.simple)"
              :class="iconColor[service.simple.toLowerCase()]"
              :name="icon[service.simple.toLowerCase()]"
              :size="20"
            />
            <div v-else>
              {{ service.simple }}
            </div>
          </s-column>
          <s-column :size="2" class="p-2 text-xs">
            <s-icon
              v-if="showIcon(service.direct)"
              :class="iconColor[service.direct.toLowerCase()]"
              :name="icon[service.direct.toLowerCase()]"
              :size="20"
            />
            <div v-else>
              {{ service.direct }}
            </div>
          </s-column>
        </s-row>
      </request>
    </template>
  </s-layout>
</template>
<script>
import { getFuneralOptions, saveFuneralType } from "@/api/new-request/funeral";
import { get } from "@/api/serviceComparison";

export default {
  props: {
    funeralDetails: [Object],
    funeralId: [Number, String],
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
    OptionCard: require("./Options").default,
    Request: require("@/components/Request").default,
  },
  data() {
    return {
      fields: ["funeral_type_id"],
      optionaData: [],
      loading: false,
      icon: {
        yes: "vmdi-checkbox-marked",
        no: "vmdi-close-box",
      },
      iconColor: {
        yes: "text-success",
        no: "text-danger",
      },
    };
  },
  computed: {
    business() {
      return this.funeralDetails?.business;
    },
  },
  mounted() {
    this.loading = true;
    getFuneralOptions(this.funeralDetails.business_id)
      .then((res) => {
        this.optionaData = res.data;
        this.loading = false;
      })
      .catch((err) => {
        console.log("err", err);
        this.loading = false;
      });
  },

  methods: {
    submitTitle(data) {
      if (data?.funeral_type_id === 2 || data?.funeral_type_id === 3) {
        return "Select Funeral Type";
      }
      return "Confirm Funeral Details";
    },
    get() {
      return { funeral_type_id: this.funeralDetails.funeral_type_id };
    },
    async save(id, data) {
      data.id = this.funeralId;
      let code = localStorage.getItem("vfa-code");
      return saveFuneralType(data, code).then((res) => {
        this.$emit("refresh");
        this.nextRoute(data.funeral_type_id);
      });
    },
    nextRoute(id) {
      if (id === 1) {
        this.$router.push({ name: "confirm-details" });
      } else {
        this.$router.push({ name: "funeral-type" });
      }
    },
    back() {
      this.$router.push({ name: "contact-details" });
    },
    getService() {
      return get(this.funeralDetails?.business_id).then((res) => {
        return res.data;
      });
    },
    showIcon(name) {
      if (name.toLowerCase() == "yes" || name.toLowerCase() == "no")
        return true;

      return false;
    },
  },
};
</script>
<style lang="scss" scoped>
.card {
  border: 3px solid #daeeec;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    border: 3px solid #465659;
    transition: 0.3s;
  }
}
.card-active {
  border: 3px solid #465659;
  transition: 0.3s;
}
@media (max-width: 992px) {
  .child-box {
    display: block;
    ::v-deep {
      .layout__content {
        overflow-x: hidden !important;
      }
    }
  }
  .option-form {
    ::v-deep {
      footer.mt-5 {
        margin-top: 20px !important;
      }
    }
  }
  // .options-list{
  //   display: flex;
  //   flex-wrap:nowrap;
  //   overflow: auto;
  // }
}
@media (max-width: 400px) {
  .card {
    .media {
      width: 80px !important;
      height: 80px !important;
    }
    ::v-deep {
      .media {
        width: 80px !important;
        height: 80px !important;
      }
    }
  }
}
</style>
