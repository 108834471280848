<template>
  <item-form
    :item="funeralId"
    :fields="fields"
    class="p-4"
    :save="save"
    :get="get"
    :form-state.sync="formValues"
    :footer="true"
    :breadcrumb="false"
    :redirect="false"
    width="400px"
    ref="item"
    :notify="true"
    :gap="3"
  >
    <template #default="{form}">
      <div class="text-primary text-bold text-md mb-2">
        Funeral Notice Family References
      </div>
      <s-field-validate label="Name of Husband / Wife / Partner">
        <s-textbox v-model="form.spouse_name" type="text" spellcheck="true" />
      </s-field-validate>
      <s-field-validate label="Sons">
        <s-textbox v-model="form.sons" type="text" spellcheck="true" />
      </s-field-validate>
      <s-field-validate label="Daughters">
        <s-textbox v-model="form.daughters" type="text" spellcheck="true" />
      </s-field-validate>
      <s-field-validate label="Grandchildren">
        <s-textbox
          v-model="form.grand_children"
          type="text"
          spellcheck="true"
        />
      </s-field-validate>
      <s-field-validate label="Other Relevant People to Mention" class="mb-1">
        <s-textbox
          v-model="form.other_relatives"
          type="text"
          spellcheck="true"
        />
      </s-field-validate>
      <s-field label="Unnamed Family References" class="family-checkbox">
        <div>
          <s-checkbox
            id="Will be sadly missed by all family and friends"
            label="Will be sadly missed by all family and friends"
            v-model="form.others"
            class="choice mt-2"
            name="Will be sadly missed by all family and friends"
          />
        </div>
      </s-field>
    </template>
    <template #footer="{saveItem,saving}" :class="['']">
      <s-row class="g-2 row-block">
        <s-column :size="12">
          <div class="mb-1 text-primary text-bold">
            Would you like to add a photograph for funeral notice?
          </div>
        </s-column>
        <s-column :size="6">
          <s-button
            fluid
            icon="vmdi-image-area"
            align="right"
            color="primary"
            label="Photograph"
            @click.native="saveItem"
            :loader="saving"
          />
        </s-column>
        <s-column :size="6">
          <s-button
            fluid
            icon="vmdi-arrow-right"
            align="right"
            color="primary"
            label="Skip Photograph"
            @click.native="skip"
            :loader="loading"
          />
        </s-column>
        <s-column :size="3">
          <s-button
            fluid
            class="bg-grey-lighter  text-grey"
            theme="muted"
            label="Back"
            @click.native="back"
          />
        </s-column>
      </s-row>
    </template>
  </item-form>
</template>
<script>
import { familyReference } from "@/api/new-request/notice";

export default {
  props: {
    funeralDetails: [Object],
    funeralId: [Number, String],
  },
  components: {
    ItemForm: require("@/components/ItemForm").default,
  },
  data() {
    return {
      fields: [
        "spouse_name",
        "sons",
        "other_relatives",
        "daughters",
        "grand_children",
        "parents",
        "others",
      ],
      formValues: null,
      loading: false,
    };
  },
  computed: {
    funnelReferencesOption() {
      return [
        { label: "Sons-in-laws", value: 0 },
        { label: "Sisters-in-laws", value: 0 },
        { label: "Grandchildren", value: 0 },
        { label: "Nieces", value: 0 },
        { label: "Relatives & Friends", value: 0 },
        { label: "Daughters-in-laws", value: 0 },
        { label: "Brothers-in-laws", value: 0 },
        { label: "Great-grand-children", value: 0 },
        { label: "Nephews", value: 0 },
        {
          label: "Will be sadly missed by all family and friends",
          value: 0,
        },
      ];
    },
  },
  methods: {
    get() {
      return this.funeralDetails?.notice;
    },
    async save(id, data) {
      let finalData = {
        ...data,
      };
      finalData.others = data.others ? 1 : 0;
      finalData.id = this.funeralId;
      let code = localStorage.getItem("vfa-code");
      return familyReference(finalData, code).then((res) => {
        this.$emit("refresh");
        this.$router.push({ name: "photograph" });
        return res;
      });
    },
    back() {
      this.$router.push({ name: "deceased-details" });
    },
    async skip() {
      this.loading = true;
      let finalData = {
        ...this.formValues,
      };
      finalData.others = this.formValues.others ? 1 : 0;
      finalData.id = this.funeralId;
      let code = localStorage.getItem("vfa-code");

      await familyReference(finalData, code)
        .then((res) => {
          this.$emit("refresh");
          this.$router.push({ name: "publish-options" });
        })
        .catch((err) => {
          console.log("ERR ", err);
        });
      this.loading = false;
    },
  },
};
</script>
<style lang="scss">
.family-checkbox {
  .options {
    margin-top: 5px;
    .choice {
      margin-bottom: 10px;
    }
  }
}
.form-width {
  width: 350px !important;
}
</style>
