<template>
  <div>
    <s-row class="common-row mb-4 pb-2">
      <s-column :size="5">
        <div class=" bg-grey-lightest-ultra p-3 text-grey radius-3">
          <div class="text-primary text-bold text-md bb-lighter pb-2  mb-3">
            Urn
          </div>
          <div class="flex flex--nowrap flex--middle" v-if="product">
            <div class="flex__fit mr-3">
              <s-media
                class="radius-3 bg-grey-lightest"
                :readonly="true"
                ratio="16x9"
                width="100px"
                :value="image"
              />
            </div>
            <div>
              <div class="text-xs text-bold mb-1 text-grey text-capitalize">
                {{ name }}
                <span v-if="product.variant_name" class="text-normal">
                  - {{ product.variant_name }}</span
                >
                <span v-if="product.is_third_party" class="text-normal">
                  (Third party Product)</span
                >
              </div>
              <div class="text-grey-darkest text-lg">
                {{ "£" + price }}
              </div>
            </div>
          </div>
          <div v-else class="text-center text-xs text-grey text-italic">
            (Urns not selected)
          </div>
        </div>
      </s-column>
    </s-row>
    <s-row class="common-row mb-4 pb-2">
      <s-column :size="5">
        <div class=" bg-grey-lightest-ultra p-3 text-grey radius-3">
          <div class="text-primary text-bold text-md bb-lighter pb-2  mb-3">
            Third-party Service
          </div>
          <div class="flex flex--nowrap flex--middle" v-if="funeralNotice">
            <div class="flex__fit mr-3">
              <s-media
                class="radius-3 bg-grey-lightest"
                :readonly="true"
                ratio="16x9"
                width="100px"
                :value="funeralNotice.image"
              />
            </div>
            <div>
              <div class="text-xs text-bold mb-1 text-grey text-capitalize">
                {{ funeralNotice.name }}
              </div>
              <div class="text-grey-darkest text-lg">
                {{ "£" + funeralNotice.price.toFixed(2) }}
              </div>
            </div>
          </div>
          <div v-else class="text-center text-xs text-grey text-italic">
            (Funeral Notice not selected)
          </div>
        </div>
      </s-column>
    </s-row>
  </div>
</template>
<script>
export default {
  props: {
    funeralDetails: Object,
  },
  computed: {
    image() {
      return this.product?.image;
    },
    name() {
      return this.product?.name;
    },
    price() {
      return this.product?.price?.toFixed(2);
    },
    product() {
      let urnProduct = this.funeralDetails?.products.filter(
        (p) => p.category?.name === "Urn"
      );
      return urnProduct?.[0];
    },
    funeralNotice() {
      return this.funeralDetails?.products.find(
        (p) => p.category?.name == "funeral notice"
      );
    },
  },
};
</script>
