<template>
  <div
    :id="'p-' + stepId"
    class="p-3 form-border  mb-3 "
    :class="isOpen ? 'form-active' : ''"
  >
    <div
      class="flex flex--middle flex--fit flex--between text-primary text-lg"
      @click="openContainer"
      :class="isOpen ? '' : 'cursor-pointer'"
    >
      <div>{{ title }}</div>
    </div>
    <div v-if="isOpen">
      <div class="text-primary-light my-3">
        {{ desc }}
      </div>
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    desc: String,
    isOpen: {
      type: Boolean,
      default: false,
    },
    stepId: Number,
  },
  data() {
    return {};
  },
  methods: {
    openContainer() {
      this.$emit("stepOpen", this.stepId);
    },
  },
};
</script>
<style lang="scss" scoped>
.form-border {
  border: 2px solid #a8a29e;
  border-radius: 8px;
}
.form-active {
  border: 2px solid #465659;
}
.cursor-pointer {
  cursor: pointer;
}
</style>
