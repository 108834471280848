<template>
  <router-view :business="business" />
</template>
<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["business"]),
  },
};
</script>
