import axios from "@/plugins/axios";

export const getFuneralOptions = async (id) => {
    return axios.get(`/v1/funeral-options?business_id=${id}`).then((res) => {
        return res.data;
    })
};

export const createId = async (data) => {
    return axios.post(`/v1/funeral`, data).then((res) => {
        return res.data;
    })
};

export const getFuneral = async (id, code) => {
    return axios.get(`/v1/funeral/${id}?verify=${code}`).then((res) => {
        return res.data;
    })
};

export const contactDetails = async (data, code) => {
    return axios.put(`/v1/funeral/${data.id}?verify=${code}`, data).then((res) => {
        return res.data;
    })
};

export const saveFuneralType = async (data, code) => {
    return axios.put(`/v1/funeral/${data.id}/type?verify=${code}`, data).then((res) => {
        return res.data;
    })
};

export const confirmDetails = async (data, code) => {
    return axios.put(`/v1/funeral/${data.id}/status?verify=${code}`, data).then((res) => {
        return res.data;
    })
};

export const confirmStatus = async (data) => {
    return axios.put(`/v1/funeral/${data.id}/payment-status`, data).then((res) => {
        return res.data;
    })
};

export const getDetailsUsingSlug = async (slug) => {
    return axios.get(`/v1/business-detail/${slug}`).then((res) => {
        return res.data;
    })
};


export const saveFuneralOptions = async (data, code) => {
    return axios.put(`/v1/funeral/${data.id}/option?verify=${code}`, data).then((res) => {
        return res.data;
    })
};

export const saveProducts = async (data) => {
    return axios.put(`/v1/funeral/${data.id}/product`, data).then((res) => {
        return res.data;
    })
};

export const productUrns = async (data) => {
    return axios.put(`/v1/funeral/${data.id}/product-urn`, data).then((res) => {
        return res.data;
    })
}

export const otpVerification = async (data) => {
    return axios.get(`/v1/funeral/${data.id}?verify=${data.code}`).then((res) => {
        return res.data;
    })
}
