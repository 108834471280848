<template>
  <div class="flex__fluid">
    <vc-date-picker
      class="flex w-full"
      :value="value"
      @input="input($event)"
      :max-date="maximum"
      :min-date="minimum"
      :mode="mode"
      :masks="{ input: ['DD/MM/YYYY'] }"
      :popover="{
        visibility: 'click',
      }"
    >
      <!-- :is24hr="true" -->
      <template v-slot="{ inputValue, inputEvents, togglePopover }">
        <div class="calender-container  bg-grey-lightest">
          <input
            class="field-block v-calendar__radius"
            :value="inputValue"
            v-on="inputEvents"
          />
          <s-icon
            v-if="clickable"
            :size="20"
            class="p-2 text-grey pointer"
            name="vmdi-calendar-month"
            @click.native="togglePopover()"
          />
        </div>
      </template>
    </vc-date-picker>
  </div>
</template>
<script>
import { format } from "date-fns";
export default {
  props: {
    value: [String, Number, Date],
    maxDate: String,
    minDate: String,
    maxAllow: {
      type: Boolean,
      default: true,
    },
    minAllow: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: "date",
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      date: null,
      maximum: null,
      minimum: null,
    };
  },
  created() {
    if (!this.value) {
      this.date = format(new Date(), "yyyy/MM/dd");
    }
    if (!this.maxDate && this.maxAllow) {
      this.maximum = format(new Date(), "yyyy/MM/dd");
    }

    if (!this.minDate && this.minAllow) {
      this.minimum = format(new Date(), "yyyy/MM/dd");
    }
  },
  methods: {
    input(date) {
      // if (!date) return; // if user want to deselct date so that will be possible

      if (this.mode === "dateTime" || this.mode === "time") {
        this.$emit("input", date);
      } else this.$emit("input", format(date, "yyyy-MM-dd"));
    },
  },
};
</script>
<style scoped>
.v-calendar__radius {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.calender-container {
  display: flex;
  align-items: center;
  border-radius: 3px;
}

.pointer {
  cursor: pointer;
}
</style>
