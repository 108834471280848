<template>
  <div
    class="plan-card mr-3"
    v-if="item"
    @click="select(item)"
    :class="isActive"
  >
    <div class="text-lg mb-1">{{ "£" + item.price }}</div>
    <div class="text-md text-bold">
      {{ item.name }}
    </div>
  </div>
</template>
<script>
import { secretKey } from "@/api/subscription";
export default {
  props: {
    item: Object,
    value: {
      type: [Object],
      default: () => {},
    },
  },
  computed: {
    isActive() {
      if (this.value?.id === this.item?.id) {
        return "plan-active";
      }
      return "";
    },
  },
  methods: {
    async select(item) {
      let clientKey = await secretKey(item.provider_product_id).then(
        (res) => res
      );
      this.$emit("input", { ...item, secretKey: clientKey.secret });
    },
  },
};
</script>
<style lang="scss" scoped>
.plan-card {
  width: 120px;
  height: 120px;
  border-radius: 3px;
  border: 2px solid #daeeec;
  color: #78716c;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  &:hover {
    border: 2px solid #465659;
    color: #0d1d2a;
    transition: 0.3s;
  }
}
.plan-active {
  border: 2px solid #0d1d2a;
  color: #0d1d2a;
  transition: 0.3s;
}
</style>
