export default {
  path: "products",
  component: require("@/views/products/Index").default,
  name: "products",
  props: true,
  redirect: { name: "products-list" },
  children: [
    {
      path: "",
      name: "products-list",
      component: require("@/views/products/List").default,
      props: true,
      meta: {
        label: "Products",
        breadcrumbs: ["dashboard", "products-list"],
      },
    },
    {
      path: ":productId",
      name: "products-upsert",
      component: require("@/views/products/Upsert").default,
      props: true,
      meta: {
        label: "Product",
        breadcrumbs: ["dashboard", "products-list", "products-upsert"],
      },
    }
  ]
}