import axios from "@/plugins/axios";

export const add = (data) => {
  return axios.post(`/v1/product`, data).then(res => {
    return res.data;
  })
}

export const get = (id, bId) => {
  return axios.get(`/v1/product/${id}?business_id=${bId}`).then(res => {
    return res.data;
  })
}

export const update = (data) => {
  return axios.put(`/v1/product/${data.id}`, data).then(res => {
    return res.data;
  })
}

export const archive = (id, bId) => {
  return axios.patch(`/v1/product/${id}/archive?business_id=${bId}`).then(res => {
    return res.data;
  })
}

export const unarchive = (id, bId) => {
  return axios.patch(`/v1/product/${id}/unarchive?business_id=${bId}`).then(res => {
    return res.data;
  })
}

export const categories = (id) => {
  return axios.get(`/v1/category?business_id=${id}&filter[category_not]=funeral notice`).then(res => {
    return res.data?.data.map(category => {
      return { label: category.name, value: category.id }
    })
  })
}