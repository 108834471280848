import axios from "@/plugins/axios";

const getFuneralDirector = async (id) => {
    return axios.get(`/v1/admin/user/${id}`).then((res) => {
        return res.data;
    })
};

export default {
    getFuneralDirector
  };